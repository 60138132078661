import React, { Component } from "react";
import { connect } from "react-redux";
import Executivedetails from "../Components/Executivedetails";
import {
  close_snack_bar
} from "../../../common/snackbar/action";
 import {viewLeadByExecutive} from '../actions'

export class Controller extends Component {
  render() {
    return (
      <Executivedetails {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    allotment: store.allotment,
    employee:store.employee,
    login:store.login,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return { 

    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewLeadByExecutive: (executive_id) => {
        dispatch(viewLeadByExecutive(executive_id));
      },
         
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);