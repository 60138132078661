/* eslint-disable no-lone-blocks */
import {
    VIEW_ALL_LEAD,
    VIEW_ALL_LEAD_WITH_PGN,
    VIEW_ALL_FOLLOW_UPS_LEAD,
    VIEW_ALL_OLD_LEAD,
    VIEW_ALL_TODAY_LEAD,
    VIEW_ALL_LEAD_BY_ID,
    VIEW_ASSIGNED_BY_LEAD,
    VIEW_LEAD_BY_MANAGER_ID,
    VIEW_ALL_SOURCE,
    VIEW_ALL_NEW_LEAD,
    SEARCH_NEW_LEAD,
    SEARCH_FOLLOW_UPS_LEAD,
    SEARCH_OLD_LEAD,
    SET_LEAD_DETAILS,
    VIEW_ALL_DEAL_BY_LEAD_ID,
    SET_ALL_NOTES_BY_LEAD_ID,
    SEARCH_NOTES,
    SET_ALL_PAYMENT_BY_LEAD_ID,
    SET_ALL_QUOTES_BY_LEAD_ID

} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import 'firebase/storage';
import { set_snack_bar } from "../../common/snackbar/action";
import user from "../User/user";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";



// ---------------add new lead---------------
export function addNewLead(org_id, phone, add_by, follow_up_date, email, source, product_id, executive_id, name, description, reminder, call_purpose, category, product_name, product_price, countryid, stateid, cityid, address, pincode, deal_status, row_perpage, pageNumber, type) {

    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_new_lead", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                phone: phone,
                add_by: add_by,
                follow_up_date: follow_up_date,
                email: email,
                source: source,
                product_id: product_id,
                executive_id: executive_id,
                name: name,
                description: description,
                reminder: reminder,
                call_purpose: call_purpose,
                category: category,
                product_name: product_name,
                product_price: product_price,
                country: countryid,
                state: stateid,
                city: cityid,
                address: address,
                pin: pincode,
                deal_status: deal_status,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    if (type !== "E" || type !== "M") {
                        dispatch(viewAllFollowUpLead(org_id, row_perpage, pageNumber, ""))
                        dispatch(viewAllOldLead(org_id, row_perpage, pageNumber, ""))
                        dispatch(viewAllNewLead(org_id, row_perpage, pageNumber, ""))
                    }
                    else if (type === "M") {
                        dispatch(viewAllFollowUpLeadManager(org_id, add_by, row_perpage, pageNumber, ""))
                        dispatch(viewAllOldLeadManager(org_id, add_by, row_perpage, pageNumber, ""))
                        dispatch(viewAllNewLeadManager(org_id, add_by, row_perpage, pageNumber, ""))
                    }
                    else if (type === "E") {
                        dispatch(viewAllFollowUpLeadExecutive(org_id, add_by, row_perpage, pageNumber, ""))
                        dispatch(viewAllOldLeadExecutive(org_id, add_by, row_perpage, pageNumber, ""))
                        dispatch(viewAllNewLeadExecutive(org_id, add_by, row_perpage, pageNumber, ""))
                    }
                    dispatch(set_snack_bar(responseJson.status, "Lead Added Successfully"));
                    dispatch(viewAllNewLead(org_id, 10, 0, ''));
                    dispatch(viewAllNewLeadExecutive(org_id, 10, 0, ''));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(responseJson.status, "Number already exists "));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function import_lead(leadData, product_name, product_price, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "import_lead", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                leadData: leadData,
                product_name: product_name,
                product_price: product_price
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAlltheClients(admin_id,org_id));
                    dispatch(set_snack_bar(responseJson.status, "Lead Added Successfully"));
                    dispatch(viewAllNewLead(org_id, 10, 0, ''));
                    dispatch(viewAllNewLeadExecutive(org_id, 10, 0, ''));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(responseJson.status, "Number already exists "));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// ---------------view alllead-----------------
export function viewAllLead(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_lead",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },

            })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_LEAD, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_LEAD, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

//------------view all source--------------
export function viewAllSource() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_source",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_SOURCE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_SOURCE, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// ------------------------all lead pgn-----------------
export function viewAllLeadWithPgn(org_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "  view_all_lead_with_pgn",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },
                body: JSON.stringify({
                    page_number: page_number,
                    rowper_page: rowper_page,
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_LEAD_WITH_PGN, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_LEAD_WITH_PGN, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
// ----------------view all followup lead-------------------

export function viewAllFollowUpLead(org_id, rowper_page, page_number, name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_followups_lead",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },
                body: JSON.stringify({
                    rowper_page: rowper_page,
                    page_number: page_number,
                    searchKeyword: name
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_FOLLOW_UPS_LEAD, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_FOLLOW_UPS_LEAD, payload: { data: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllFollowUpLeadManager(org_id, user_id, rowper_page, page_number) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_follow_lead_manger",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    org_id: org_id,
                    user_id: user_id,
                    rowper_page: rowper_page,
                    page_number: page_number,
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_FOLLOW_UPS_LEAD, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_FOLLOW_UPS_LEAD, payload: { data: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllFollowUpLeadExecutive(org_id, user_id, rowper_page, page_number) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_follow_up_lead_executive",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    org_id: org_id,
                    executive_id: user_id,
                    rowper_page: rowper_page,
                    page_number: page_number,
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_FOLLOW_UPS_LEAD, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_FOLLOW_UPS_LEAD, payload: { data: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// -------------------view all old lead---------------
export function viewAllOldLead(org_id, rowper_page, page_number, name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_old_lead",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },

                body: JSON.stringify({
                    rowper_page: rowper_page,
                    page_number: page_number,
                    searchKeyword: name
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_OLD_LEAD, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_OLD_LEAD, payload: { data: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewAllOldLeadManager(org_id, user_id, rowper_page, page_number) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_old_lead_manger",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

                body: JSON.stringify({
                    org_id: org_id,
                    user_id: user_id,
                    rowper_page: rowper_page,
                    page_number: page_number,
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_OLD_LEAD, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_OLD_LEAD, payload: { data: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllOldLeadExecutive(org_id, user_id, rowper_page, page_number) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_old_lead_executive",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

                body: JSON.stringify({
                    org_id: org_id,
                    executive_id: user_id,
                    rowper_page: rowper_page,
                    page_number: page_number,
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_OLD_LEAD, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_OLD_LEAD, payload: { data: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

//-----------------view all today lead---------------

export function viewAllTodayLead(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_today_lead",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

                body: JSON.stringify({
                    org_id: org_id,
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_TODAY_LEAD, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_TODAY_LEAD, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
// --------------view lead by id------------------
export function viewAllLeadById(lead_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_lead_by_id",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    lead_id: lead_id
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_LEAD_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_LEAD_BY_ID, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_lead_details(lead_id) {

    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_lead_details",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    lead_id: lead_id,
                }),
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(setLeadDetails(responseJson.result));
                    dispatch({ type: VIEW_ALL_DEAL_BY_LEAD_ID, payload: responseJson.deal_result });
                    dispatch(viewAllNotesByDealId(responseJson.deal_result[0]?._id.toString(), 0, 10, ''))
                    dispatch(viewAllPaymentByDealId(responseJson.deal_result[0]?._id.toString(), 0, 10, ''))
                    dispatch(viewAllQuoteByLeadId(responseJson.deal_result[0]?._id.toString(), ''))
                    dispatch(viewAllFollowupDeal(responseJson.deal_result[0]?._id.toString()))
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch(setLeadDetails({}));
                    dispatch({ type: VIEW_ALL_DEAL_BY_LEAD_ID, payload: [] });
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
// -----------update lead  status-------------
export function updateLeadStatus(lead_id, status, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_lead_status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                lead_id: lead_id
            },
            body: JSON.stringify({
                status: status,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllNewLead(org_id, 10, 0, ''));
                    dispatch(viewAllFollowup(lead_id))
                    dispatch(viewAllNewLeadExecutive(org_id, 10, 0, ""))
                    dispatch(viewAllLeadById(lead_id))
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

//--------------update lead type-----------------

export function updateLeadType(lead_id, ptype, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_lead_type", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                lead_id: lead_id
            },
            body: JSON.stringify({
                ptype: ptype,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllNewLead(org_id, 10, 0, ''));
                    dispatch(viewAllFollowup(lead_id))
                    dispatch(viewAllNewLeadExecutive(org_id, 10, 0, ""))
                    dispatch(viewAllLeadById(lead_id))
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// ---------------view assigned by lead----------------
export function viewAssignedByLead(user_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_assigned_by_leads",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    user_id: user_id,
                    org_id: org_id
                },

                // body: JSON.stringify({

                // })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ASSIGNED_BY_LEAD, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ASSIGNED_BY_LEAD, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
//--------------bulk delete lead----------------

export function bulkDeleteLeads(isChecked) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + " bulk_delete_leads", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: isChecked,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    // dispatch(viewAllAssets(org_id));
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
// -------------------------view lead by manager id------------------------------
export function viewLeadByManagerId(manager_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_lead_by_manager_id",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    manager_id: manager_id
                },

                // body: JSON.stringify({

                // })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_LEAD_BY_MANAGER_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_LEAD_BY_MANAGER_ID, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// all new leads

export function viewAllNewLead(org_id, rowper_page, page_number, name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_new_lead", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                searchKeyword: name,
                page_number: page_number,
                rowper_page: rowper_page
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_NEW_LEAD, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_NEW_LEAD, payload: { data: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewAllNewLeadManager(org_id, user_id, rowper_page, page_number, name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_new_lead_manger",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    org_id: org_id,
                    user_id: user_id,
                    rowper_page: rowper_page,
                    page_number: page_number,
                    searchKeyword: name
                })

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_NEW_LEAD, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_NEW_LEAD, payload: { data: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllNewLeadExecutive(org_id, user_id, rowper_page, page_number, name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_new_lead_executive",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    org_id: org_id,
                    executive_id: user_id,
                    rowper_page: rowper_page,
                    page_number: page_number,
                    name: name,
                })

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_NEW_LEAD, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_NEW_LEAD, payload: { data: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// search new leads
export function searchNewLead(all_new_lead, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_NEW_LEAD, payload: all_new_lead });
        }
        else {
            const newArray = all_new_lead.filter((el) => {
                return (el.name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_NEW_LEAD, payload: newArray })
        }
    };
}

// search followups lead
export function searchFollowUpsLead(all_followups_lead, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_FOLLOW_UPS_LEAD, payload: all_followups_lead });
        }
        else {
            const newArray = all_followups_lead.filter((el) => {
                return (el.name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_FOLLOW_UPS_LEAD, payload: newArray })
        }
    };
}

// search old lead

export function searchOldLead(all_old_lead, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_OLD_LEAD, payload: all_old_lead });
        }
        else {
            const newArray = all_old_lead.filter((el) => {
                return (el.name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_OLD_LEAD, payload: newArray })
        }
    };
}

export function setLeadDetails(payload) {
    return {
        type: SET_LEAD_DETAILS,
        payload: payload,
    };
}

// add lead deal
export function createLeadDeal(lead_id, product_name, admin_id, product_price, deal_status, priority, category) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "create_lead_deal", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                lead_id: lead_id

            },
            body: JSON.stringify({
                product_name: product_name,
                admin_id: admin_id,
                product_price: product_price,
                deal_status: deal_status,
                priority: priority,
                category: category
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllDealByLeadId(lead_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function editLeadDeal(lead_id, product_name, product_price, deal_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_deal", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                lead_id: lead_id,
                _id: deal_id,

            },
            body: JSON.stringify({
                product_name: product_name,
                product_price: product_price,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllDealByLeadId(lead_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateDealStatusPriority(deal_id, status, priority, lead_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_deal_status_priority", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                _id: deal_id
            },
            body: JSON.stringify({
                deal_status: status,
                priority: priority,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(view_lead_details(lead_id))
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
// view deallll

export function viewAllDealByLeadId(lead_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_deals_by_lead_id",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    lead_id: lead_id
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_DEAL_BY_LEAD_ID, payload: responseJson.result, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_DEAL_BY_LEAD_ID, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// add notes

export function createLeadNotes(lead_id, admin_id, titel, note_description, page_number, rowper_page, deal_id) {
    return (dispatch) => {
        console.log(deal_id);
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_lead_notes", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                lead_id: lead_id

            },
            body: JSON.stringify({
                admin_id: admin_id,
                titel: titel,
                note_description: note_description,
                deal_id: deal_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllNotesByDealId(deal_id, page_number, rowper_page, ""));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// view lead notes

export function viewAllNotesByLeadId(lead_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_notes_by_lead_id",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    lead_id: lead_id
                },

                body: JSON.stringify({
                    page_number: page_number,
                    rowper_page: rowper_page
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: SET_ALL_NOTES_BY_LEAD_ID, payload: responseJson.result, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_ALL_NOTES_BY_LEAD_ID, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllNotesByDealId(deal_id, page_number, rowper_page, name) {
    console.log(deal_id);
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_notes_by_deal_id",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    deal_id: deal_id
                },

                body: JSON.stringify({
                    page_number: page_number,
                    rowper_page: rowper_page,
                    name: name
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {
                // 
                if (responseJson.status) {
                    dispatch({ type: SET_ALL_NOTES_BY_LEAD_ID, payload: responseJson.result, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_ALL_NOTES_BY_LEAD_ID, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// search note
// export function searchNotes(all_notes_by_lead, e) {
//     return (dispatch) => {
//         if (e === "") {
//             dispatch({ type: SEARCH_NOTES, payload: all_notes_by_lead });
//         }
//         else {
//             const newArray = all_notes_by_lead.filter((el) => {
//                 return (el.titel.toLowerCase().includes(e.toLowerCase()));
//             })
//             dispatch({ type: SEARCH_NOTES, payload: newArray })
//         }
//     };
// }

export function searchNotes(lead_id, page_number, rowper_page, name) {
    return (dispatch) => {

        dispatch(unsetLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_notes_by_lead_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "Application/json",
                lead_id: lead_id,
            },
            body: JSON.stringify({

                name: name,
                page_number: page_number,
                rowper_page: rowper_page
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: SET_ALL_NOTES_BY_LEAD_ID, payload: responseJson.result, count: responseJson.length });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_ALL_NOTES_BY_LEAD_ID, payload: responseJson.result, count: responseJson.length });
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

//-------------------add follow up------------------------
export function addFollowup(lead_id, description, reminder, call_purpose, next_followup_date, deal_id, executive_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_followup", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                lead_id: lead_id

            },
            body: JSON.stringify({
                executive_id: executive_id,
                description: description,
                reminder: reminder,
                call_purpose: call_purpose,
                next_followup_date: next_followup_date,
                deal_id: deal_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_lead_details(lead_id));
                    dispatch(viewAllFollowupDeal(deal_id));
                    dispatch(set_snack_bar(responseJson.status, "followup Added Successfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(responseJson.status, "Number already exists "));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllFollowup(lead_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_followup", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                lead_id: lead_id

            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                // 
                if (responseJson.status) {
                    // dispatch(viewAlltheClients(admin_id,org_id));
                    dispatch({ type: VIEW_ALL_FOLLOW_UPS_LEAD, payload: { data: responseJson.result } });
                    dispatch(set_snack_bar(responseJson.status, "followup Added Successfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_FOLLOW_UPS_LEAD, payload: { data: [] } })
                    dispatch(set_snack_bar(responseJson.status, "Number already exists "));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllFollowupDeal(deal_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_followup_deal_id", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                deal_id: deal_id

            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                // ;
                if (responseJson.status) {
                    // dispatch(viewAlltheClients(admin_id,org_id));
                    dispatch({ type: VIEW_ALL_FOLLOW_UPS_LEAD, payload: { data: responseJson.result } });
                    dispatch(set_snack_bar(responseJson.status, "followup Added Successfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_FOLLOW_UPS_LEAD, payload: { data: [] } })
                    dispatch(set_snack_bar(responseJson.status, "Number already exists "));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function createPayment(org_id, lead_id, paid_by, amount, page_number, rowper_page, deal_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_lead_payment", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
                lead_id: lead_id,
            },
            body: JSON.stringify({
                paid_by: paid_by,
                amount: amount,
                deal_id: deal_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllPaymentByDealId(deal_id, page_number, rowper_page, ""));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// view lead notes

export function viewAllPaymentByLeadId(lead_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_payment_by_lead_id",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    lead_id: lead_id
                },

                body: JSON.stringify({
                    page_number: page_number,
                    rowper_page: rowper_page
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: SET_ALL_PAYMENT_BY_LEAD_ID, payload: responseJson.result, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_ALL_PAYMENT_BY_LEAD_ID, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllPaymentByDealId(deal_id, page_number, rowper_page, name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_payment_by_deal_id",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    deal_id: deal_id
                },

                body: JSON.stringify({
                    page_number: page_number,
                    rowper_page: rowper_page,
                    name: name
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: SET_ALL_PAYMENT_BY_LEAD_ID, payload: responseJson.result, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_ALL_PAYMENT_BY_LEAD_ID, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function searchPayment(lead_id, page_number, rowper_page, name) {
    return (dispatch) => {

        dispatch(unsetLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_payment_by_lead_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "Application/json",
                lead_id: lead_id,
            },
            body: JSON.stringify({

                name: name,
                page_number: page_number,
                rowper_page: rowper_page
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: SET_ALL_PAYMENT_BY_LEAD_ID, payload: responseJson.result, count: responseJson.length });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_ALL_PAYMENT_BY_LEAD_ID, payload: [], count: 0 });
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function uploadQuote(org_id, lead_id, deal_id, amount, quotefile) {
    return dispatch => {

        dispatch(setLoader(true));
        if (quotefile !== "") {
            const storageRef = ref(getStorage(), "/Quotes/" + quotefile + Math.random() + ".pdf")
            const uploadimage = uploadBytesResumable(storageRef, quotefile);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {

                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },

                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (quotefile) {
                        // 
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            // 
                            dispatch(addQuote(org_id, lead_id, deal_id, amount, quotefile));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(addQuote(org_id, lead_id, deal_id, amount, quotefile));
        }
    }
}

export function addQuote(org_id, lead_id, deal_id, amount, quotefile) {
    console.log(deal_id);
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_quotation", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
            },
            body: JSON.stringify({
                lead_id: lead_id,
                deal_id: deal_id,
                amount: amount,
                pdf: quotefile,

            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllQuoteByLeadId(deal_id, ''));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));

                } else {
                    dispatch(viewAllQuoteByLeadId(deal_id, ''));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllQuoteByLeadId(deal_id, name) {
    console.log(deal_id);
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_quotation", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                deal_id: deal_id,
                name: name
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: SET_ALL_QUOTES_BY_LEAD_ID, payload: responseJson.result });
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));

                } else {
                    dispatch({ type: SET_ALL_QUOTES_BY_LEAD_ID, payload: responseJson.result });
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

{/*-------------------------------------------------------*/ }
export function updateLead(lead_id, name, email, phone, city, state, country, pin, address) {

    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_lead", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                lead_id: lead_id
            },
            body: JSON.stringify({
                name: name,
                email: email,
                phone: phone,
                city: city,
                state: state,
                country: country,
                pin: pin,
                address: address,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(view_lead_details(lead_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

{/*-------------------------------------------------------*/ }
export function approveQuotation(deal_id, quote_id, amount) {

    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "approve_quotation", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                deal_id: deal_id,
                quotation_id: quote_id,
                amount: amount,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllQuoteByLeadId(deal_id, ''));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}








