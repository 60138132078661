import {
  VIEW_ALL_SALES_BY_ORG_ID,
  SEARCH_SALE,
  SEARCH_PAYMENT,
  VIEW_ALL_SALES_BY_PRODUCT_AND_PAYMENT
} from "./constant";
const initial_state = {
  view_all_sales_by_org_id: [],
  search_sale: [],
  view_all_sales_by_product_and_payment:[],
  sale_length:0,
  payment_length:0,
  search_payment: []
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
      case VIEW_ALL_SALES_BY_ORG_ID:
        
        return {
          ...state,
          view_all_sales_by_org_id: action.payload, search_sale: action.payload, sale_length: action.count
        };

    case SEARCH_SALE:
      
      return state = { ...state, search_sale: action.payload };


      case VIEW_ALL_SALES_BY_PRODUCT_AND_PAYMENT:
        return state = { ...state, 
          view_all_sales_by_product_and_payment: action.payload, search_payment: action.payload, payment_length: action.count };


          case SEARCH_PAYMENT:
      
          return state = { ...state, search_payment: action.payload };

    default:
      return state;
  }
}