import React, { Component } from "react";
import { connect } from "react-redux";
import Template from "../Components/template";
import {
  close_snack_bar
} from "../../../common/snackbar/action";
import {addWhatsappTemplet,viewAllCategory,uploadWhatsappTemplet,addSMSTemplet} from "../action";


export class Controller extends Component {
    render() {
      return (
        <Template {...this.props} />
      );
    }
  };
  export const mapStateToProps = store => {
    return {
      employee: store.employee,
      login: store.login,
      setting: store.setting,
    };
  };
  export const mapDispatchToProps = dispatch => {
    return {
      close_snack_bar: () => {
        dispatch(close_snack_bar());
      },
  
      viewAllCategory: (org_id) => {
        dispatch(viewAllCategory(org_id));
      },

    //   addWhatsappTemplet: (org_id,image_upload,link,templet_name,category,desc) => {
    //     dispatch(addWhatsappTemplet(org_id,image_upload,link,templet_name,category,desc));
    //   },

      uploadWhatsappTemplet: (org_id,image_upload,link,templet_name,category,desc) => {
        dispatch(uploadWhatsappTemplet(org_id,image_upload,link,templet_name,category,desc));
      },


      addSMSTemplet: (org_id,link,templet_name) => {
        dispatch(addSMSTemplet(org_id,link,templet_name));
      },

     
    
  
     
      
     
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(Controller);