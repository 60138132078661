import React, { Component } from "react";
import { connect } from "react-redux";
import Vieworg from "../components/vieworg";
import {
  close_snack_bar
} from "../../common/snackbar/action";
import {
  clear_login,
  viewAllOrgbyMobile,
  viewUserOrgDetails,
  setOrgId,
  viewAccessByUser,
  uploadOrgLogo,
  create_new_access,
  view_owned_org
} from "../actions";
export class Controller extends Component {
  render() {
    return (
      <Vieworg {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    clear_login: () => {
      dispatch(clear_login())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewAllOrgbyMobile: (mobile) => {
      dispatch(viewAllOrgbyMobile(mobile));
    },
    viewUserOrgDetails: (user_id, org_id) => {
      dispatch(viewUserOrgDetails(user_id, org_id));
    },
    viewAccessByUser: (user_id) => {
      dispatch(viewAccessByUser(user_id));
    },
    uploadOrgLogo: (org_logo, org_name, org_email, org_num, org_gstno, user_id, org_address) => {
      dispatch(uploadOrgLogo(org_logo, org_name, org_email, org_num, org_gstno, user_id, org_address));
    },
    setOrgId: (org_id) => {
      dispatch(setOrgId(org_id));
    },

    create_new_access: (user_id, org_id, product) => {
      dispatch(create_new_access(user_id, org_id, product));
    },

    view_owned_org: (user_id, product) => {
      dispatch(view_owned_org(user_id, product));
    },
     
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);