
import { Card, Grid, Typography, Box, Button, CardContent } from '@mui/material'
import React, { Component } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddBoxIcon from '@mui/icons-material/AddBox';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import SettingDrawer from "../../settings/Components/SettingDrawer";
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import { Edit } from 'iconsax-react';


export default class businesspersonal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editperinfo: false,
            addpermodal: false,
            name: "",
            email: "",
            phone: ""
        };
    }

    componentDidMount() {
        this.props.viewAllPersonalDetails(this.props.login.user_id);
        ;
    }

    render() {
        return (
            <Grid style={{ marginTop: "10%", display: "flex", gap: 20 }}>

                <Grid>
                    <SettingDrawer />
                </Grid>
                


                <Dialog
                    onClose={() => this.setState({ editperinfo: false })}
                    open={this.state.editperinfo} style={{}}>
                    <Grid style={{ width: "520px", marginBottom: "2%" }}>

                        <Grid style={{ marginTop: "3%", marginLeft: "3%", marginRight: "3%", marginBottom: "3%" }}>
                            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography style={{ fontSize: "17px", fontWeight: "600" }}>Name</Typography>
                                <Typography style={{ cursor: 'pointer', color: 'red', fontSize: "17px", fontWeight: "600" }}
                                    onClick={() => {
                                        this.setState({
                                            editperinfo: false,
                                        });
                                        this.props.updatePersonalDetails(this.props.login.user_id, this.state.name, this.state.email, this.state.mobile);
                                    }}
                                >Save</Typography>
                            </Grid>
                            <TextField style={{ fontSize: "17px", fontWeight: "600" }} id="standard-basic" variant="standard"
                                value={this.state.name}
                                onChange={(e) => {
                                    this.setState({ name: e.target.value })
                                }}
                            />

                            <Grid style={{ marginTop: "2%" }}>

                                <Typography style={{ fontSize: "17px", fontWeight: "600" }}>Email</Typography>
                                <TextField style={{ fontSize: "17px", fontWeight: "600", width: 'auto' }} id="standard-basic" variant="standard"
                                    value={this.state.email}

                                    onChange={(e) => {
                                        this.setState({ email: e.target.value })
                                    }}
                                />

                            </Grid>

                            <Grid style={{ marginTop: "2%" }}>


                                <Typography style={{ fontSize: "17px", fontWeight: "600" }}>Phone No</Typography>
                                <TextField style={{ marginLeft: "2%", }} id="standard-basic" variant="standard"
                                    value={this.state.mobile}
                                    onChange={(e) => {
                                        this.setState({ mobile: e.target.value })
                                    }}
                                />



                            </Grid>


                        </Grid>
                    </Grid>
                </Dialog>


                <Dialog


                    open={this.state.addpermodal} style={{}}>
                    <Grid style={{ height: "250px", width: "360px" }}>

                        <Box style={{ textAlign: "center" }}>

                            <Typography style={{ fontSize: "18px", fontWeight: "500", marginTop: "3%" }}>Add Personal Details</Typography>


                            <Grid style={{ marginTop: "5%", marginLeft: "12%", marginRight: "12%" }}>

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "4%" }}>
                                    <Typography style={{}}>Name</Typography>
                                    <TextField style={{ marginLeft: "2%", marginTop: "-7px" }} id="standard-basic" variant="standard"
                                        onChange={(text) => this.setState({ name: text.target.value })}
                                    />
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "4%" }}>
                                    <Typography>Email</Typography>
                                    <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                        onChange={(text) => this.setState({ email: text.target.value })}
                                    />
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "4%" }}>
                                    <Typography>Phone</Typography>
                                    <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                        onChange={(text) => this.setState({ phone: text.target.value })}
                                    />
                                </Grid>



                                <Grid style={{ marginTop: "8%", display: "flex", justifyContent: "center" }}>
                                    <Button
                                        onClick={() => { this.setState({ addpermodal: false }); }}
                                        variant='outlined' style={{ color: "black" }}>cancel</Button>
                                    <Button

                                        variant='contained' style={{ marginLeft: "3%" }}
                                        onClick={() => {
                                            this.setState({ addpermodal: false })
                                            this.props.addPersonalDetails(this.props.login.org_id, this.state.name, this.state.email, this.state.phone)
                                        }}
                                    >save</Button>
                                </Grid>
                            </Grid>




                        </Box>

                    </Grid>
                </Dialog>

            </Grid>
        )
    }
}
