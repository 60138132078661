import {
  VIEW_ALL_PRODUCT,
  VIEW_ALL_PRODUCT_WITH_PGN,
  VIEW_PRODUCT_BY_ID,
  SEARCH_PRODUCT,
  SEARCH_PRODUCT_BY_ADMIN,
  VIEW_PRODUCT_BY_ORG_ID

} from "./constant";
const initial_state = {
  prroduct: [],
  view_product_by_id: [],
  set_product_name: "",
  set_product_short_id: "",
  set_product_price: "",
  set_Product_stock: "",
  set_product_desc: "",
  set_product_id: "",
  product_id: "",
  view_all_product_with_pagination: [],
  search_product: [],
  man_length: 0,
  exe_length: 0,
  search_product_by_admin: [],
  view_all_product: [],
  product_Length: 0,
  view_product_by_org_id: [],
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case VIEW_ALL_PRODUCT_WITH_PGN:
      return state = { ...state, view_all_product_with_pagination: action.payload, product_Length: action.count };

    case SEARCH_PRODUCT_BY_ADMIN:
      return state = { ...state, search_product_by_admin: action.payload };

    case VIEW_PRODUCT_BY_ORG_ID:
      return state = { ...state, view_product_by_org_id: action.payload };

    case VIEW_PRODUCT_BY_ID:
      return state = { ...state, view_product_by_id: action.payload };

    case VIEW_ALL_PRODUCT:
      return state = { ...state, view_all_product: action.payload };

    case SEARCH_PRODUCT:
      return state = { ...state, search_product: action.payload };

    default:
      return state;


  }
}
