import React, { Component } from 'react';
import { Grid, Typography, Button, } from '@mui/material';
import SettingDrawer from "../../settings/Components/SettingDrawer";
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,


} from "@mui/material"
import { Edit2, Trash, Add } from 'iconsax-react';



export default class businesscategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editcat: false,
      delcat: false,
      addcat: false,
      org_id: "",
      cate_name: "",
      addtext: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({ addtext: true });
  };

  handleMouseLeave = () => {
    this.setState({ addtext: false });
  };

  componentDidMount() {
    this.props.viewAllCategory(this.props.login.org_id);
  }
  render() {
    return (
      <Grid style={{ marginTop: "10%", display: "flex", gap: 20 }}>

        <Grid>
          <SettingDrawer />
        </Grid>

        <Grid>
          <Grid style={{ display: "flex", justifyContent: "space-between", }}>
            <Typography style={{ color: 'var(--PrimaryColour-G3, #666)', fontFamily: 'Roboto', fontSize: '1.15rem', fontWeight: 500, marginBottom: '22px' }}>Category</Typography>
            <Grid
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            >
              <Add
                size="24"
                color="#525050"
                variant="Bold"
                cursor={'pointer'}
                onClick={() => { this.setState({ addcat: true }); }}
              />
              {this.state.addtext && <Typography style={{ fontSize: '0.8rem' }}>Add</Typography>}
            </Grid>
          </Grid>

          <TableContainer component={Paper} style={{ marginTop: '1rem', width: '37rem', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid var(--PrimaryColour-G9, #E0E0E0)', background: '#FFF', boxShadow: '1px 1px 8px 0px rgba(86, 86, 86, 0.20), -1px -1px 8px 0px rgba(86, 86, 86, 0.20)' }}>
            <Table size="small" aria-label="a dense table">
              <TableHead style={{ background: "lightgray" }}>
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>Category</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                </TableRow>
              </TableHead>
              {Array.isArray(this.props.setting.category) && this.props.setting.category.map((item) => {
                return (
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>{item.cate_name}</TableCell>
                      <TableCell>
                        <Grid style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center" }}>
                          <Edit2 size="24" color="#525050" variant="Outline"
                            onClick={() => {
                              this.setState({
                                editcat: true,
                                cate_name: item.cate_name,
                                category_id: item._id
                              })
                            }}
                          />
                          <Trash size="24" color="#CC2E2E" variant="Outline"
                            onClick={() => {
                              this.setState({
                                delcat: true,
                                category_id: item._id
                              })
                            }}
                          />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              })}
            </Table>
          </TableContainer>

        </Grid >

        <Dialog
          // onClose={()=>this.setState({ editcat: false })}
          open={this.state.addcat} style={{}}>
          <Grid style={{ width: "450px", marginBottom: "2%", marginTop: "3%" }}>


            <Grid style={{ marginTop: "3%", marginLeft: "3%", marginRight: "3%", marginBottom: "3%" }}>

              <Grid style={{ fontSize: "20px", fontWeight: "500", marginTop: "3%", display: "flex", justifyContent: "center" }}>Add Category</Grid>
              <Grid style={{ display: "flex", flexDirection: "row", marginTop: "3%", justifyContent: "center" }}>

                <Typography style={{}}>Category Name</Typography>
                <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                  onChange={(text) => this.setState({ cate_name: text.target.value })}
                />

              </Grid>

              <Grid style={{ marginTop: "8%", display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => { this.setState({ addcat: false }); }}
                  variant='outlined' style={{ color: "black" }}>cancel</Button>
                <Button
                  variant='contained' style={{ marginLeft: "3%" }}
                  onClick={() => {
                    this.setState({ addcat: false })
                    this.props.addCategory(this.props.login.org_id, this.state.cate_name)
                  }}
                >
                  Save
                </Button>
              </Grid>


            </Grid>

          </Grid>
        </Dialog>


        <Dialog
          // onClose={()=>this.setState({ editcat: false })}
          open={this.state.editcat} style={{}}>
          <Grid style={{ width: "500px", marginBottom: "2%" }}>


            <Grid style={{ marginTop: "3%", marginLeft: "3%", marginRight: "3%", marginBottom: "3%" }}>

              <Grid style={{ fontSize: "20px", fontWeight: "500", marginTop: "3%", display: "flex", justifyContent: "center" }}>Edit Category</Grid>
              <Grid style={{ display: "flex", flexDirection: "row", marginTop: "3%", justifyContent: "center" }}>

                <Typography style={{}}>Category Name</Typography>
                <TextField placeholder='Order' id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                  value={this.state.cate_name}
                  onChange={(text) => this.setState({ cate_name: text.target.value })}
                />

              </Grid>

              <Grid style={{ marginTop: "8%", display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => { this.setState({ editcat: false }); }}
                  variant='outlined' style={{ color: "black" }}>cancel</Button>
                <Button
                  variant='contained' style={{ marginLeft: "3%" }}
                  onClick={() => {
                    this.setState({ editcat: false })
                    this.props.updateCategory(this.props.login.org_id, this.state.category_id, this.state.cate_name)
                  }}
                >
                  Save Changes
                </Button>
              </Grid>


            </Grid>

          </Grid>
        </Dialog>

        <Dialog
          // onClose={()=>this.setState({ editcat: false })}
          open={this.state.delcat} style={{}}>
          <Grid style={{ width: "500px", marginBottom: "2%" }}>


            <Grid style={{ marginTop: "3%", marginLeft: "3%", marginRight: "3%", marginBottom: "3%" }}>

              <Grid style={{ fontSize: "20px", fontWeight: "500", marginTop: "3%", display: "flex", justifyContent: "center" }}>Are you sure?</Grid>


              <Grid style={{ marginTop: "8%", display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => { this.setState({ delcat: false }); }}
                  variant='outlined' style={{ color: "black" }}>No, go back</Button>
                <Button
                  onClick={() => {
                    this.setState({ delcat: false })
                    this.props.deleteCategory(this.props.login.org_id, this.state.category_id)
                  }}
                  variant='contained' style={{ marginLeft: "3%" }}>Yes, Delete it</Button>
              </Grid>


            </Grid>

          </Grid>
        </Dialog>
      </Grid >
    )
  }
}
