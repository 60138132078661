import React, { Component } from "react";
import { connect } from "react-redux";
import Product from "../Component/product";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  uploadImageProduct,
  updateimageproduct,
  deleteProduct,
  viewAllProductWithPgn,
  bulkDeleteProduct,
  searchProductByAdmin,
  SearchProduct

} from "../Action"


export class Controller extends Component {
  render() {
    return (
      <Product {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    product: store.product,
    login: store.login,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {



    uploadImageProduct: (org_id, product_img, product_shortId, product_name, product_price, product_stock, product_desc, product_type) => {
      dispatch(uploadImageProduct(org_id, product_img, product_shortId, product_name, product_price, product_stock, product_desc, product_type))
    },



    updateimageproduct: (org_id, product_id, old_product_img, product_img, product_shortId, product_name, product_price, product_stock, product_desc,product_type) => {
      dispatch(updateimageproduct(org_id, product_id, old_product_img, product_img, product_shortId, product_name, product_price, product_stock, product_desc,product_type))
    },



    viewAllProductWithPgn: (org_id,page_number,rowper_page) => {
      dispatch(viewAllProductWithPgn(org_id,page_number,rowper_page))
    },



    deleteProduct: (org_id, _id,) => {
      dispatch(deleteProduct(org_id,_id))
    },

    bulkDeleteProduct: (product_id) => {
      dispatch(bulkDeleteProduct(product_id))
    },
    searchProductByAdmin: (view_all_product_with_pagination, e) => {
      dispatch(searchProductByAdmin(view_all_product_with_pagination, e))
    },

    SearchProduct: (org_id,page_number,rowper_page,name) => {
      dispatch(SearchProduct(org_id,page_number,rowper_page,name))
    },


    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);