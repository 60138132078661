import React, { Component } from "react";
import { connect } from "react-redux";
import Businesssetting from "../Components/businesssetting";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  viewOrgDetailsById, updateimageOrganization,
  addCategory, updateCategory, deleteCategory, viewAllCategory,
  addPersonalDetails, viewAllPersonalDetails, updatePersonalDetails
} from '../action'


export class Controller extends Component {
  render() {
    return (
      <Businesssetting {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    employee: store.employee,
    login: store.login,
    setting: store.setting
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

    viewOrgDetailsById: (org_id, token) => {
      dispatch(viewOrgDetailsById(org_id, token));
    },

    updateimageOrganization: (org_name, org_email, org_num, org_logo, org_logo_old, org_gstno, org_id, org_address, token) => {
      dispatch(updateimageOrganization(org_name, org_email, org_num, org_logo, org_logo_old, org_gstno, org_id, org_address, token));
    },
    addCategory: (org_id, cate_name) => {
      dispatch(addCategory(org_id, cate_name));
    },

    viewAllCategory: (org_id) => {
      dispatch(viewAllCategory(org_id));
    },

    updateCategory: (org_id, category_id, cate_name) => {
      dispatch(updateCategory(org_id, category_id, cate_name));
    },

    deleteCategory: (org_id, category_id) => {
      dispatch(deleteCategory(org_id, category_id));
    },

    addPersonalDetails: (org_id, name, email, phone) => {
      dispatch(addPersonalDetails(org_id, name, email, phone));
    },

    viewAllPersonalDetails: (user_id) => {
      dispatch(viewAllPersonalDetails(user_id));
    },
    updatePersonalDetails: (user_id, name, email, mobile) => {
      dispatch(updatePersonalDetails(user_id, name, email, mobile));
    },





  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);