import {
  VIEW_ALL_LEAD,
  VIEW_ALL_LEAD_WITH_PGN,
  VIEW_ALL_FOLLOW_UPS_LEAD,
  VIEW_ALL_OLD_LEAD,
  VIEW_ALL_TODAY_LEAD,
  VIEW_ALL_LEAD_BY_ID,
  VIEW_ASSIGNED_BY_LEAD,
  VIEW_LEAD_BY_MANAGER_ID,
  VIEW_ALL_SOURCE,
  VIEW_ALL_NEW_LEAD,
  SEARCH_NEW_LEAD,
  SEARCH_FOLLOW_UPS_LEAD,
  SEARCH_OLD_LEAD,
  SET_LEAD_DETAILS,
  VIEW_ALL_DEAL_BY_LEAD_ID,
  SET_ALL_NOTES_BY_LEAD_ID,
  SEARCH_NOTES,
  SET_ALL_PAYMENT_BY_LEAD_ID,
  SET_ALL_QUOTES_BY_LEAD_ID
} from "./constant";
const initial_state = {
  all_lead: [],
  all_source: [],
  all_lead_with_pgn: [],
  all_followups_lead: [],
  all_today_lead: [],
  all_lead_by_id: [],
  assigned_by_lead: [],
  lead_by_manager_id: [],
  all_new_lead: [],
  search_new_lead: [],
  search_all_follow_up_lead: [],
  // search_all_old_lead:[],
  set_lead_details: {},
  all_deal_by_lead_id: [],
  deal_length: 0,
  all_notes_by_lead: [],
  search_notes: [],
  all_new_lead_length: 0,
  all_old_leade_length: 0,
  all_followups_lead_length: 0,
  all_payments: [],
  payment_length: 0,
  all_quotes: [],
  quotes_length: 0
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case VIEW_ALL_LEAD:
      return {
        ...state,
        all_lead: action.payload
      };

    case VIEW_ALL_LEAD_WITH_PGN:
      return {
        ...state,
        all_lead_with_pgn: action.payload
      };

    case VIEW_ALL_FOLLOW_UPS_LEAD:
      return {
        ...state,
        all_followups_lead: action.payload.data, search_all_follow_up_lead: action.payload.data, all_followups_lead_length: action.payload.count
      };

    case VIEW_ALL_OLD_LEAD:
      return {
        ...state,
        all_old_lead: action.payload.data, search_all_old_lead: action.payload.data, all_old_leade_length: action.payload.count
      };

    case VIEW_ALL_SOURCE:

      return {
        ...state,
        all_source: action.payload
      };


    case VIEW_ALL_TODAY_LEAD:
      return {
        ...state,
        all_today_lead: action.payload
      };

    case VIEW_ALL_LEAD_BY_ID:
      return {
        ...state,
        all_lead_by_id: action.payload
      };

    case VIEW_ASSIGNED_BY_LEAD:
      return {
        ...state,
        assigned_by_lead: action.payload
      };

    case VIEW_LEAD_BY_MANAGER_ID:
      return {
        ...state,
        lead_by_manager_id: action.payload
      };

    case VIEW_ALL_NEW_LEAD:
      return {
        ...state,
        all_new_lead: action.payload.data, search_new_lead: action.payload.data, all_new_lead_length: action.payload.count
      };

    case SEARCH_NEW_LEAD:
      return {
        ...state,
        search_new_lead: action.payload
      };

    case SEARCH_FOLLOW_UPS_LEAD:
      return {
        ...state,
        search_all_follow_up_lead: action.payload
      };

    case SEARCH_OLD_LEAD:
      return {
        ...state,
        search_all_old_lead: action.payload
      };


    case SET_LEAD_DETAILS:

      return {
        ...state,
        set_lead_details: action.payload
      };

    case VIEW_ALL_DEAL_BY_LEAD_ID:

      return {
        ...state,
        all_deal_by_lead_id: action.payload, deal_length: action.count
      };

    case SET_ALL_NOTES_BY_LEAD_ID:

      return {
        ...state,
        all_notes_by_lead: action.payload, notes_length: action.count,
      };

    case SEARCH_NOTES:
      return {
        ...state,
        search_notes: action.payload
      };
    case SET_ALL_PAYMENT_BY_LEAD_ID:
      return {
        ...state,
        all_payments: action.payload, payment_length: action.count
      };
    case SET_ALL_QUOTES_BY_LEAD_ID:
      return {
        ...state,
        all_quotes: action.payload, quotes_length: action.count
      };

    default:
      return state;
  }
}
