export const VIEW_SALE_PRICE="VIEW_SALE_PRICE";
export const VIEW_SALE_LENGTH="VIEW_SALE_LENGTH";
export const VIEW_ALL_ORDER="VIEW_ALL_ORDER";
export const VIEW_ALL_NEW_CUSTOMER="VIEW_ALL_NEW_CUSTOMER";
export const VIEW_EXECUTIVE_NEW_CUSTOMER="VIEW_EXECUTIVE_NEW_CUSTOMER";
export const VIEW_EXECUTIVE_ORDER="VIEW_EXECUTIVE_ORDER";
export const VIEW_SALE_PRICE_EXECUTIVE="VIEW_SALE_PRICE_EXECUTIVE";
export const VIEW_SALE_LENGTH_EXECUTIVE="VIEW_SALE_LENGTH_EXECUTIVE";





export const VIEW_ALL_SOLD_PRODUCT="VIEW_ALL_SOLD_PRODUCT";

export const MANAGER_OF_THE_MONTH="MANAGER_OF_THE_MONTH";
export const EXECUTIVE_OF_THE_MONTH="EXECUTIVE_OF_THE_MONTH";

export const VIEW_LEAD_DATA_BY_MONTH="VIEW_LEAD_DATA_BY_MONTH";
export const VIEW_LEAD_DATA_BY_WEEK="VIEW_LEAD_DATA_BY_WEEK";