import { Card, Grid, Typography, Button, Box, CardContent } from '@mui/material'
import React, { Component } from 'react';
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Edit } from 'iconsax-react';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material"
import { Edit2, Trash, Add } from 'iconsax-react';


export default class businesssetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorginfo: false,
            addorgmodal: false,
            org_name: "",
            org_email: "",
            org_num: "",
            org_logo: "",
            org_gstno: "",
            org_logo_old: "",
            org_address: '',
            selectedsetting: 'org'
        };
    }

    componentDidMount() {
        this.props.viewOrgDetailsById(this.props.login.org_id, this.props.login.token);
        this.props.viewAllPersonalDetails(this.props.login.user_id);
        this.props.viewAllCategory(this.props.login.org_id);

        console.log(this.props.setting.org_details_by_id);


    }
    render() {
        return (
            <Grid style={{ marginTop: "5%", display: "flex", gap: 20 }}>

                <Grid>
                    <Card style={{ borderRadius: "0.25rem", boxShadow: "none", border: "1px solid #E0E0E0" }}>

                        <Card style={{ margin: '10px', borderRadius: "0.5rem", boxShadow: "none", border: "1px solid #E0E0E0" }}>
                            <Typography className='text' style={{ cursor: 'pointer', color: this.state.selectedsetting === 'org' ? 'black' : '', fontWeight: this.state.selectedsetting === 'org' ? 500 : '', fontSize: this.state.selectedsetting === 'org' ? '1.2rem' : '' }}
                                onClick={() => {
                                    this.setState({
                                        selectedsetting: 'org',
                                    });
                                }}
                            >
                                Organizational Details
                            </Typography>
                            <Typography className='text' style={{ cursor: 'pointer', color: this.state.selectedsetting === 'person' ? 'black' : '', fontWeight: this.state.selectedsetting === 'person' ? 500 : '', fontSize: this.state.selectedsetting === 'person' ? '1.2rem' : '' }}
                                onClick={() => {
                                    this.setState({
                                        selectedsetting: 'person',
                                    });
                                }}
                            >
                                Personal Details
                            </Typography>
                            <Typography className='text' style={{ cursor: 'pointer', color: this.state.selectedsetting === 'cate' ? 'black' : '', fontWeight: this.state.selectedsetting === 'cate' ? 500 : '', fontSize: this.state.selectedsetting === 'cate' ? '1.2rem' : '' }}
                                onClick={() => {
                                    this.setState({
                                        selectedsetting: 'cate',
                                    });
                                }}
                            >
                                Category Setting
                            </Typography>
                        </Card>
                    </Card>
                </Grid>

                {this.state.selectedsetting === 'org' &&
                    <Grid>

                        {/* <Grid style={{ display: "flex", flexDirection: "row", marginLeft: "3%", alignItems: "center" }}>
                        <Typography style={{ fontSize: "18px", fontWeight: "500" }}>Account Setting</Typography>
                        <ArrowForwardIosIcon style={{ fontSize: "15px", fontWeight: "500" }} />
                        <Typography style={{ fontSize: "15px", marginLeft: "2%" }}>Organisation Details</Typography>
                    </Grid> */}
                        <Grid>
                            <Typography style={{ color: 'var(--PrimaryColour-G3, #666)', fontFamily: 'Roboto', fontSize: '1.15rem', fontWeight: 500 }}>Organisation Details</Typography>
                            <Card style={{ marginTop: '1rem', width: '38.9375rem', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid var(--PrimaryColour-G9, #E0E0E0)', background: '#FFF', boxShadow: '1px 1px 8px 0px rgba(86, 86, 86, 0.20), -1px -1px 8px 0px rgba(86, 86, 86, 0.20)' }}>
                                <CardContent>
                                    <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Avatar
                                            src={this.props.setting.org_details_by_id.org_logo !== (undefined || null) ? this.props.setting.org_details_by_id.org_logo : ""}
                                            style={{ width: '5rem', height: '5rem' }} >
                                        </Avatar>
                                        {/* <Edit size="25" color="#525050" variant="Outline"
                                            onClick={() => {
                                                this.setState({
                                                    editorginfo: true,
                                                    org_name: this.props.setting.org_details_by_id.org_name,
                                                    org_email: this.props.setting.org_details_by_id.org_email,
                                                    org_num: this.props.setting.org_details_by_id.org_phone,
                                                    org_gstno: this.props.setting.org_details_by_id.org_gstno,
                                                    org_address: this.props.setting.org_details_by_id.org_address,
                                                    org_logo_old: this.props.setting.org_details_by_id.org_logo
                                                });
                                            }} /> */}
                                    </Grid>

                                    <Grid container marginTop={'20px'}>

                                        <Grid item xs={6}>
                                            <Typography style={{ fontSize: "1rem", fontWeight: "400", color: "#7A7A7A" }}>Name</Typography>
                                            <Typography style={{ fontSize: "1.15rem", fontWeight: "500", color: '#3D3B3B' }}>{this.props.setting.org_details_by_id.org_name !== (undefined || null) ? this.props.setting.org_details_by_id.org_name : ""}</Typography>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Typography style={{ fontSize: "1rem", fontWeight: "400", color: "#7A7A7A" }}>Email</Typography>
                                            <Typography style={{ fontSize: "1.15rem", fontWeight: "500", color: '#3D3B3B' }}>{this.props.setting.org_details_by_id.org_email !== (undefined || null) ? this.props.setting.org_details_by_id.org_email : ""}</Typography>
                                        </Grid>

                                    </Grid>

                                    <Grid container marginTop={'20px'}>

                                        <Grid item xs={6}>
                                            <Typography style={{ fontSize: "1rem", fontWeight: "400", color: "#7A7A7A" }}>Phone No</Typography>
                                            <Typography style={{ fontSize: "1.15rem", fontWeight: "500", color: '#3D3B3B' }}>{this.props.setting.org_details_by_id.org_phone !== (undefined || null) ? this.props.setting.org_details_by_id.org_phone : ""}</Typography>
                                        </Grid>

                                        {/* <Grid style={{ width: "270px" }}>
                                        <Typography>GSTIN</Typography>
                                        <Typography style={{ fontSize: "17px", fontWeight: "600" }}>{this.props.setting.org_details_by_id.org_gstno !== (undefined || null) ? this.props.setting.org_details_by_id.org_gstno : ""}</Typography>
                                    </Grid> */}

                                    </Grid>
                                    <Grid container marginTop={'20px'}>

                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: "1rem", fontWeight: "400", color: "#7A7A7A" }}>Address</Typography>
                                            <Typography style={{ fontSize: "1.15rem", fontWeight: "500", color: '#3D3B3B' }}>{this.props.setting.org_details_by_id.org_address !== (undefined || null) ? this.props.setting.org_details_by_id.org_address : ""}</Typography>
                                        </Grid>

                                    </Grid>


                                </CardContent>
                            </Card>

                        </Grid>
                    </Grid>
                }
                {this.state.selectedsetting === 'person' &&
                    <Grid>

                        {/* <Grid style={{ display: "flex", flexDirection: "row", marginLeft: "3%", alignItems: "center" }}>
                        <Typography style={{ fontSize: "18px", fontWeight: "500" }}>Account Setting</Typography>
                        <ArrowForwardIosIcon style={{ fontSize: "15px", fontWeight: "500" }} />
                        <Typography style={{ fontSize: "15px", marginLeft: "2%" }}>Personal Details</Typography>
                    </Grid> */}


                        <Grid>
                            <Typography style={{ color: 'var(--PrimaryColour-G3, #666)', fontFamily: 'Roboto', fontSize: '1.15rem', fontWeight: 500 }}>Personal Details</Typography>
                            <Card style={{ marginTop: '1rem', width: '30.9375rem', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid var(--PrimaryColour-G9, #E0E0E0)', background: '#FFF', boxShadow: '1px 1px 8px 0px rgba(86, 86, 86, 0.20), -1px -1px 8px 0px rgba(86, 86, 86, 0.20)' }}>
                                <CardContent>
                                    <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                                        {/* <Typography style={{ fontSize: "1rem", fontWeight: "400", color: "#7A7A7A" }}>Name</Typography> */}
                                        {/* <Edit size="25" color="#525050" variant="Outline"
                                            onClick={() => {
                                                this.setState({
                                                    editperinfo: true,
                                                    name: this.props.setting.personal_details.name,
                                                    email: this.props.setting.personal_details.email_id,
                                                    mobile: this.props.setting.personal_details.mobile
                                                });
                                            }}
                                        />  */}
                                    </Grid>
                                    <Typography style={{ fontSize: "1rem", fontWeight: "400", color: "#7A7A7A" }}>Name</Typography>
                                    <Typography style={{ fontSize: "1.15rem", fontWeight: "500", color: '#3D3B3B' }}> {this.props.setting.personal_details.name ? this.props.setting.personal_details.name : ""}</Typography>



                                    <Grid style={{ marginTop: "20px" }}>
                                        <Typography style={{ fontSize: "1rem", fontWeight: "400", color: "#7A7A7A" }}>Email</Typography>
                                        <Typography style={{ fontSize: "1.15rem", fontWeight: "500", color: '#3D3B3B' }}>  {this.props.setting.personal_details.email_id ? this.props.setting.personal_details.email_id : ""}</Typography>
                                    </Grid>

                                    <Grid style={{ marginTop: "20px" }}>
                                        <Typography style={{ fontSize: "1rem", fontWeight: "400", color: "#7A7A7A" }}>Phone No</Typography>
                                        <Typography style={{ fontSize: "1.15rem", fontWeight: "500", color: '#3D3B3B' }}>  {this.props.setting.personal_details.mobile ? this.props.setting.personal_details.mobile : ""}</Typography>
                                    </Grid>


                                </CardContent>
                            </Card>

                        </Grid>
                    </Grid>
                }
                {this.state.selectedsetting === 'cate' &&
                    <Grid>
                        <Grid style={{ display: "flex", justifyContent: "space-between", }}>
                            <Typography style={{ color: 'var(--PrimaryColour-G3, #666)', fontFamily: 'Roboto', fontSize: '1.15rem', fontWeight: 500, marginBottom: '22px' }}>Category</Typography>
                            {this.props.login.type === "Owner" &&
                                <Add
                                    size="24"
                                    color="#525050"
                                    variant="Bold"
                                    cursor={'pointer'}
                                    onClick={() => { this.setState({ addcat: true }); }}
                                />
                            }
                        </Grid>

                        <TableContainer component={Paper} style={{ marginTop: '1rem', width: '37rem', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid var(--PrimaryColour-G9, #E0E0E0)', background: '#FFF', boxShadow: '1px 1px 8px 0px rgba(86, 86, 86, 0.20), -1px -1px 8px 0px rgba(86, 86, 86, 0.20)' }}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead style={{ background: "lightgray" }}>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center' }}>Category</TableCell>
                                        {this.props.login.type === "Owner" &&
                                            <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                {Array.isArray(this.props.setting.category) && this.props.setting.category.map((item) => {
                                    return (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ textAlign: 'center' }}>{item.cate_name}</TableCell>
                                                {this.props.login.type === "Owner" &&
                                                    <TableCell>
                                                        <Grid style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center" }}>
                                                            <Edit2 size="24" color="#525050" variant="Outline"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        editcat: true,
                                                                        cate_name: item.cate_name,
                                                                        category_id: item._id
                                                                    })
                                                                }}
                                                            />
                                                            <Trash size="24" color="#CC2E2E" variant="Outline"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        delcat: true,
                                                                        category_id: item._id
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        </TableBody>
                                    )
                                })}
                            </Table>
                        </TableContainer>

                    </Grid >
                }


                {/* edit organizationn */}
                <Dialog
                    onClose={this.handleClose}
                    open={this.state.editorginfo} style={{}}>
                    <Grid style={{ height: "450px", width: "360px" }}>

                        <Box style={{ textAlign: "center" }}>

                            <Typography style={{ fontSize: "18px", fontWeight: "500", marginTop: "3%" }}>Update Organisation Details</Typography>

                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div>
                                    <Avatar
                                        alt="Profile"
                                        src={this.state.org_logo ? URL.createObjectURL(this.state.org_logo) : this.state.org_logo_old}
                                        variant={"circular"}
                                        style={{ width: "50px", height: "50px", borderRadius: "50%", border: "1px solid black" }}
                                    />
                                </div>
                                <div >
                                    <Button variant="contained" style={{ background: "#7110DCCC", marginTop: "5%" }}  >
                                        <label htmlFor="file" style={{ display: "flex", flexDirection: "row", }}>
                                            <AddPhotoAlternateIcon style={{ marginLeft: "10%", }} />
                                            <Typography style={{ fontSize: "13px", width: "100px", color: "white" }}>
                                                Edit Image
                                            </Typography>
                                        </label>
                                        <input style={{ display: "none" }} type="file" id="file"
                                            onChange={(e) => { this.setState({ org_logo: e.target.files[0] }) }}
                                        />
                                    </Button>
                                </div>
                            </div>


                            <Grid style={{ marginTop: "5%", marginLeft: "12%", marginRight: "12%" }}>

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "7%" }}>
                                    <Typography style={{}}>Name</Typography>
                                    <TextField style={{ marginLeft: "2%", marginTop: "-7px" }} id="standard-basic" variant="standard"
                                        value={this.state.org_name}
                                        onChange={(e) => {
                                            this.setState({ org_name: e.target.value })
                                        }}
                                    />
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "7%" }}>
                                    <Typography>Email</Typography>
                                    <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                        value={this.state.org_email}
                                        onChange={(e) => {
                                            this.setState({ org_email: e.target.value })
                                        }}
                                    />
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "7%" }}>
                                    <Typography>Phone</Typography>
                                    <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                        value={this.state.org_num}
                                        onChange={(e) => {
                                            this.setState({ org_num: e.target.value })
                                        }}
                                    />
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "7%" }}>
                                    <Typography>GSTIN</Typography>
                                    <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                        value={this.state.org_gstno}
                                        onChange={(e) => {
                                            this.setState({ org_gstno: e.target.value })
                                        }}
                                    />
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "7%" }}>
                                    <Typography>Address</Typography>
                                    <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                        value={this.state.org_address}
                                        onChange={(e) => {
                                            this.setState({ org_address: e.target.value })
                                        }}
                                    />
                                </Grid>



                                <Grid style={{ marginTop: "8%", display: "flex", justifyContent: "center" }}>
                                    <Button
                                        onClick={() => { this.setState({ editorginfo: false }); }}
                                        variant='outlined' style={{ color: "black" }}>cancel</Button>
                                    <Button
                                        onClick={() => {
                                            this.props.updateimageOrganization(this.state.org_name, this.state.org_email, this.state.org_num, this.state.org_logo, this.state.org_logo_old, this.state.org_gstno, this.props.login.org_id, this.state.org_address, this.props.login.token);
                                            this.setState({ editorginfo: false });
                                        }}
                                        variant='contained' style={{ marginLeft: "3%" }}>save</Button>
                                </Grid>
                            </Grid>




                        </Box>

                    </Grid>
                </Dialog>

                {/* edit & add personal */}
                <Dialog
                    onClose={() => this.setState({ editperinfo: false })}
                    open={this.state.editperinfo} style={{}}>
                    <Grid style={{ width: "520px", marginBottom: "2%" }}>

                        <Grid style={{ marginTop: "3%", marginLeft: "3%", marginRight: "3%", marginBottom: "3%" }}>
                            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography style={{ fontSize: "17px", fontWeight: "600" }}>Name</Typography>
                                <Typography style={{ cursor: 'pointer', color: 'red', fontSize: "17px", fontWeight: "600" }}
                                    onClick={() => {
                                        this.setState({
                                            editperinfo: false,
                                        });
                                        this.props.updatePersonalDetails(this.props.login.user_id, this.state.name, this.state.email, this.state.mobile);
                                    }}
                                >Save</Typography>
                            </Grid>
                            <TextField style={{ fontSize: "17px", fontWeight: "600" }} id="standard-basic" variant="standard"
                                value={this.state.name}
                                onChange={(e) => {
                                    this.setState({ name: e.target.value })
                                }}
                            />

                            <Grid style={{ marginTop: "2%" }}>

                                <Typography style={{ fontSize: "17px", fontWeight: "600" }}>Email</Typography>
                                <TextField style={{ fontSize: "17px", fontWeight: "600", width: 'auto' }} id="standard-basic" variant="standard"
                                    value={this.state.email}

                                    onChange={(e) => {
                                        this.setState({ email: e.target.value })
                                    }}
                                />

                            </Grid>

                            <Grid style={{ marginTop: "2%" }}>


                                <Typography style={{ fontSize: "17px", fontWeight: "600" }}>Phone No</Typography>
                                <TextField style={{ marginLeft: "2%", }} id="standard-basic" variant="standard"
                                    value={this.state.mobile}
                                    onChange={(e) => {
                                        this.setState({ mobile: e.target.value })
                                    }}
                                />



                            </Grid>


                        </Grid>
                    </Grid>
                </Dialog>


                <Dialog


                    open={this.state.addpermodal} style={{}}>
                    <Grid style={{ height: "250px", width: "360px" }}>

                        <Box style={{ textAlign: "center" }}>

                            <Typography style={{ fontSize: "18px", fontWeight: "500", marginTop: "3%" }}>Add Personal Details</Typography>


                            <Grid style={{ marginTop: "5%", marginLeft: "12%", marginRight: "12%" }}>

                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "4%" }}>
                                    <Typography style={{}}>Name</Typography>
                                    <TextField style={{ marginLeft: "2%", marginTop: "-7px" }} id="standard-basic" variant="standard"
                                        onChange={(text) => this.setState({ name: text.target.value })}
                                    />
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "4%" }}>
                                    <Typography>Email</Typography>
                                    <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                        onChange={(text) => this.setState({ email: text.target.value })}
                                    />
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "4%" }}>
                                    <Typography>Phone</Typography>
                                    <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                        onChange={(text) => this.setState({ phone: text.target.value })}
                                    />
                                </Grid>



                                <Grid style={{ marginTop: "8%", display: "flex", justifyContent: "center" }}>
                                    <Button
                                        onClick={() => { this.setState({ addpermodal: false }); }}
                                        variant='outlined' style={{ color: "black" }}>cancel</Button>
                                    <Button

                                        variant='contained' style={{ marginLeft: "3%" }}
                                        onClick={() => {
                                            this.setState({ addpermodal: false })
                                            this.props.addPersonalDetails(this.props.login.org_id, this.state.name, this.state.email, this.state.phone)
                                        }}
                                    >save</Button>
                                </Grid>
                            </Grid>




                        </Box>

                    </Grid>
                </Dialog>

                {/* add, edit, delete category */}
                <Dialog
                    // onClose={()=>this.setState({ editcat: false })}
                    open={this.state.addcat} style={{}}>
                    <Grid style={{ width: "450px", marginBottom: "2%", marginTop: "3%" }}>


                        <Grid style={{ marginTop: "3%", marginLeft: "3%", marginRight: "3%", marginBottom: "3%" }}>

                            <Grid style={{ fontSize: "20px", fontWeight: "500", marginTop: "3%", display: "flex", justifyContent: "center" }}>Add Category</Grid>
                            <Grid style={{ display: "flex", flexDirection: "row", marginTop: "3%", justifyContent: "center" }}>

                                <Typography style={{}}>Category Name</Typography>
                                <TextField id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                    onChange={(text) => this.setState({ cate_name: text.target.value })}
                                />

                            </Grid>

                            <Grid style={{ marginTop: "8%", display: "flex", justifyContent: "center" }}>
                                <Button
                                    onClick={() => { this.setState({ addcat: false }); }}
                                    variant='outlined' style={{ color: "black" }}>cancel</Button>
                                <Button
                                    variant='contained' style={{ marginLeft: "3%" }}
                                    onClick={() => {
                                        this.setState({ addcat: false })
                                        this.props.addCategory(this.props.login.org_id, this.state.cate_name)
                                    }}
                                >
                                    Save
                                </Button>
                            </Grid>


                        </Grid>

                    </Grid>
                </Dialog>


                <Dialog
                    // onClose={()=>this.setState({ editcat: false })}
                    open={this.state.editcat} style={{}}>
                    <Grid style={{ width: "500px", marginBottom: "2%" }}>


                        <Grid style={{ marginTop: "3%", marginLeft: "3%", marginRight: "3%", marginBottom: "3%" }}>

                            <Grid style={{ fontSize: "20px", fontWeight: "500", marginTop: "3%", display: "flex", justifyContent: "center" }}>Edit Category</Grid>
                            <Grid style={{ display: "flex", flexDirection: "row", marginTop: "3%", justifyContent: "center" }}>

                                <Typography style={{}}>Category Name</Typography>
                                <TextField placeholder='Order' id="standard-basic" variant="standard" style={{ marginLeft: "2%", marginTop: "-7px" }}
                                    value={this.state.cate_name}
                                    onChange={(text) => this.setState({ cate_name: text.target.value })}
                                />

                            </Grid>

                            <Grid style={{ marginTop: "8%", display: "flex", justifyContent: "center" }}>
                                <Button
                                    onClick={() => { this.setState({ editcat: false }); }}
                                    variant='outlined' style={{ color: "black" }}>cancel</Button>
                                <Button
                                    variant='contained' style={{ marginLeft: "3%" }}
                                    onClick={() => {
                                        this.setState({ editcat: false })
                                        this.props.updateCategory(this.props.login.org_id, this.state.category_id, this.state.cate_name)
                                    }}
                                >
                                    Save Changes
                                </Button>
                            </Grid>


                        </Grid>

                    </Grid>
                </Dialog>

                <Dialog
                    // onClose={()=>this.setState({ editcat: false })}
                    open={this.state.delcat} style={{}}>
                    <Grid style={{ width: "500px", marginBottom: "2%" }}>


                        <Grid style={{ marginTop: "3%", marginLeft: "3%", marginRight: "3%", marginBottom: "3%" }}>

                            <Grid style={{ fontSize: "20px", fontWeight: "500", marginTop: "3%", display: "flex", justifyContent: "center" }}>Are you sure?</Grid>


                            <Grid style={{ marginTop: "8%", display: "flex", justifyContent: "center" }}>
                                <Button
                                    onClick={() => { this.setState({ delcat: false }); }}
                                    variant='outlined' style={{ color: "black" }}>No, go back</Button>
                                <Button
                                    onClick={() => {
                                        this.setState({ delcat: false })
                                        this.props.deleteCategory(this.props.login.org_id, this.state.category_id)
                                    }}
                                    variant='contained' style={{ marginLeft: "3%" }}>Yes, Delete it</Button>
                            </Grid>


                        </Grid>

                    </Grid>
                </Dialog>
            </Grid>
        )
    }
}

