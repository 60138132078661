import React, { Component } from 'react';
import { TextField, Dialog, Box, Grid, Button, Divider, Typography, Card, DialogTitle, DialogContent, DialogActions, TableContainer } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Message from "../../../pages/image/message.png";
import Whatsapp from "../../../pages/image/whatsapp.png";
import Callpic from "../../../pages/image/call.png";
import Mail from "../../../pages/image/email.png";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "../../sales/Components/sales.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextArea from 'antd/es/input/TextArea';
import Profimgm from "../../../pages/image/profimgm.png";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ImageIcon from '@mui/icons-material/Image';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { MenuItem, Select } from '@mui/material';
import { Calendar } from 'react-calendar';
import Textarea from '@mui/joy/Textarea';
import FormControl from '@mui/material/FormControl';
import Pagination from "@mui/material/Pagination";
import { CloseCircle, Edit, Edit2, DocumentDownload, Call, Calendar2, Stickynote } from 'iconsax-react';
import Search from '../../image/search-normal.png'
import "react-country-state-city/dist/react-country-state-city.css";
import { useParams } from "react-router-dom";
import './Adminleaddetails.css';
import { debounce } from 'lodash';
import LoaderCon from "../../../common/loader/containers/loader_cont";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class AdminleadDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statuschange: '',
            viewData: false,
            createdeal: false,
            emailmodal: false,
            paymentmodal: false,
            download: false,
            downloadDone: false,
            eml: '',
            adminaddnote: false,
            editstatus: false,
            adminfolloup: false,
            calandericonad: false,
            edittype: false,
            product_name: "N",
            product_price: "",
            deal_status: "New",
            titel: "",
            date: "",
            time: "",
            note_description: "",
            description: "",
            reminder: "",
            call_purpose: "",
            next_followup_date: "",
            priority: "Low",
            paid_by: "N",
            amount: "",
            dealstatus: '',
            productprice: '',
            productname: '',
            cate: '',
            editDeal: false,
            quotemodal: false,
            editLeads: false,
            countryid: 0,
            stateid: 0,
            cityid: 0,
            lcountry: '',
            lstate: '',
            lcity: '',
            quotefile: '',
            notedata: [
                {
                    notehead1: "Note by ",
                    profilename: "Floyd Miles",
                    noteproimg: Profimgm,
                    notedate: "21-06-23, 10 AM",
                    notedescr: "Lorem ipsum dolor sit amet. Cum perferendis voluptas ex minima architecto qui maiores excepturi aut iure commodi. Nam reprehenderit veritatis nam laborum perferendis rem commodi culpa.",
                },
                {
                    notehead1: "Note by ",
                    profilename: "Floyd Miles",
                    noteproimg: Profimgm,
                    notedate: "21-06-23, 10 AM",
                    notedescr: "Lorem ipsum dolor sit amet. Cum perferendis voluptas ex minima architecto qui maiores excepturi aut iure commodi. Nam reprehenderit veritatis nam laborum perferendis rem commodi culpa.",
                },
            ],
            Executivedetailsdata: [
                {

                    name: "Subh",
                    source: "meta",
                    lead: "old",
                    project: "CRM",
                    fullname: "Subhasmita Karna",
                    id: "#EMP400620",
                    email: "subh@gmail.com",
                },
                {

                    name: "Subh",
                    source: "meta",
                    lead: "old",
                    project: "CRM",
                    fullname: "Subhasmita Karna",
                    id: "#EMP400620",
                    email: "subh@gmail.com",
                },

            ],
            messages: [
                {
                    date: '21-06-23, 10 AM',
                    text: 'Thank You for Contacting',
                    author: 'Floyd Miles',
                    sender: 'Aman Gupta',
                    message:
                        'Lorem ipsum dolor sit amet. Cum perferendis voluptas ex minima architecto qui Lorem ipsum dolor sit amet. Cum perferendis voluptas ex minima architecto quiqui Lorem ipsum dolor sit amet. Cum perferendis voluptas ex minima architecto qui Lorem ipsum dolor sit amet. Cum perferendis voluptas ex minima architecto qui ',
                },
            ],
            page: 0,
            setPage: 0,
            rowsPerPage: 10,
            clickedIndex: 0,
            deal_id: Array.isArray(this.props.lead?.all_deal_by_lead_id) && this.props.lead?.all_deal_by_lead_id[0]?._id,


        };
        this.handleSendMessage = this.handleSendMessage.bind(this)
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleCallButtonClick = this.handleCallButtonClick.bind(this)

        this.debouncedviewAllPaymentByID = debounce(this.props.viewAllPaymentByDealId, 500);
        this.debouncedviewAllNotesByDealId = debounce(this.props.viewAllNotesByDealId, 500);
        this.debouncedviewAllQuoteByLeadId = debounce(this.props.viewAllQuoteByLeadId, 500);
    }
    handleChangeCalad = (calvaluead) => {

        this.setState({ calandericonad: false });
        const formattedDate = calvaluead.toISOString().split('T')[0];
        this.setState({ calvaluead: formattedDate });
    };
    handleChangeage = (event) => {
        this.setState({
            eml: event.target.value,
        });
    };

    handleClick = (event) => {
        this.setState((prevState) => ({
            anchor: prevState.anchor ? null : event.currentTarget,
        }));
    };

    // whats app
    handleButtonClick() {
        const phoneNumber = this.props.lead?.set_lead_details.phone;
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    }

    //   email
    handleEmailClick = () => {
        const emailAddress = this.props.lead?.set_lead_details.email;
        const mailtoLink = `mailto:${emailAddress}`;
        window.location.href = mailtoLink;
    };

    //   message
    handleSendMessage() {
        const messageURI = `sms:${this.props.lead?.set_lead_details.phone}`;
        window.location.href = messageURI;
    }

    //   call
    handleCallButtonClick() {
        const telUrl = `tel:${this.props.lead?.set_lead_details.phone}`;
        window.location.href = telUrl;
    }

    handleCountryChange = (e) => {
        this.setState({
            countryid: e.id,
            countryname: e.name,
            stateid: 0,
            cityid: 0,
            statename: '',
            cityname: '',
        });
    };

    handleStateChange = (e) => {
        this.setState({
            stateid: e.id,
            statename: e.name,
            cityid: 0,
            cityname: '',
        });
    };

    handleCityChange = (e) => {
        this.setState({ cityid: e.id, cityname: e.name });
    };

    componentDidMount() {

        this.props.viewAllCategory(this.props.login.org_id);
        this.props.viewAllProductWithPgn(this.props.login.org_id, this.state.page_number, this.state.rowsPerPage);
        this.props.view_lead_details(this.props.params.id)
        this.props.viewAllQuoteByLeadId(this.state.deal_id, '')
        this.props.viewAllFollowupDeal(this.state.deal_id);
        this.props.viewAllNotesByDealId(this.state.deal_id, this.state.page, this.state.rowsPerPage, '');
        this.props.viewAllPaymentByDealId(this.state.deal_id, this.state.page, this.state.rowsPerPage, '');
        this.setState({
            deal_id: this.props.lead?.all_deal_by_lead_id[0]?._id,
            product_name: this.props.lead?.all_deal_by_lead_id[0]?.product_name,
            product_price: this.props.lead?.all_deal_by_lead_id[0]?.product_price,
        })
    }
    render() {
        const { eml } = this.state;
        const handleChangePage = (event, newPage) => {
            this.props.viewAllNotesByLeadId(
                this.props.lead?.set_lead_details._id,
                newPage - 1,
                this.state.rowsPerPage
            );
            this.setState({
                page: newPage,
            });
        };
        return (
            <Grid>
                <Grid container spacing={2}>
                    {/*--------------------------------------------------------------*/}
                    <Grid item xs={12} sm={12} md={3.4}>
                        <Card style={{ boxShadow: "2px 2px 8px 0px #66666633" }}>
                            <Grid display={'flex'} justifyContent={'space-between'} m={1} mb={2}>
                                <a href='/AdminNewLead' style={{ textDecoration: 'none', color: 'black' }}>
                                    <Grid style={{ display: "flex", flexDirection: "row", marginTop: "2%", marginLeft: "1%", }}>
                                        <KeyboardArrowLeftIcon />
                                        Back to Leads
                                    </Grid>
                                </a>
                                <Edit
                                    size="24"
                                    color="black"
                                    variant="Outline"
                                    cursor={'pointer'}
                                    onClick={() => {
                                        this.setState({
                                            editLeads: true,
                                            lname: this.props.lead?.set_lead_details.name,
                                            lemail: this.props.lead?.set_lead_details.email,
                                            lphone: this.props.lead?.set_lead_details.phone,
                                            lpin: this.props.lead?.set_lead_details.pin,
                                            laddress: this.props.lead?.set_lead_details.Address,
                                            lcountry: this.props.lead?.set_lead_details.country,
                                            lstate: this.props.lead?.set_lead_details.state,
                                            lcity: this.props.lead?.set_lead_details.city,
                                        });
                                    }} />
                            </Grid>
                            <center>
                                <Typography className='subh'>{this.props.lead?.set_lead_details.name}</Typography>
                                <Typography className='Time'>{this.props.lead?.set_lead_details.email}</Typography>
                                <Typography className='min'>{this.props.lead?.set_lead_details.phone}</Typography>
                            </center>
                            <Grid container style={{ justifyContent: 'center' }} mt={2}>
                                {/* <Grid item xs={1.5} sm={2} /> */}
                                <Grid item xs={3} sm={2}>
                                    <button onClick={() => this.handleButtonClick()} style={{ borderRadius: "5px" }}> <img src={Whatsapp} alt='' style={{}} /></button>
                                </Grid>
                                <Grid item xs={0.5} />
                                <Grid item xs={3} sm={2}>
                                    <button onClick={this.handleEmailClick} style={{ borderRadius: "5px" }}><img src={Mail} alt='' /></button>
                                </Grid>
                                <Grid item xs={0.5} />
                                <Grid item xs={3} sm={2}>
                                    <button onClick={() => this.handleCallButtonClick()} style={{ borderRadius: "5px" }}><img src={Callpic} alt='' /></button>
                                </Grid>
                                <Grid item xs={0.5} />
                                <Grid item xs={3} sm={2}>
                                    <button onClick={() => this.handleSendMessage()} style={{ borderRadius: "5px" }}><img src={Message} alt='' style={{ width: "28px", height: "27px", marginTop: "5px", marginLeft: "4%" }} /></button>
                                </Grid>
                                {/* <Grid item xs={1.5} sm={2} /> */}

                            </Grid>
                            <Grid container mt={2}>
                                <Grid item xs={.5} />
                                <Grid item xs={11} p={1} style={{ border: "0.5px solid var(--primary-colour-g-9, #E0E0E0)", borderRadius: 5, }}>
                                    <Grid container>
                                        <Grid item xs={12} sm={5}>
                                            <Typography style={{ fontSize: 12 }}>Status</Typography>
                                            <Grid mt={0.5} style={{ display: "flex", justifyContent: 'space-between' }}>
                                                <Grid container alignItems="center" gap={.5} style={{ display: 'flex' }}>
                                                    <FiberManualRecordIcon style={{
                                                        fontSize: 10,
                                                        color:
                                                            this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]?.deal_status === "New" ? "#006400" :
                                                                this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]?.deal_status === "In Follow Up" ? "#0A376B" :
                                                                    this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]?.deal_status === "Converted" ? "#FFA500" : "#CC542E",
                                                    }} />
                                                    <Typography fontSize={12} style={{
                                                        color:
                                                            this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]?.deal_status === "New" ? "#006400" :
                                                                this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]?.deal_status === "In Follow Up" ? "#0A376B" :
                                                                    this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]?.deal_status === "Converted" ? "#FFA500" : "#CC542E",
                                                    }}>
                                                        {Array.isArray(this.props.lead?.all_deal_by_lead_id) && this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]?.deal_status}
                                                    </Typography>
                                                </Grid>
                                                <Edit2
                                                    size="20"
                                                    color="blue"
                                                    variant="Outline"
                                                    cursor={'pointer'}
                                                    onClick={() => {
                                                        this.setState({
                                                            editstatus: true,
                                                            deal_id: Array.isArray(this.props.lead?.all_deal_by_lead_id) && this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]._id,
                                                            status: Array.isArray(this.props.lead?.all_deal_by_lead_id) && this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex].deal_status,
                                                            priority: Array.isArray(this.props.lead?.all_deal_by_lead_id) && this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex].priority,
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={0} sm={1}>
                                            <hr style={{ backgroundColor: "var(--primary-colour-g-9, #E0E0E0)", height: 30, width: 1 }} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography style={{ fontSize: 12 }}>Potential Type</Typography>
                                            <Grid mt={0.5} style={{ display: "flex", justifyContent: 'space-between' }}>
                                                <Grid style={{ display: 'flex' }}>
                                                    <StopRoundedIcon style={{
                                                        color: this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]?.priority === "High" ? 'green' : this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]?.priority === "Low" ? 'red' : 'yellow',
                                                    }} />
                                                    <Typography fontSize={12} marginTop={.5}>
                                                        {Array.isArray(this.props.lead?.all_deal_by_lead_id) && this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]?.priority}</Typography>
                                                </Grid>
                                                <Edit2
                                                    size="20"
                                                    color="blue"
                                                    variant="Outline"
                                                    cursor={'pointer'}
                                                    onClick={() => {
                                                        this.setState({
                                                            edittype: true,
                                                            deal_id: Array.isArray(this.props.lead?.all_deal_by_lead_id) && this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]?._id,
                                                            status: Array.isArray(this.props.lead?.all_deal_by_lead_id) && this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]?.deal_status,
                                                            priority: Array.isArray(this.props.lead?.all_deal_by_lead_id) && this.props.lead?.all_deal_by_lead_id[this.state.clickedIndex]?.priority,
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={.5} />
                            </Grid>
                            <Grid container mt={2} mb={2}>
                                <Grid item xs={.5} />
                                <Grid item xs={11} >
                                    <Typography fontWeight={'bold'}>Lead Information</Typography>
                                    <Typography className='Emaillead'>Country:</Typography>
                                    <Typography className='Emailid'>{this.props.lead?.set_lead_details.country !== (undefined || null) ? this.props.lead?.set_lead_details.country : "---"}</Typography>
                                    <Typography className='Emaillead'>State:</Typography>
                                    <Typography className='Emailid'>{this.props.lead?.set_lead_details.state !== (undefined || null) ? this.props.lead?.set_lead_details.state : "---"}</Typography>
                                    <Typography className='Emaillead'>City:</Typography>
                                    <Typography className='Emailid'>{this.props.lead?.set_lead_details.city !== (undefined || null) ? this.props.lead?.set_lead_details.city : "---"}</Typography>
                                    <Typography className='Emaillead'>Address:</Typography>
                                    <Typography className='Emailid'>{this.props.lead?.set_lead_details.Address !== (undefined || null) ? this.props.lead?.set_lead_details.Address : "---"}</Typography>
                                    <Typography className='Emaillead'>Pin:</Typography>
                                    <Typography className='Emailid'>{this.props.lead?.set_lead_details.pin !== (undefined || null) ? this.props.lead?.set_lead_details.pin : "---"}</Typography>
                                    <Typography className='Emaillead'>Lead Source:</Typography>
                                    <Typography className='Emailid'>{this.props.lead?.set_lead_details.source !== (undefined || null) ? this.props.lead?.set_lead_details.source : "---"}</Typography>
                                </Grid>
                                <Grid item xs={.5} />
                            </Grid>
                        </Card>
                    </Grid>
                    {/*--------------------------------------------------------------*/}
                    <Grid item xs={12} sm={12} md={5.6} mt={-2}>
                        <Tabs>
                            <Grid>
                                <TabList className="sales" style={{
                                    border: "none",
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: 'space-evenly',
                                    backgroundColor: "rgba(242, 242, 242, 1)",
                                    borderRadius: '5px',
                                }}>
                                    <Tab>Notes</Tab>
                                    <Tab>Call</Tab>
                                    <Tab>Quotes</Tab>
                                    <Tab>Payment</Tab>
                                </TabList>
                            </Grid>
                            {/* -----------Notes--------------- */}
                            <TabPanel>
                                <Paper
                                    component="form"
                                    style={{
                                        width: '92%',
                                        height: '2.5rem',
                                        gap: '0.625rem',
                                        borderRadius: '1.25rem',
                                        border: '1px solid var(--PrimaryColour-G7, #B8B8B8)',
                                        margin: '3%'
                                    }}
                                >
                                    <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                                        <img alt='' src={Search} style={{ fontSize: "25px" }} />
                                    </IconButton>
                                    <InputBase
                                        className='search-text'
                                        placeholder="Search "
                                        inputProps={{ "aria-label": "Search " }}
                                        onChange={(e) => {
                                            this.debouncedviewAllNotesByDealId(this.state.deal_id, e.target.value)
                                        }}
                                    />
                                </Paper>
                                <Grid p={2}>
                                    <Typography style={{ fontWeight: "bold" }}>Add New Note</Typography>
                                    <Card>
                                        <Grid container mt={1}>
                                            <Grid item xs={.5} />
                                            <Grid item xs={11}>
                                                <Typography style={{ color: "#A3A3A3", fontSize: 12 }}>Note Title <span style={{ color: 'red' }}>*</span></Typography>
                                                <TextField fullWidth style={{ borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"
                                                    onChange={(text) => this.setState({ titel: text.target.value })}
                                                    value={this.state.titel}
                                                />
                                                <Typography mt={2} style={{ color: "#A3A3A3", fontSize: 12 }}>Note Description</Typography>
                                                <TextArea fullWidth
                                                    onChange={(text) => this.setState({ note_description: text.target.value })}
                                                    value={this.state.note_description}
                                                />
                                                <DialogActions>
                                                    <Button
                                                        disabled={this.state.titel === ''}
                                                        onClick={() => {
                                                            this.setState({ adminaddnote: false, _id: "", user_id: "", titel: "", date: "", time: "", note_description: "" })
                                                            this.props.createLeadNotes(this.props.lead?.set_lead_details._id, this.props.login.user_id, this.state.titel, this.state.note_description, this.state.page, this.state.rowsPerPage, this.state.deal_id)
                                                        }}
                                                        style={{ backgroundColor: "#3D3B3B", color: "#fff" }}>Add Notes</Button>
                                                </DialogActions>
                                            </Grid>
                                            <Grid item xs={.5} />
                                        </Grid>



                                    </Card>
                                    {Array.isArray(this.props.lead?.all_notes_by_lead) && this.props.lead?.all_notes_by_lead?.map((item) => {
                                        return (
                                            <Card style={{ padding: 10, marginTop: "2%" }}>
                                                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Grid style={{ display: "flex" }}>
                                                        <Grid gap={1} style={{ display: "flex" }}>
                                                            <Stickynote
                                                                size="15"
                                                                color="black"
                                                            />
                                                            <Typography style={{ fontSize: 12 }}>Note</Typography>
                                                        </Grid>
                                                        <Grid style={{ display: "flex" }}>
                                                            {/* <img style={{ height: 20, width: 20, marginLeft: "10px" }} src={item.noteproimg} /> */}
                                                            {/* <Typography style={{ fontSize: 12, whiteSpace: "nowrap", marginLeft: "10px" }}>{this.props.login.name}</Typography> */}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid gap={1} style={{ display: "flex" }}>
                                                        <Calendar2
                                                            size="15"
                                                            color="black"
                                                        />
                                                        <Typography style={{ fontSize: 12 }}>{item.create_date} , {item.create_time}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Typography style={{ fontSize: 13, marginTop: "2%", marginBottom: "2%", fontWeight: 700 }}>{item.titel}</Typography>

                                                <Typography style={{ fontSize: 13, marginTop: "2%", marginBottom: "2%" }}>{item.note_description}</Typography>
                                            </Card>
                                        );
                                    })}
                                </Grid>
                                <Grid style={{ display: "flex", justifyContent: "flex-end", margin: "2% 2% 2% 0%" }}>
                                    <Pagination
                                        count={parseInt(this.props.lead?.notes_length / this.state.rowsPerPage) + 1}
                                        size="small"
                                        className='pagination'
                                        onChange={handleChangePage}
                                    />
                                </Grid>
                            </TabPanel>

                            {/* -----------Call--------------- */}
                            <TabPanel>
                                <Grid p={2}>
                                    <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography style={{ fontWeight: "bold" }}>Call Schedule</Typography>
                                        <Button
                                            onClick={() => { this.setState({ adminfolloup: true }); }}
                                            style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', padding: '0.33675rem 0.6735rem', color: 'white' }}>Next  Followup</Button>
                                    </Grid>
                                    <Card style={{ padding: '5px', marginTop: '10px' }}>
                                        <Grid container>
                                            <Grid item xs={.2} />
                                            <Grid item xs={11.6} >
                                                <Typography style={{ fontSize: 14, fontWeight: "500" }}>Next Follow up</Typography>
                                                <Typography style={{ fontSize: 13, }}>{this.props.lead?.all_followups_lead[0]?.description} </Typography>
                                                <Grid container mt={2} mb={1}>
                                                    <Grid item xs={12} p={1} style={{ border: "0.5px solid var(--primary-colour-g-9, #E0E0E0)", borderRadius: 5, }}>
                                                        <Grid container>
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography style={{ fontSize: 12 }}>Reminder</Typography>
                                                                <Typography style={{ fontSize: 12, marginTop: "1%" }}>{this.props.lead?.all_followups_lead[0]?.reminder ? this.props.lead?.all_followups_lead[0]?.reminder : "---"}</Typography>
                                                            </Grid>
                                                            <Grid item xs={0} sm={1.5}>
                                                                <hr style={{ backgroundColor: "var(--primary-colour-g-9, #E0E0E0)", height: 30, width: 1 }} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography style={{ fontSize: 12 }}>Call Purpose</Typography>
                                                                <Typography style={{ fontSize: 12, marginTop: "1%" }}>{this.props.lead?.all_followups_lead[0]?.call_purpose ? this.props.lead?.all_followups_lead[0]?.call_purpose : "---"}</Typography>
                                                            </Grid>
                                                            <Grid item xs={0} sm={1.5}>
                                                                <hr style={{ backgroundColor: "var(--primary-colour-g-9, #E0E0E0)", height: 30, width: 1 }} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography style={{ fontSize: 12 }}>Follow Up</Typography>
                                                                <Typography style={{ fontSize: 12, marginTop: "1%" }}>{this.props.lead?.all_followups_lead[0]?.next_followup_date !== "Invalid date" ? this.props.lead?.all_followups_lead[0]?.next_followup_date : "---"}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={.2} />
                                        </Grid>
                                    </Card>
                                    <Grid mt={1}>
                                        <Typography style={{ fontSize: "18", fontWeight: "bold" }}>Call History</Typography>
                                        {Array.isArray(this.props.lead?.all_followups_lead) && this.props.lead?.all_followups_lead.map((item, index) => {
                                            return (
                                                <Grid>
                                                    <Typography style={{ fontSize: 13, lineHeight: "35px" }}>{item.date}</Typography>
                                                    <Card style={{ padding: 15 }}>
                                                        <Grid gap={1} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                            <Grid gap={1} style={{ display: "flex" }}>
                                                                <Call
                                                                    size="20"
                                                                    color="#666666"
                                                                    variant="Bold"
                                                                />
                                                                <Typography style={{ fontSize: 12, fontWeight: "500" }}>Description : {item.description} </Typography>
                                                            </Grid>
                                                            <Typography style={{ fontSize: 12 }}>{item.call_purpose}</Typography>
                                                        </Grid>
                                                        <Divider style={{ margin: "2% 0% 2% 0%" }} />
                                                        <Typography style={{ fontSize: 12, whiteSpace: "nowrap", marginLeft: "3%", fontWeight: "500" }}>Next Followup Date : {item.next_followup_date !== 'Invalid date' ? item.next_followup_date : ''} </Typography>
                                                    </Card>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            {/* ------------Quotes--------- */}
                            <TabPanel>
                                <Paper
                                    component="form"
                                    style={{
                                        width: '92%',
                                        height: '2.5rem',
                                        gap: '0.625rem',
                                        borderRadius: '1.25rem',
                                        border: '1px solid var(--PrimaryColour-G7, #B8B8B8)',
                                        margin: '3%'
                                    }}
                                >
                                    <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                                        <img alt='' src={Search} style={{ fontSize: "25px" }} />
                                    </IconButton>
                                    <InputBase
                                        className='search-text'
                                        placeholder="Search "
                                        inputProps={{ "aria-label": "Search " }}
                                        onChange={e => {
                                            this.debouncedviewAllQuoteByLeadId(this.state.deal_id, e.target.value)
                                        }}
                                    />
                                </Paper>
                                <Grid p={2}>
                                    <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography style={{ fontWeight: "bold" }}>All Quotes</Typography>
                                        <Button
                                            onClick={() => { this.setState({ quotemodal: true }); }}
                                            style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', padding: '0.33675rem 0.6735rem', color: 'white' }}>Create Quotes</Button>
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <Box>
                                        {Array.isArray(this.props.lead?.all_quotes) && this.props.lead?.all_quotes.map((item, index) => (
                                            <Card style={{ padding: '10px', margin: '8px', justifyContent: 'space-between', display: 'flex' }}>
                                                <Typography variant='h7'>{item.amount}</Typography>
                                                <Grid display={'flex'} gap={2}>
                                                    {item.pdf &&
                                                        <a href={item.pdf} rel="noreferrer" target='_blank' style={{ textDecoration: 'none' }}>
                                                            <center style={{ flexDirection: 'column' }}>
                                                                <DocumentDownload size='20' color='blue' style={{ marginLeft: '5px' }} />
                                                                <Typography style={{ color: 'blue', fontSize: '10px' }}> Download   </Typography>
                                                            </center>
                                                        </a>}
                                                    <Button
                                                        onClick={() => {
                                                            if (item.status === 'Pending') {
                                                                this.setState({
                                                                    statuschange: true,
                                                                    quote_id: item._id,
                                                                    amount: item.amount,
                                                                });
                                                            }
                                                        }}
                                                        disabled={item.status !== "Pending"}
                                                        style={{ border: item.status === 'Approved' ? '1px solid green' : item.status === 'Pending' ? '1px solid orange' : '1px solid red', color: item.status === 'Approved' ? 'green' : item.status === 'Pending' ? 'orange' : 'red' }}>
                                                        {item.status}
                                                    </Button>
                                                </Grid>
                                            </Card>
                                        ))}
                                    </Box>
                                </Grid>
                            </TabPanel>

                            {/* ------------Payment--------- */}
                            <TabPanel>
                                <Paper
                                    component="form"
                                    style={{
                                        width: '92%',
                                        height: '2.5rem',
                                        gap: '0.625rem',
                                        borderRadius: '1.25rem',
                                        border: '1px solid var(--PrimaryColour-G7, #B8B8B8)',
                                        margin: '3%'
                                    }}
                                >
                                    <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                                        <img alt='' src={Search} style={{ fontSize: "25px" }} />
                                    </IconButton>
                                    <InputBase
                                        className='search-text'
                                        placeholder="Search "
                                        inputProps={{ "aria-label": "Search " }}
                                        onChange={(e) => {
                                            this.debouncedviewAllPaymentByID(this.state.deal_id, this.state.page, this.state.rowsPerPage, e.target.value)
                                        }}
                                    />
                                </Paper>
                                <Grid p={2}>
                                    <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography style={{ fontWeight: "bold" }}>Payment</Typography>
                                        <Button
                                            onClick={() => { this.setState({ paymentmodal: true }); }}
                                            style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', padding: '0.33675rem 0.6735rem', color: 'white' }}>Create Payment</Button>
                                    </Grid>
                                </Grid>

                                <Grid p={1}>
                                    <TableContainer component={Paper} style={{ borderRadius: '0px' }}>
                                        <Table size='medium'>
                                            <TableHead style={{ background: "#F2F2F2" }}>
                                                <TableRow >
                                                    <TableCell><Typography style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>Payment Mode</Typography></TableCell>
                                                    <TableCell><Typography style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>Amount</Typography></TableCell>
                                                    <TableCell><Typography style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>Date</Typography></TableCell>
                                                    {/* <TableCell><Typography style={{ fontSize: 12, whiteSpace: "nowrap" }}>Payment Mode</Typography></TableCell> */}
                                                    {/* <TableCell><Typography></Typography></TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Array.isArray(this.props.lead?.all_payments) && this.props.lead?.all_payments.map((item, index) => {
                                                    // 
                                                    return (
                                                        <TableRow>
                                                            <TableCell><Typography style={{ fontSize: 12, whiteSpace: "nowrap" }}>{item.paid_by}</Typography></TableCell>
                                                            <TableCell><Typography style={{ fontSize: 12, whiteSpace: "nowrap" }}>{item.amount}</Typography></TableCell>
                                                            <TableCell><Typography style={{ fontSize: 12, whiteSpace: "nowrap" }}>{item.create_date}</Typography></TableCell>
                                                            {/* <TableCell><Typography style={{ fontSize: 12, whiteSpace: "nowrap" }}>Cash</Typography></TableCell> */}
                                                            {/* <TableCell>
                                                            <Grid style={{ display: "flex", flexDirection: "row" }}>
                                                                <FileDownloadOutlinedIcon style={{ color: "blue" }} />
                                                                <Typography onClick={() => this.setState({ download: true })} style={{ color: "blue", fontSize: 12, whiteSpace: "nowrap" }} >Download</Typography>
                                                            </Grid>
                                                        </TableCell> */}

                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                        <Pagination style={{ display: "flex", justifyContent: "flex-end", padding: '10px' }}
                                            count={parseInt(this.props.lead?.payment_length / this.state.rowsPerPage) + 1}
                                            size="small"
                                            className='pagination'
                                            onChange={handleChangePage}
                                        />
                                    </TableContainer>
                                </Grid>
                            </TabPanel>

                            {/* ------------Email--------- */}
                            <TabPanel>
                                <Paper
                                    component="form"
                                    style={{
                                        display: "flex",
                                        width: "90%",
                                        height: "42px",
                                        boxShadow: "none",
                                        backgroundColor: "rgba(242, 242, 242, 0.30)",
                                        border: "1px solid var(--grey-colour-g-6, #B4B0B4)",
                                        marginLeft: "5%",
                                        marginTop: "3%"
                                    }}
                                >
                                    <IconButton type="button" style={{ p: "15px" }} aria-label="search">
                                        <SearchIcon style={{ fontSize: "25px" }} />
                                    </IconButton>
                                    <InputBase
                                        className='serach-text'
                                        placeholder="Search "
                                        inputProps={{ "aria-label": "Search " }}
                                    />
                                </Paper>
                                <Grid style={{}}>
                                    <Grid style={{ padding: 20 }} >
                                        <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography style={{ fontSize: 18, fontWeight: "600" }}>All Emails</Typography>
                                            <Button
                                                onClick={() => { this.setState({ emailmodal: true }); }}
                                                size='small' style={{ backgroundColor: "#3D3B3B", color: "#fff", marginTop: "2%", }}>Add Email</Button>
                                        </Grid>
                                        <Box sx={{ marginTop: "2%" }}>
                                            {this.state.messages.map((message, index) => (
                                                <Accordion>
                                                    <AccordionSummary
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Grid style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", width: "100%" }} >
                                                            <Grid>
                                                                <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                                    <KeyboardArrowDownOutlinedIcon />
                                                                    <Typography style={{ fontWeight: "bold" }}>{message.text}</Typography>
                                                                </Grid>
                                                                <Grid style={{ display: 'flex', flexDirection: "row", gap: 5, marginLeft: "15%" }}> <Typography>by</Typography> <Typography>Floyd Miles</Typography></Grid>
                                                            </Grid>
                                                            <Grid style={{ display: 'flex', flexDirection: "row", gap: 5 }}><CalendarMonthIcon style={{ fontSize: 15 }} /><Typography style={{ fontSize: 12 }}>{message.date}</Typography><MoreHorizIcon /></Grid>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid >
                                                            <Grid>
                                                                <ListItem>
                                                                    <ListItemAvatar>
                                                                        <Avatar>
                                                                            <ImageIcon />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary={message.sender} />
                                                                </ListItem>
                                                            </Grid>
                                                        </Grid>
                                                        <Typography >{message.message}</Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </Tabs>
                    </Grid>
                    {/*--------------------------------------------------------------*/}
                    <Grid item xs={12} sm={12} md={3}>
                        <Card style={{ boxShadow: "2px 2px 8px 0px #66666633", padding: '10px' }}>
                            <Typography style={{ fontSize: 15, fontWeight: "600" }}> Number Of Deals :- {this.props.lead?.all_deal_by_lead_id.length}</Typography>
                            <Grid style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Button
                                    onClick={() => { this.setState({ createdeal: true }); }}
                                    size='small' variant='outlined' style={{ borderColor: "black", color: "black" }}>
                                    <AddOutlinedIcon />Create New Deal</Button>
                                <Edit
                                    size="24"
                                    color="black"
                                    variant="Outline"
                                    cursor={'pointer'}
                                    onClick={() => {
                                        this.setState({
                                            editDeal: true,
                                        });

                                    }} />
                            </Grid>
                            {Array.isArray(this.props.lead?.all_deal_by_lead_id) && this.props.lead?.all_deal_by_lead_id.map((item, index) => {
                                return (
                                    <Card style={{
                                        padding: 12,
                                        marginTop: "5%",
                                        border: `${this.state.clickedIndex === index ? "2px solid #37DA29" : ""}`,
                                        borderRadius: "8px", cursor: 'pointer'
                                    }}
                                        onClick={() => {
                                            this.props.viewAllFollowupDeal(item._id);
                                            this.props.viewAllPaymentByDealId(item._id, this.state.page, this.state.rowsPerPage, '');
                                            this.props.viewAllNotesByDealId(item._id, this.state.page, this.state.rowsPerPage, '');
                                            this.props.viewAllQuoteByLeadId(item._id, "");
                                            this.setState({
                                                clickedIndex: index,
                                                deal_id: item._id,
                                                product_name: item.product_name,
                                                product_price: item.product_price,
                                            })
                                        }}>
                                        <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography style={{ fontSize: 13, fontWeight: "600" }}>{item.product_name}</Typography>
                                            <button style={{
                                                backgroundColor: item.deal_status === "New" ? "#00FF0033" : item.deal_status === "In Follow Up" ? "#0A376B33" : item.deal_status === "Converted" ? "#FFA50033" : "#CC542E33",
                                                color: item.deal_status === "New" ? "#006400" : item.deal_status === "In Follow Up" ? "#0A376B" : item.deal_status === "Converted" ? "#FFA500" : "#CC542E",
                                                border: "none",
                                                borderRadius: 15,
                                                fontSize: 10,
                                                height: "20px"
                                            }}>
                                                <FiberManualRecordIcon style={{
                                                    fontSize: 8,
                                                    color: item.deal_status === "New" ? "#006400" : item.deal_status === "In Follow Up" ? "#0A376B" : item.deal_status === "Converted" ? "#FFA500" : "#CC542E",
                                                }} />
                                                {item.deal_status}
                                            </button>
                                        </Grid>
                                        <Typography style={{ fontSize: 11, marginTop: "2%" }}>Price:{item.product_price}</Typography>
                                    </Card>
                                );
                            })}
                        </Card>
                    </Grid>
                </Grid>

                {/* edit status */}
                <Dialog fullWidth maxWidth='xs'
                    open={this.state.editstatus}>
                    <DialogTitle style={{ display: "flex", justifyContent: "space-between", }}>
                        <Typography variant='h6'>Edit Status</Typography>
                        <CloseCircle
                            size="24"
                            color="black"
                            variant="Outline"
                            cursor={'pointer'}
                            onClick={() => {
                                this.setState({ editstatus: false });
                            }}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            id="outlined-select-currency-native"
                            select
                            value={this.state.status}
                            onChange={(e) => this.setState({ status: e.target.value })}
                        >
                            <MenuItem value={'New'}>
                                New
                            </MenuItem>
                            <MenuItem value={'Converted'}>
                                Converted
                            </MenuItem>
                            <MenuItem value={'In Follow Up'}>
                                In Follow Up
                            </MenuItem>
                            <MenuItem value={'Rejected'}>
                                Rejected
                            </MenuItem>
                        </TextField>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center", marginBottom: '10px' }}>
                        <Button
                            onClick={() => { this.setState({ editstatus: false }); }}
                            variant='outlined' style={{ borderradius: ' 0.33675rem', border: '1px solid var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'var(--PrimaryColour-G1, #3D3B3B)' }}>Cancel</Button>
                        <Button
                            onClick={() => {
                                this.setState({ editstatus: false })
                                this.props.updateDealStatusPriority(this.state.deal_id, this.state.status, this.state.priority, this.props.lead?.set_lead_details._id)
                            }}
                            variant='contained' style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white' }}>
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* edittype */}
                <Dialog fullWidth maxWidth='xs' open={this.state.edittype} >
                    <DialogTitle style={{ display: "flex", justifyContent: "space-between", }}>
                        <Typography variant='h6'>Edit Potential Type</Typography>
                        <CloseCircle
                            size="24"
                            color="black"
                            variant="Outline"
                            cursor={'pointer'}
                            onClick={() => {
                                this.setState({ edittype: false });
                            }}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            id="outlined-select-currency-native"
                            select
                            value={this.state.priority}
                            onChange={(e) => this.setState({ priority: e.target.value })}
                        >
                            <MenuItem value="High">
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                    <FiberManualRecordIcon style={{ color: "green", fontSize: 12, }} />
                                    <Typography style={{ color: "green" }}>High </Typography>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="Moderate">
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                    <FiberManualRecordIcon style={{ color: "yellow", fontSize: 12, }} />
                                    <Typography style={{ color: "yellow" }}>Moderate </Typography>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="Low">
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                    <FiberManualRecordIcon style={{ color: "red", fontSize: 12, }} />
                                    <Typography style={{ color: "red" }}>Low </Typography>
                                </Grid>
                            </MenuItem>
                        </TextField>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
                        <Button
                            onClick={() => { this.setState({ edittype: false }); }}
                            variant='outlined' style={{ borderradius: ' 0.33675rem', border: '1px solid var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'var(--PrimaryColour-G1, #3D3B3B)' }}>Cancel</Button>
                        <Button
                            onClick={() => {
                                this.setState({ edittype: false })
                                this.props.updateDealStatusPriority(this.state.deal_id, this.state.status, this.state.priority, this.props.lead?.set_lead_details._id)
                            }}
                            variant='contained' style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white' }}>
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* ----------add note--------- */}
                <Dialog
                    open={this.state.adminaddnote}
                    onClose={() => this.setState({ adminaddnote: false })}
                >
                    <Grid style={{ height: "30px", width: "200px" }}>
                        <center>
                            <Typography style={{ fontSize: "12px", fontWeight: "400", color: "gray", marginTop: "10%" }}>Notes Added Successfully</Typography>
                        </center>
                    </Grid>
                </Dialog>
                {/* view dialog */}
                <Dialog
                    open={this.state.viewData} style={{}}>
                    <Grid style={{ width: "400px", padding: "20px", justifyContent: "center", alignItems: "center" }}>
                        <Grid style={{ display: "flex", justifyContent: "space-between", }}>
                            <Typography style={{ fontWeight: "600" }}>Add Product</Typography>
                            <CloseOutlinedIcon
                                onClick={() => {
                                    this.setState({ viewData: false });
                                }}
                                className="eventcross-icon"
                            />
                        </Grid>
                        <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <Grid style={{ marginTop: "2%" }}>
                                <Typography>User Name-</Typography>
                                <TextField id="standard-basic" placeholder='Punchul Duos' disabled variant="standard" style={{ width: "250px", marginLeft: "2%", marginTop: "-7px" }} />
                            </Grid>
                            <Grid>
                                <Typography>Email ID-</Typography>
                                <TextField id="standard-basic" placeholder='abc@gmail.com' disabled variant="standard" style={{ width: "250px", marginLeft: "2%", marginTop: "-7px" }} />
                            </Grid>
                            <Grid>
                                <Typography>Phone No-</Typography>
                                <TextField id="standard-basic" placeholder='1234567890' disabled variant="standard" style={{ width: "250px", marginLeft: "2%", marginTop: "-7px" }} />
                            </Grid>
                            <Grid>
                                <Typography>Employee ID-</Typography>
                                <TextField id="standard-basic" placeholder='EMP1234567' disabled variant="standard" style={{ width: "250px", marginLeft: "2%", marginTop: "-7px" }} />
                            </Grid>
                            <button
                                onClick={() => {
                                    this.setState({ viewData: false });
                                }}
                                style={{ border: "none", borderRadius: "2px", outline: "none", color: "white", background: "#441000", padding: "10px", width: "30%", borderRadius: "2px", marginTop: "2%" }}>
                                Okay
                            </button>
                        </Grid>
                    </Grid>
                </Dialog>
                {/* -----------Create Deal------------ */}
                <Dialog maxWidth='sm' fullWidth
                    open={this.state.createdeal}
                >
                    <DialogTitle style={{ display: "flex", justifyContent: "space-between", backgroundColor: "lightgray" }}>
                        <Typography variant='h6'>Create Deal</Typography>
                        <CloseCircle
                            size="24"
                            color="black"
                            variant="Outline"
                            cursor={'pointer'}
                            onClick={() => {
                                this.setState({ createdeal: false });
                            }}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <Grid conatiner mt={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    select
                                    variant='standard'
                                    value={this.state.category}
                                    label='Category *'
                                    onChange={(e) => {
                                        this.setState({ category: e.target.value })
                                    }}
                                >
                                    {Array.isArray(this.props.employee.all_category) && this.props.employee.all_category.map((item) => (
                                        <MenuItem
                                            value={item.cate_name}
                                            onClick={() => {
                                                this.setState({ category_id: item._id })
                                            }}
                                        >{item.cate_name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container mt={2}>
                            <Grid item xs={2.8}>
                                <Typography variant='h7'>Product Name <span style={{ color: 'red' }}>*</span> :</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="outlined-select-currency-native"
                                    select
                                    value={this.state.product_name}
                                    onChange={(e) => {
                                        this.setState({ product_name: e.target.value, });
                                    }}
                                >
                                    <MenuItem value={"N"}>{"All Product"}</MenuItem>
                                    {Array.isArray(this.props.product.view_all_product_with_pagination) && this.props.product.view_all_product_with_pagination.map((item) => (
                                        <MenuItem value={item.product_name}
                                            onClick={() => {
                                                this.setState({ product_price: item.product_price })
                                            }}
                                        >{item.product_name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={.4} />
                            <Grid item xs={2.8}>
                                <Typography variant='h7'>Product Price <span style={{ color: 'red' }}>*</span> :</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="standard-basic" variant="standard"
                                    fullWidth
                                    value={this.state.product_price}
                                    onChange={(e) => {
                                        this.setState({ product_price: e.target.value });
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container mt={2}>
                            <Grid item xs={2.8}>
                                <Typography variant='h7'>Product Status :</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="outlined-select-currency-native"
                                    select
                                    value={this.state.deal_status || 'New'}
                                    onChange={(e) => { this.setState({ deal_status: e.target.value }) }}
                                >
                                    <MenuItem value={'New'}>
                                        Open
                                    </MenuItem>
                                    <MenuItem value={'In follow-up'}>
                                        In follow-up
                                    </MenuItem>
                                    <MenuItem value={'Rejected'}>
                                        Rejected
                                    </MenuItem>
                                    <MenuItem value={'Converted'}>
                                        Converted
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={.4} />
                            <Grid item xs={2.8}>
                                <Typography variant='h7'>Potential Type :</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="outlined-select-currency-native"
                                    select
                                    value={this.state.priority || 'Low'}
                                    onChange={(e) => { this.setState({ priority: e.target.value }) }}
                                >
                                    <MenuItem value="High">
                                        <Grid style={{ display: "flex", alignItems: "center" }}>
                                            <FiberManualRecordIcon style={{ color: "green", fontSize: 12, }} />
                                            <Typography style={{ color: "green" }}>High </Typography>
                                        </Grid>
                                    </MenuItem>
                                    <MenuItem value="Moderate">
                                        <Grid style={{ display: "flex", alignItems: "center" }}>
                                            <FiberManualRecordIcon style={{ color: "yellow", fontSize: 12, }} />
                                            <Typography style={{ color: "yellow" }}>Moderate </Typography>
                                        </Grid>
                                    </MenuItem>
                                    <MenuItem value="Low">
                                        <Grid style={{ display: "flex", alignItems: "center" }}>
                                            <FiberManualRecordIcon style={{ color: "red", fontSize: 12, }} />
                                            <Typography style={{ color: "red" }}>Low </Typography>
                                        </Grid>
                                    </MenuItem>
                                </TextField>
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
                        <Button
                            onClick={() => { this.setState({ createdeal: false }); }}
                            variant='outlined' style={{ borderradius: ' 0.33675rem', border: '1px solid var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'var(--PrimaryColour-G1, #3D3B3B)' }}>Cancel</Button>
                        <Button
                            disabled={this.state.category_id === '' || this.state.product_name === '' || this.state.product_price === ""}
                            size='small'
                            variant='contained' style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white' }}
                            onClick={() => {
                                this.setState({
                                    createdeal: false
                                })
                                this.props.createLeadDeal(this.props.lead?.set_lead_details._id, this.state.product_name, this.props.login.user_id, this.state.product_price, this.state.deal_status, this.state.priority, this.state.category_id)
                            }}
                        >
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* -----------Edit Deal------------ */}
                <Dialog maxWidth='sm' fullWidth
                    open={this.state.editDeal}
                >
                    <DialogTitle style={{ display: "flex", justifyContent: "space-between", backgroundColor: "lightgray" }}>
                        <Typography variant='h6'>Edit Deal</Typography>
                        <CloseCircle
                            size="24"
                            color="black"
                            variant="Outline"
                            cursor={'pointer'}
                            onClick={() => {
                                this.setState({
                                    editDeal: false,
                                    deal_id: '',
                                    category: '',
                                    product_name: '',
                                    product_price: '',
                                    deal_status: '',
                                    priority: ''
                                });
                            }}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container mt={2}>
                            <Grid item xs={2.8}>
                                <Typography variant='h7'>Product Name :</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    id="outlined-select-currency-native"
                                    select
                                    value={this.state.product_name}
                                    onChange={(e) => {
                                        this.setState({ product_name: e.target.value, });
                                    }}
                                >
                                    <MenuItem value={"N"}>{"All Product"}</MenuItem>
                                    {Array.isArray(this.props.product.view_all_product_with_pagination) && this.props.product.view_all_product_with_pagination.map((item) => (
                                        <MenuItem value={item.product_name}
                                            onClick={() => {
                                                this.setState({ product_price: item.product_price })
                                            }}
                                        >{item.product_name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={.4} />
                            <Grid item xs={2.8}>
                                <Typography variant='h7'>Product Price :</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="standard-basic" variant="standard"
                                    fullWidth
                                    value={this.state.product_price}
                                    onChange={(e) => {
                                        this.setState({ product_price: e.target.value });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
                        <Button
                            onClick={() => {
                                this.setState({
                                    editDeal: false,
                                    deal_id: '',
                                    product_name: '',
                                    product_price: '',
                                });
                            }}
                            variant='outlined' style={{ borderradius: ' 0.33675rem', border: '1px solid var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'var(--PrimaryColour-G1, #3D3B3B)' }}>Cancel</Button>
                        <Button
                            size='small'
                            variant='contained' style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white' }}
                            onClick={() => {
                                this.setState({
                                    editDeal: false,
                                    deal_id: '',
                                    product_name: '',
                                    product_price: ''
                                })
                                this.props.editLeadDeal(this.props.lead?.set_lead_details._id, this.state.product_name, this.state.product_price, this.state.deal_id)
                                // 
                            }}
                        >
                            Edit
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* ----------email--------- */}
                <Dialog
                    open={this.state.emailmodal}
                >
                    <Grid style={{ height: "auto", width: "600px" }}>
                        <Box style={{ width: "100%" }}>
                            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10, backgroundColor: "lightgray" }}>
                                <Typography style={{ fontSize: 16, whiteSpace: "nowrap", fontWeight: 600 }}>Create Email</Typography>
                                <CloseOutlinedIcon
                                    onClick={() => { this.setState({ emailmodal: false }); }}
                                    style={{ fontSize: 20, color: "#000", cursor: "pointer", marginRight: "2%" }} />
                            </Grid>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "15px" }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar style={{ height: 40, width: 40 }}>
                                            <ImageIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Subh" />
                                </ListItem>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={eml}
                                        onChange={this.handleChangeage}
                                    >
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <Accordion style={{ width: "600px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography style={{ marginLeft: "20px" }}>To</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box>
                                        <ListItem style={{ backgroundColor: "#EBEBEB", width: "50%", borderRadius: "27px", marginLeft: "10px", height: "35px" }}>
                                            <ListItemAvatar>
                                                <Avatar style={{ height: 25, width: 25 }}>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="karna.subh@technoboot.co" />
                                        </ListItem>
                                    </Box>
                                    <Typography style={{ marginLeft: "20px", marginTop: "10px" }}>Cc</Typography>
                                    <Box>
                                        <ListItem style={{ marginTop: "10px", backgroundColor: "#EBEBEB", width: "50%", borderRadius: "27px", marginLeft: "10px", height: "35px" }}>
                                            <ListItemAvatar>
                                                <Avatar style={{ height: 25, width: 25 }}>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="karna.subh@technoboot.co" />
                                        </ListItem>
                                    </Box>
                                    <Typography style={{ marginLeft: "20px", marginTop: "10px" }}>Bcc</Typography>
                                    <Box>
                                        <ListItem style={{ marginTop: "10px", backgroundColor: "#EBEBEB", width: "50%", borderRadius: "27px", marginLeft: "10px", height: "35px" }}>
                                            <ListItemAvatar>
                                                <Avatar style={{ height: 25, width: 25 }}>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="karna.subh@technoboot.co" />
                                        </ListItem>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            {/* <Divider /> */}
                            <div style={{ padding: "14px", }}>
                                <Textarea name="Outlined" placeholder="Subject" variant="outlined" style={{ margin: "15px", width: "95%" }} />
                            </div>

                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "14px", }} >
                                {/* <InsertLinkIcon /> */}
                                <Button
                                    onClick={() => { this.setState({ emailmodal: false }); }}
                                    style={{ backgroundColor: "#3D3B3B", marginLeft: "80%" }} className='create-btn' variant="contained">Send</Button>
                            </div>
                        </Box>
                    </Grid>
                </Dialog>
                {/* ----------CReate payment--------- */}
                <Dialog fullWidth maxWidth='xs' open={this.state.paymentmodal}>
                    <DialogTitle style={{ display: "flex", justifyContent: "space-between", backgroundColor: 'lightgray' }}>
                        <Typography variant='h6'>Select mode of Payment</Typography>
                        <CloseCircle
                            size="24"
                            color="black"
                            variant="Outline"
                            cursor={'pointer'}
                            onClick={() => {
                                this.setState({ paymentmodal: false });
                            }}
                        />
                    </DialogTitle>
                    <DialogContent >
                        <Grid container mt={2}>
                            <Grid item xs={4}>
                                <Typography variant='h7'>Pay Through <span style={{ color: 'red' }}>*</span> : </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    select
                                    value={this.state.paid_by || 'N'}
                                    onChange={(e) => { this.setState({ paid_by: e.target.value }) }}
                                >
                                    <MenuItem value={'N'}>
                                        Select Mode
                                    </MenuItem>
                                    <MenuItem value={'Cash'}>
                                        Cash
                                    </MenuItem>
                                    <MenuItem value={'Cheque'}>
                                        Cheque
                                    </MenuItem>
                                    <MenuItem value={'Card'}>
                                        Card
                                    </MenuItem>
                                    <MenuItem value={'Netbanking'}>
                                        Netbanking
                                    </MenuItem>
                                    <MenuItem value={'UPI'}>
                                        UPI
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container mt={4}>
                            <Grid item xs={4}>
                                <Typography variant='h7'>Amount <span style={{ color: 'red' }}>*</span> : </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    variant='standard'
                                    fullWidth
                                    type='number'
                                    value={this.state.amount}
                                    onChange={(e) => { this.setState({ amount: e.target.value }) }}
                                    placeholder='Enter amount'
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
                        <Button
                            disabled={this.state.amount === ""}
                            style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white' }} variant="contained"
                            onClick={(e) => {
                                this.setState({ paymentmodal: false, paid_by: 'N', amount: '' })
                                this.props.createPayment(this.props.login.org_id, this.props.lead?.set_lead_details._id, this.state.paid_by, this.state.amount, this.state.page, this.state.rowsPerPage, this.state.deal_id)
                            }}>Submit</Button>
                    </DialogActions>
                    {/* <Tabs>
                        <TabList style={{ border: "none" }}>
                            <Tab>Cash</Tab>
                            <Tab>Online</Tab>
                        </TabList>
                        <TabPanel>
                            <Box style={{ "marginLeft": "4em", "width": "80%", "gap": "26px", "display": "flex", "flexDirection": "column" }}>
                                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}><Typography variant='h6'>Paid Through-</Typography><TextField id="standard-basic" variant="standard"
                                    value={this.state.paid_by}
                                    onChange={(e) => {
                                        this.setState({ paid_by: e.target.value })
                                    }} /></Box>
                                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}><Typography variant='h6'>Amount-</Typography><TextField id="standard-basic" variant="standard" label="Enter amount"
                                    value={this.state.amount}
                                    onChange={(e) => {
                                        this.setState({ amount: e.target.value })
                                    }} /></Box>
                                <TextField id="standard-basic" label="in words" variant="standard" />
                                <Button style={{ width: "3em", left: "26em", backgroundColor: "grey" }} variant="contained"
                                    onClick={(e) => {
                                        this.setState({ paymentmodal: false })
                                        this.props.createPayment(this.props.lead?.set_lead_details._id, this.state.paid_by, this.state.amount, this.state.page, this.state.rowsPerPage, this.state.deal_id)
                                    }}>Submit</Button>
                            </Box>
                        </TabPanel>
                        <TabPanel>
                            <Box style={{ display: "flex", flexDirection: 'row', alignItems: "flex-start", justifyContent: "space-between" }}>
                                <Tabs style={{ width: "80%" }}>
                                    <TabList style={{ border: "none" }}>
                                        <Tab>Net Banking</Tab>
                                        <Tab>UPI</Tab>
                                        <Tab>Via link</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <Box style={{ "marginLeft": "4em", "width": "80%", "gap": "26px", "display": "flex", "flexDirection": "column" }}>
                                                <h4>Select a bank for payment</h4>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Age"
                                                >
                                                    <MenuItem value={10}>Ten</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </Box>
                                    </TabPanel>
                                    <TabPanel>
                                        <Box style={{ "marginLeft": "4em", "width": "80%", "gap": "26px", "display": "flex", "flexDirection": "column" }}>
                                            <h4>Use the Scanner ror upi id to pay</h4>
                                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}>
                                                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}><Typography variant='h6'>UPI id-</Typography><TextField value="786788866@ybl" id="standard-basic" variant="standard" /></Box>
                                                <Box className="QR code" style={{ height: "30px", width: "30px", backgroundColor: "black" }}></Box>
                                            </Box>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel>
                                        <Box style={{ "marginLeft": "4em", "width": "80%", "gap": "26px", "display": "flex", "flexDirection": "column" }}>
                                            <h4>use the link for payment</h4>
                                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <Typography variant='h6'>Copy this</Typography>
                                                <ContentCopyIcon />
                                            </Box>
                                        </Box>
                                    </TabPanel>
                                </Tabs>
                                <Button onClick={() => this.setState({ paymentmodal: false })} variant="text">Save</Button></Box>
                        </TabPanel>
                    </Tabs> */}
                </Dialog>

                {/* ----------Download--------- */}

                <Dialog open={this.state.download}>
                    <Grid style={{
                        display: "flex", justifyContent: "space-between", padding: "23px",
                    }}>
                        <ArrowBackIosIcon onClick={() => {
                            this.setState({ download: false });
                        }} />
                        <Typography style={{ fontWeight: "600" }}>Preview</Typography>
                    </Grid>
                    <Box style={{ width: "433px", display: "flex", flexDirection: 'column', alignItems: "center" }}>

                        <Box style={{
                            width: "362px",
                            height: " 483.684px",
                            flexShrink: 0, backgroundColor: "Gray"
                        }}>
                            d
                        </Box>
                        <Button onClick={() => this.setState({ downloadDone: true })} style={{ backgroundColor: "black", margin: ".6em" }} variant="contained">Download</Button>
                    </Box>
                </Dialog>
                {/* downloadDone */}
                <Dialog open={this.state.downloadDone}>
                    <Box style={{ height: "302px", width: "422px", display: "flex", flexDirection: 'column', alignItems: "center" }}>
                        <Box style={{
                            width: "196.833px",
                            height: "190.62px",
                            flexShrink: 0,
                        }}>
                        </Box>
                        <Typography variant='h5'>Download Successful</Typography>
                        <Button onClick={() => { this.setState({ download: false, downloadDone: false }); }} style={{ backgroundColor: "black", margin: ".6em" }} variant="contained">OK</Button>
                    </Box>
                </Dialog>
                {/* ---------next follow up---------- */}
                <Dialog fullWidth maxWidth='sm' open={this.state.adminfolloup}>
                    <DialogTitle style={{ display: "flex", justifyContent: "space-between", backgroundColor: 'lightgray' }}>
                        <Typography variant='h6'>Next Followup</Typography>
                        <CloseCircle
                            size="24"
                            color="black"
                            variant="Outline"
                            cursor={'pointer'}
                            onClick={() => {
                                this.setState({ adminfolloup: false });
                            }}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container mt={2}>
                            <Grid item xs={12}>
                                <Typography variant='h7'>Description <span style={{ color: 'red' }}>*</span></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    onChange={(e) => {
                                        this.setState({ description: e.target.value })
                                    }}
                                    fullWidth
                                    multiline
                                    style={{ backgroundColor: "#F2F2F2", }}
                                    placeholder='Enter Description'
                                />
                            </Grid>
                        </Grid>
                        <Grid container mt={2} style={{ border: "0.5px solid lightgray", borderRadius: 5, padding: "20px", display: "flex" }}>

                            <Grid item xs={3.5}>
                                <Typography variant='h7'>Call Purpose <span style={{ color: 'red' }}>*</span></Typography>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    value={this.state.call_purpose}
                                    onChange={(e) => {
                                        this.setState({ call_purpose: e.target.value })
                                    }}
                                    placeholder='Enter the purpose'
                                />
                            </Grid>
                            <hr style={{ backgroundColor: "gray", height: 30, width: 2 }} />
                            <Grid item xs={3.5}>
                                <Typography variant='h7'>Follow Up Date<span style={{ color: 'red' }}>*</span></Typography>
                                <TextField
                                    fullWidth
                                    type='date'
                                    value={this.state.next_followup_date}
                                    onChange={(e) => {
                                        this.setState({ next_followup_date: e.target.value })
                                    }}
                                />
                            </Grid>
                            <hr style={{ backgroundColor: "gray", height: 30, width: 2 }} />
                            <Grid item xs={3.5}>
                                <Typography variant='h7'>Follow Up Time</Typography>
                                <TextField
                                    fullWidth
                                    type='time'
                                    value={this.state.reminder}
                                    onChange={(e) => {
                                        this.setState({ reminder: e.target.value })
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
                        <Button
                            disabled={this.state.reminder === '' || this.state.call_purpose === '' || this.state.next_followup_date === ''}
                            onClick={() => {
                                this.setState({ adminfolloup: false })
                                this.props.addFollowup(this.props.lead?.set_lead_details._id, this.state.description, this.state.reminder, this.state.call_purpose, this.state.next_followup_date, this.state.deal_id, this.props.login.user_id)
                            }} style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white' }} variant="contained">Save</Button>
                    </DialogActions>
                </Dialog>
                {/* ----calander---- */}
                <Dialog
                    open={this.state.calandericonad}
                >
                    <Calendar
                        onChange={this.handleChangeCalad} value={this.state.calvaluead} />
                </Dialog>
                {/*---------------Quotes-----------------------*/}
                <Dialog open={this.state.quotemodal} fullWidth maxWidth='xs'>
                    <DialogTitle display={'flex'} justifyContent={'space-between'} backgroundColor={'lightgray'}>
                        <Typography variant='h6'>Add Quote</Typography>
                        <CloseCircle
                            size="24"
                            color="black"
                            variant="Outline"
                            cursor={'pointer'}
                            onClick={() => {
                                this.setState({
                                    quotemodal: false,
                                    amount: "",
                                    quotefile: "",
                                });
                            }}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container mt={4}>
                            <Grid item xs={4}>
                                <Typography variant='h7'>Amount <span style={{ color: 'red' }}>*</span> : </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    variant='standard'
                                    fullWidth
                                    type='number'
                                    value={this.state.amount}
                                    onChange={(e) => { this.setState({ amount: e.target.value }) }}
                                    placeholder='Enter amount'
                                />
                            </Grid>
                        </Grid>
                        <Grid container mt={2}>
                            <Grid item xs={12}>
                                <Box fullWidth style={{
                                    height: "50px",
                                    borderRadius: '0.5rem',
                                    border: "1px solid black",
                                    position: 'relative', // Add position relative to contain the absolute positioned img
                                }}>
                                    {this.state.quotefile !== "" ?
                                        <label
                                            htmlFor="file"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '100%',
                                                width: '100%',
                                                color: 'green',
                                                fontWeight: 500,
                                            }}
                                        >
                                            Uploaded Successfully
                                        </label>
                                        :
                                        <label
                                            htmlFor="file"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        >
                                            Upload File
                                        </label>
                                    }
                                    <input
                                        style={{ display: "none", cursor: "pointer" }}
                                        type="file"
                                        id="file"
                                        onChange={(e) => {
                                            // Ensure that e.target.files is not empty and contains a File object
                                            if (e.target.files && e.target.files.length > 0) {
                                                this.setState({ quotefile: e.target.files[0] });
                                            }
                                        }}
                                    />

                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button variant='contained'
                            disabled={this.state.amount === ""}
                            style={{
                                borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white'
                            }}
                            onClick={() => {
                                this.setState({
                                    quotemodal: false,
                                    amount: "",
                                    quotefile: "",
                                });
                                this.props.uploadQuote(
                                    this.props.login.org_id,
                                    this.props.lead?.set_lead_details._id,
                                    this.state.deal_id,
                                    this.state.amount,
                                    this.state.quotefile,
                                );
                            }}
                        >
                            Add
                        </Button>
                    </DialogActions>

                </Dialog>

                {/*-------------Edit Lead---------------*/}
                <Dialog maxWidth='sm' fullWidth
                    open={this.state.editLeads}
                >
                    <DialogTitle style={{ display: "flex", justifyContent: "space-between", backgroundColor: "lightgray" }}>
                        <Typography variant='h6'>Edit Leads</Typography>
                        <CloseCircle
                            size="24"
                            color="black"
                            variant="Outline"
                            cursor={'pointer'}
                            onClick={() => {
                                this.setState({
                                    editLeads: false,
                                    lname: '',
                                    lphone: '',
                                    lemail: '',
                                    lpin: '',
                                    laddress: '',
                                });
                            }}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container mt={2}>
                            <Grid item xs={2}>
                                <Typography variant='h7'>Name :</Typography>
                            </Grid>
                            <Grid item xs={3.8}>
                                <TextField
                                    fullWidth
                                    id="outlined-select-currency-native"
                                    value={this.state.lname}
                                    variant='standard'
                                    onChange={(e) => {
                                        this.setState({ lname: e.target.value, });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={.4} />
                            <Grid item xs={2}>
                                <Typography variant='h7'>Contact :</Typography>
                            </Grid>
                            <Grid item xs={3.8}>
                                <TextField id="standard-basic" variant="standard"
                                    fullWidth
                                    type='number'
                                    value={this.state.lphone}
                                    onChange={(e) => {
                                        this.setState({ lphone: e.target.value });
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container mt={2}>
                            <Grid item xs={2}>
                                <Typography variant='h7'>Email ID :</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    id="outlined-select-currency-native"
                                    value={this.state.lemail}
                                    variant='standard'
                                    onChange={(e) => {
                                        this.setState({ lemail: e.target.value, });
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container mt={2}>
                            <Grid item xs={2}>
                                <Typography variant='h7'>Pin :</Typography>
                            </Grid>
                            <Grid item xs={3.8}>
                                <TextField
                                    fullWidth
                                    id="outlined-select-currency-native"
                                    value={this.state.lpin}
                                    variant='standard'
                                    onChange={(e) => {
                                        this.setState({ lpin: e.target.value, });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={.4} />
                            <Grid item xs={2}>
                                <Typography variant='h7'>Address :</Typography>
                            </Grid>
                            <Grid item xs={3.8}>
                                <TextField id="standard-basic" variant="standard"
                                    fullWidth
                                    value={this.state.laddress}
                                    onChange={(e) => {
                                        this.setState({ laddress: e.target.value });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
                        <Button
                            onClick={() => {
                                this.setState({
                                    editLeads: false,
                                    lname: '',
                                    lphone: '',
                                    lemail: '',
                                    lpin: '',
                                    laddress: '',
                                });
                            }}
                            variant='outlined' style={{ borderradius: ' 0.33675rem', border: '1px solid var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'var(--PrimaryColour-G1, #3D3B3B)' }}>Cancel</Button>
                        <Button
                            size='small'
                            variant='contained' style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white' }}
                            onClick={() => {
                                this.setState({
                                    editLeads: false,
                                    lname: '',
                                    lphone: '',
                                    lemail: '',
                                    lpin: '',
                                    laddress: '',
                                })
                                this.props.updateLead(this.props.lead?.set_lead_details._id, this.state.lname, this.state.lemail, this.state.lphone, this.state.lcity, this.state.lstate, this.state.lcountry, this.state.lpin, this.state.laddress)
                            }}
                        >
                            Edit
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Approve quotation */}
                <Dialog maxWidth='xs' fullWidth
                    open={this.state.statuschange}>
                    <DialogTitle style={{ backgroundColor: "lightgray" }}>
                        <Typography variant='h6'>Approve Quotation</Typography>
                    </DialogTitle>
                    <DialogContent style={{ padding: '20px' }}>
                        <center><Typography>Are you sure you want to approve this quotation ?</Typography></center>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
                        <Button
                            onClick={() => {
                                this.setState({
                                    statuschange: false,
                                });
                            }}
                            variant='outlined' style={{ borderradius: ' 0.33675rem', border: '0.5px solid var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'var(--PrimaryColour-G1, #3D3B3B)' }}>Cancel</Button>
                        <Button
                            size='small'
                            variant='contained' style={{ borderradius: ' 0.33675rem', border: '0.5px solid var(--PrimaryColour-G1, #3D3B3B)', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white' }}
                            onClick={() => {
                                this.setState({
                                    statuschange: false,
                                })
                                this.props.approveQuotation(this.state.deal_id, this.state.quote_id, this.state.amount);
                            }}
                        >
                            Approve
                        </Button>
                    </DialogActions>
                </Dialog>
                <LoaderCon />

            </Grid>
        )
    }
}
export default withParams(AdminleadDetails);

