// import React, { Component } from 'react'
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import 'react-tabs/style/react-tabs.css';
// import { Link } from 'react-router-dom';
// import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import { Grid, Dialog, TextField } from '@mui/material';
// import Avatar from '@mui/material/Avatar';
// import LoaderCon from "../../common/loader/containers/loader_cont";
// import CameraAltIcon from '@mui/icons-material/CameraAlt';
// export default class vieworg extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             otp: "",
//             addorg: false
//         };
//     }
//     componentDidMount() {
//         this.props.viewAccessByUser(this.props.login.user_id);
//         
//     }
//     render() {
//         const { login } = this.props;
//         return (
//             <div className='bg'>
//                 <Box style={{ display: "flex" }}>
//                     <Card style={{ marginLeft: "auto", marginRight: "auto", width: "400px", marginTop: "50px", height: "400px", boxShadow: "2px 2px 15px rgba(0, 0, 0, 0.2)", borderRadius: "10px" }}>
//                         <CardContent>
//                             <Box style={{ textAlign: "center" }}>
//                                 <Typography style={{ fontSize: "20px", fontWeight: 600, letterSpacing: 2, }}>
//                                     {this.props.login.user_name}
//                                 </Typography>
//                             </Box>
//                             <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "15px" }}>
//                                 <Typography style={{ fontSize: "16px", fontWeight: 600, marginTop: "25px" }}>
//                                     Choose Organization
//                                 </Typography>
//                                 <Button style={{ color: "blue", fontSize: "13px", fontWeight: 400 }}
//                                     onClick={() => {
//                                         this.setState({ addorg: true })
//                                     }}
//                                 >
//                                     + Create Organization
//                                 </Button>
//                             </Grid>
//                             <Box>
//                                 <div style={{ boxShadow: "none", height: '300px' }}>
//                                     <Box>
//                                     </Box>
//                                     {Array.isArray(login.all_org) && login.all_org.map((e) => (
//                                         <Box style={{ display: "flex", marginTop: "5%", height: "5vh", width: "80%", marginLeft: "11%" }}>
//                                             <Avatar
//                                                 src={e.logo === undefined ? "" : e.logo}
//                                                 sx={{ width: 24, height: 24, marginLeft: "9%", marginTop: "2%" }} />
//                                             <Link style={{ textDecoration: "none" }} to="/">
//                                                 <Button onClick={() => {
//                                                     this.props.viewUserOrgDetails(this.props.login.user_id, e._id)
//                                                     
//                                                 }}
//                                                     style={{ color: "black", display: "flex", fontSize: "15px", fontWeight: 400 }}>
//                                                     {e.org_name}
//                                                 </Button>
//                                             </Link>
//                                         </Box>
//                                     ))}
//                                 </div>
//                             </Box>
//                         </CardContent>
//                     </Card>
//                 </Box>
//                 <Dialog
//                     open={this.state.addorg} style={{}}
//                     onClose={() => {
//                         this.setState({ addorg: false })
//                     }}
//                 >
//                     <Grid style={{ height: "auto", width: "450px", marginBottom: "30px" }}>
//                         <Box >
//                             <Typography className='Create' style={{ textAlign: "center" }}>Create Organization</Typography>
//                             <Grid style={{ marginTop: "5%" }}>
//                                 <Avatar
//                                     src={this.state.logo ? URL.createObjectURL(this.state.logo) : ""}
//                                     style={{ marginLeft: "auto", marginRight: "auto" }}
//                                 >
//                                     <input
//                                         style={{ display: "none", cursor: "pointer" }}
//                                         type="file"
//                                         id="file"
//                                         onChange={(e) => {
//                                             // Ensure that e.target.files is not empty and contains a File object
//                                             if (e.target.files && e.target.files.length > 0) {
//                                                 this.setState({ logo: e.target.files[0] });
//                                             }
//                                         }}
//                                     />
//                                     <label htmlFor="file">
//                                         <CameraAltIcon style={{ marginTop: "25%" }} />
//                                     </label>
//                                 </Avatar>
//                                 <Typography className='Name' style={{}}>Name of Organization</Typography>
//                                 <TextField style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"
//                                     onChange={(e) => {
//                                         this.setState({ org_name: e.target.value })
//                                     }}
//                                 />
//                                 <Typography className='Name' style={{ marginTop: "10px" }}>Email</Typography>
//                                 <TextField type="Email" style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"
//                                     onChange={(e) => {
//                                         this.setState({ email: e.target.value })
//                                     }}
//                                 />
//                                 <Typography className='Name' style={{ marginTop: "10px" }}>Phone Number</Typography>
//                                 <TextField type="number" style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"
//                                     onChange={(e) => {
//                                         this.setState({ mobile: e.target.value })
//                                     }}
//                                 />
//                                 <Typography className='Name' style={{ marginTop: "10px" }}>GSTIN</Typography>
//                                 <TextField type="number" style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"
//                                     onChange={(e) => {
//                                         this.setState({ gstin: e.target.value })
//                                     }}
//                                 />
//                                 <Typography className='Name' style={{ marginTop: "10px" }}>Address</Typography>
//                                 <TextField style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5", marginTop: "10px" }}
//                                     name="Outlined" placeholder="Type in here…" variant="outlined"
//                                     onChange={(e) => {
//                                         this.setState({ org_address: e.target.value })
//                                     }}
//                                 />
//                                 <center>
//                                     <Button
//                                         onClick={() => {
//                                             this.setState({ addorg: false });
//                                             this.props.uploadOrgLogo(this.state.logo, this.state.org_name, this.state.email, this.state.mobile, this.state.gstin, this.props.login.user_id, this.state.org_address)
//                                             
//                                         }}
//                                         variant='outlined' style={{ border: "1px solid gray", color: "gray", marginTop: "20px"}}>
//                                         Add Organization
//                                     </Button>
//                                 </center>
//                             </Grid>
//                         </Box>
//                     </Grid>
//                 </Dialog>
//                 <LoaderCon />
//             </div>
//         )
//     }
// }












import React, { Component } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, Dialog, TextField } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import LoaderCon from "../../common/loader/containers/loader_cont";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AddIcon from '@mui/icons-material/Add';
export default class vieworg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: "",
            addorg: false,
            SelectOrg: false,
        };
    }
    componentDidMount() {
        this.props.viewAccessByUser(this.props.login.user_id);
    }
    render() {
        const { login } = this.props;
        return (
            <div className='bg'>
                <Box style={{ display: "flex" }}>
                    <Card style={{ marginLeft: "auto", marginRight: "auto", width: "400px", marginTop: "50px", height: "400px", boxShadow: "2px 2px 15px rgba(0, 0, 0, 0.2)", borderRadius: "10px" }}>
                        <CardContent>
                            <Box style={{ textAlign: "center" }}>
                                <Typography style={{ fontSize: "20px", fontWeight: 600, letterSpacing: 2, }}>
                                    {this.props.login.user_name}
                                </Typography>
                            </Box>
                            <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "15px" }}>
                                <Typography style={{ fontSize: "16px", fontWeight: 600, marginTop: "25px" }}>
                                    Choose Organization
                                </Typography>
                                <Button style={{ color: "blue", fontSize: "13px", fontWeight: 400 }}
                                    onClick={() => {
                                        this.setState({ SelectOrg: true });
                                        this.props.view_owned_org(this.props.login.user_id, 'crm')
                                    }}
                                >
                                    +Add Organization
                                </Button>
                            </Grid>
                            <Box>
                                <div style={{ boxShadow: "none", height: '300px' }}>
                                    <Box>
                                    </Box>
                                    {Array.isArray(login.all_org) && login.all_org.map((e) => (
                                        <Box style={{ display: "flex", marginTop: "5%", height: "5vh", width: "80%", marginLeft: "11%" }}>
                                            <Avatar
                                                src={e.logo === undefined ? "" : e.logo}
                                                sx={{ width: 24, height: 24, marginLeft: "9%", marginTop: "2%" }} />
                                            <Link style={{ textDecoration: "none" }} to="/">
                                                <Button onClick={() => {
                                                    this.props.viewUserOrgDetails(this.props.login.user_id, e._id)
                                                    
                                                }}
                                                    style={{ color: "black", display: "flex", fontSize: "15px", fontWeight: 400 }}>
                                                    {e.org_name}
                                                </Button>
                                            </Link>
                                        </Box>
                                    ))}
                                </div>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Dialog
                    open={this.state.SelectOrg} style={{}}
                    onClose={() => {
                        this.setState({ SelectOrg: false })
                    }}
                >
                    <Grid style={{ height: "auto", width: "450px", marginBottom: "30px" }}>
                        <Box >
                            
                            <Typography className='Create'>Select Organization</Typography>

                            {Array.isArray(this.props.login.org_own) && this.props.login.org_own.map((orgdata, index) => (
                                <Box style={{ display: "flex", marginLeft: "30px", marginTop: "12px" }}>
                                    <Button
                                        onClick={() => {
                                            this.setState({ SelectOrg: false })
                                            this.props.create_new_access(this.props.login.user_id, orgdata._id.toString(), 'crm')
                                        }}
                                    >
                                        <Avatar
                                            src={orgdata.logo}
                                            sx={{ width: 24, height: 24, marginLeft: "5%", marginTop: "2%" }} />
                                        <Typography
                                            style={{ color: "black", display: "flex", fontSize: "15px", marginTop: "6px", marginLeft: "10px", fontWeight: 400 }}>
                                            {orgdata.org_name}
                                        </Typography>
                                    </Button>
                                </Box>
                            ))}
                            <Grid style={{ marginTop: "5%" }}>
                                <center>
                                    <Button

                                        onClick={() => {
                                            this.setState({ addorg: true });
                                        }}
                                        startIcon={<AddIcon style={{ fontSize: "15px", color: "#1C352D", }} />} style={{ color: "#1C352D", display: "flex", fontSize: "13px", fontWeight: 600 }}>
                                        Create Organization
                                    </Button>
                                </center>
                            </Grid>
                        </Box>

                    </Grid>
                </Dialog>


                <Dialog
                    open={this.state.addorg} style={{}}
                    onClose={() => {
                        this.setState({ addorg: false })
                    }}
                >
                    <Grid style={{ height: "auto", width: "450px", marginBottom: "30px" }}>
                        <Box >
                            <Typography className='Create' style={{ textAlign: "center" }}>Create Organization</Typography>
                            <Grid style={{ marginTop: "5%" }}>
                                <Avatar
                                    src={this.state.logo ? URL.createObjectURL(this.state.logo) : ""}
                                    style={{ marginLeft: "auto", marginRight: "auto" }}
                                >
                                    <input
                                        style={{ display: "none", cursor: "pointer" }}
                                        type="file"
                                        id="file"
                                        onChange={(e) => {
                                            // Ensure that e.target.files is not empty and contains a File object
                                            if (e.target.files && e.target.files.length > 0) {
                                                this.setState({ logo: e.target.files[0] });
                                            }
                                        }}
                                    />
                                    <label htmlFor="file">
                                        <CameraAltIcon style={{ marginTop: "25%" }} />
                                    </label>
                                </Avatar>
                                <Typography className='Name' style={{}}>Name of Organization</Typography>
                                <TextField style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"
                                    onChange={(e) => {
                                        this.setState({ org_name: e.target.value })
                                    }}
                                />
                                <Typography className='Name' style={{ marginTop: "10px" }}>Email</Typography>
                                <TextField type="Email" style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"
                                    onChange={(e) => {
                                        this.setState({ email: e.target.value })
                                    }}
                                />
                                <Typography className='Name' style={{ marginTop: "10px" }}>Phone Number</Typography>
                                <TextField type="number" style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"
                                    onChange={(e) => {
                                        this.setState({ mobile: e.target.value })
                                    }}
                                />
                                <Typography className='Name' style={{ marginTop: "10px" }}>GSTIN</Typography>
                                <TextField type="number" style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5" }} id="standard-basic" variant="standard"
                                    onChange={(e) => {
                                        this.setState({ gstin: e.target.value })
                                    }}
                                />
                                <Typography className='Name' style={{ marginTop: "10px" }}>Address</Typography>
                                <TextField style={{ marginLeft: "30px", width: "380px", borderColor: "#B5E4D5", marginTop: "10px" }}
                                    name="Outlined" placeholder="Type in here…" variant="outlined"
                                    onChange={(e) => {
                                        this.setState({ org_address: e.target.value })
                                    }}
                                />
                                <center>
                                    <Button
                                        onClick={() => {
                                            this.setState({ addorg: false });
                                            this.props.uploadOrgLogo(this.state.logo, this.state.org_name, this.state.email, this.state.mobile, this.state.gstin, this.props.login.user_id, this.state.org_address)
                                           
                                        }}
                                        variant='outlined' style={{ border: "1px solid gray", color: "gray", marginTop: "20px" }}>
                                        Add Organization
                                    </Button>
                                </center>
                            </Grid>
                        </Box>
                    </Grid>
                </Dialog>
                <LoaderCon />
            </div>
        )
    }
}