import * as React from 'react';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
// import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import LGCont from "../containers/Lg_Cont";
import { useState } from 'react';
import { DialogContent, } from '@mui/material';
import {
  all_admin_option,
  all_manager_option,
  all_executive_option,
} from "../constant";
import { Avatar, Button, Grid, IconButton, MenuItem, TextField, Dialog, Paper, } from '@mui/material';
import "../../router/components/drawer.css";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Crmlogo from "../../pages/image/crmlogo.png";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import { styled } from "@mui/material/styles";
import { useLocation } from 'react-router-dom'
import logo from "../../assets/img/email1.svg"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { onLogout } from '../../auth/actions';
const drawerWidth = 200;
const drawerColor = "#EBF0FF";

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: drawerColor,

  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',

});

const closedMixin = (theme) => ({
  backgroundColor: drawerColor,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 25px)`,
  },
});




const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  // width: `calc(100% - 65px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    // padding: '5px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);






export default function MiniDrawer(props) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [notimodal, setnotimodal] = React.useState(false);
  const [supportmodal, setsupportmodal] = React.useState(false);
  const dispatch = useDispatch();
  const location = useLocation();



  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(onLogout());
    closeModal();
  };



  function sideBar(type, open) {

    if (type === "Owner") {
      return (
        <div style={{ marginTop: 1 }}>
          {all_admin_option.map((option) => (
            location.pathname === option.link ?
              <center style={{ background: "linear-gradient(to right, rgba(255,255,255,0.5), rgba(255,0,0,0))", opacity: "0.9" }}>
                <Link key={option.link} to={option.link} style={{ textDecoration: "none", }}   >
                  <ListItem style={{ color: "white" }} button key={option.name} className="drawer_text">
                    <ListItemIcon ><Icon className="drawer_icon">{option.icon}</Icon></ListItemIcon>
                    <span style={{ color: "white" }} >{option.name}</span>
                  </ListItem>
                </Link>
              </center>
              :
              <center>
                <Link key={option.link} to={option.link} style={{ textDecoration: "none", }}   >
                  <ListItem style={{ color: "white" }} button key={option.name} className="drawer_text">

                    <ListItemIcon ><Icon className="drawer_icon">{option.icon}</Icon></ListItemIcon>

                    <span style={{ color: "white" }} >{option.name}</span>
                  </ListItem>
                </Link>
              </center>
          ))}
        </div>
      );
    }

    else if (type === "M") {
      return (
        <div style={{ marginTop: 1 }}>
          {all_manager_option.map((option) => (
            location.pathname === option.link ?
              <center style={{ background: "linear-gradient(to right, rgba(255,255,255,0.5), rgba(255,0,0,0))", opacity: "0.9" }}>
                <Link key={option.link} to={option.link} style={{ textDecoration: "none", }}   >
                  <ListItem style={{ color: "white" }} button key={option.name} className="drawer_text">

                    <ListItemIcon ><Icon className="drawer_icon">{option.icon}</Icon></ListItemIcon>

                    <span style={{ color: "white" }} >{option.name}</span>
                  </ListItem>
                </Link>
              </center>
              :
              <center>
                <Link key={option.link} to={option.link} style={{ textDecoration: "none", }}   >
                  <ListItem style={{ color: "white", }} button key={option.name} className="drawer_text">

                    <ListItemIcon ><Icon className="drawer_icon">{option.icon}</Icon></ListItemIcon>

                    <span style={{ color: "white" }} >{option.name}</span>
                  </ListItem>
                </Link>
              </center>
          ))}
        </div>
      );
    }

    else if (type === "E") {
      return (
        <div style={{ marginTop: 1 }}>
          {all_executive_option.map((option) => (
            location.pathname === option.link ?
              <center style={{ background: "linear-gradient(to right, rgba(255,255,255,0.5), rgba(255,0,0,0))", opacity: "0.9" }}>
                <Link key={option.link} to={option.link} style={{ textDecoration: "none", }}   >
                  <ListItem style={{ color: "white" }} button key={option.name} className="drawer_text">

                    <ListItemIcon ><Icon className="drawer_icon">{option.icon}</Icon></ListItemIcon>

                    <span style={{ color: "white" }} >{option.name}</span>
                  </ListItem>
                </Link>
              </center>
              :
              <center>
                <Link key={option.link} to={option.link} style={{ textDecoration: "none", }}   >
                  <ListItem style={{ color: "white", }} button key={option.name} className="drawer_text">

                    <ListItemIcon ><Icon className="drawer_icon">{option.icon}</Icon></ListItemIcon>

                    <span style={{ color: "white" }} >{option.name}</span>
                  </ListItem>
                </Link>
              </center>
          ))}
        </div>
      );
    }


  }
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <Box >
      <CssBaseline />
      {/* <div> */}
      <AppBar position="fixed" style={{ background: "rgba(215, 215, 215x)", width: "100%", zIndex: 1 }}>
        <Toolbar style={{}}>
          <div style={{ flexGrow: 1 }} />

          <Link style={{ marginRight: 10 }}>
            {/* 
            <IconButton>
              <img src={noti} alt='' onClick={() => setnotimodal(true)} style={{}} />

              <img src={switchimg} alt='' style={{ marginLeft: "30px" }} />
              <img src={support} alt='' onClick={() => setsupportmodal(true)} style={{ marginLeft: "30px" }} />
            </IconButton> */}
          </Link>
          {/* <img alt='' width={'75px'} src={Crmlogo} /> */}
          <Avatar
            alt="User Avatar"
            src={props.login.profile_pic}
            sx={{ width: 50, height: 50, mb: 0 }}
            style={{ cursor: "pointer" }}
            onClick={openModal}
          />

          <Box >
            {/* <Tooltip title="Open Profile"> */}
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            </IconButton>
            {/* </Tooltip> */}
            {/* <Menu
              sx={{ mt: '35px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >

              <MenuItem onClick={handleCloseUserMenu} style={{ flexDirection: "column" }}>

                <Logout />
              </MenuItem>
            </Menu> */}
          </Box>






        </Toolbar>
      </AppBar>






      <Drawer variant="permanent" open={true} >
        <Grid style={{ backgroundColor: '#414141', height: '100%', flexDirection: 'column', justifyContent: 'space-around', display: 'flex', alignItems: 'center' }}>


          {/* profile here */}
          <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px", color: "white" }}>
            <Box style={{}}>
              {/* <img src={Profilepic} style={{ height: "100%", width: "100%" }} /> */}

              <center>
                {(props.type === "Owner") ?
                  //  <Link to={"/Profile"} style={{textDecoration: "none"}}>
                  <Avatar

                    style={{ marginLeft: 0, width: 85, height: 85, marginTop: "5%", fontSize: "25px" }} >
                    {props.login.user_name == null ? "" : props.login.user_name.split(' ').map(part => part[0].toUpperCase()).join('')}

                  </Avatar>
                  // </Link>
                  :

                  (props.type === "M" ?
                    // <Link to={"/ManagerProfile"}>
                    <Avatar

                      style={{ marginLeft: 0, width: 85, height: 85, marginTop: "5%" }} >
                      {props.login.user_name == null ? "" : props.login.user_name.split(' ').map(part => part[0].toUpperCase()).join('')}

                    </Avatar>
                    // </Link>
                    :
                    // <Link to={"/Executiveprofile"}>
                    <Avatar

                      style={{ marginLeft: 0, width: 85, height: 85, marginTop: "5%" }} >
                      {props.login.user_name == null ? "" : props.login.user_name.split(' ').map(part => part[0].toUpperCase()).join('')}

                    </Avatar>
                    // </Link>
                  )
                }



              </center>


            </Box>
            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "7px" }}>

              <Typography style={{ fontWeight: 500, fontSize: "20px" }}>{props.login.user_name}</Typography>

              {/* portal name changes here  */}
              {(props.type === "A" || props.type === "Owner") ? "Admin" : (props.type === "M" ? "Manager" : "Executives")}

            </Box>
          </Grid>


          {/* options here */}
          <List>
            {sideBar(props.type)}
          </List>

          {/* logout option here */}
          {/* <center>
            <LGCont />
          </center> */}
        </Grid>
      </Drawer>



      <Dialog open={supportmodal}  >
        <Box style={{ padding: "1em", width: "600px", backgroundColor: "white", "display": "flex", "flexDirection": "column", "alignItems": "flex-end" }}>

          <CloseOutlinedIcon
            style={{
              cursor: "pointer"
            }}
            onClick={() => {
              setsupportmodal(false)


            }}

            className="eventcross-icon"

          />

          <Box style={{ "flexDirection": "column", "display": "flex", "alignItems": "center", width: "100%" }}>
            <>

              <img src={logo} alt='' height={40} width={40} />

            </>
            <Typography variant="h6" component="h2">
              Our Agents will reach you back within 24hrs
            </Typography>
          </Box>


          <Box style={{
            display: "flex",
            padding: "36px",
            flexDirection: "column",
            gap: "23px",
            width: "100%"

          }}>
            <TextField id="outlined-basic" label="Name" variant="outlined" />
            <TextField id="outlined-basic" label="Phone no -" variant="outlined" />

            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Book your slot</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value="hello"
                  label="Book your slot"
                // onChange={handleChange}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <TextField id="outlined-basic" label="Description -" variant="outlined" />
          </Box>

          <Button variant="contained">Submit</Button>
        </Box>
      </Dialog>


      <Dialog open={notimodal}>
        <Box style={{ padding: "1em", width: "600px", backgroundColor: "white", "display": "flex", "flexDirection": "column", "alignItems": "flex-end" }}>
          <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10, width: "100%" }}>
            <Typography style={{ fontSize: "20px", fontWeight: "600" }}>Notification</Typography>
            <CloseOutlinedIcon
              onClick={() => setnotimodal(false)}

              style={{ fontSize: 20, color: "#000", cursor: "pointer" }} />
          </Grid>
          <Paper className='srch'
            component="form"
            style={{ display: "flex", marginLeft: "auto", width: "100%", height: "43px", marginTop: "6%", backgroundColor: "rgba(121, 0, 255, 0.08)", boxShadow: "none" }}
          >
            <IconButton
              type="button"
              style={{ p: "150px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              style={{ ml: 5, flex: 1, fontSize: "15px" }}
              placeholder="Search here"
              inputProps={{ "aria-label": "Search here" }}
            />
          </Paper>
          {
            [1, 2, 3, 4, 5, 6].map((i) => (
              <>  <Box key={i} style={{ width: "100%", padding: "10px", display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: ".5px solid gray", marginTop: "10px" }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "20px", width: "100%" }}>
                  <Typography style={{ "color": "var(--primary-colour-g-1, #3D3B3B)", "textAlign": "center", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal" }}>Sky Dey</Typography>
                  <Typography style={{ "color": "var(--primary-colour-g-1, #3D3B3B)", "textAlign": "center", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal" }}>Converted By</Typography>
                  <Typography style={{ "color": "var(--primary-colour-g-1, #3D3B3B)", "textAlign": "center", "fontFamily": "Lato", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "400" }}>Manish Kathura</Typography>
                  <Typography style={{ "color": "var(--primary-colour-g-1, #3D3B3B)", "textAlign": "center", "fontFamily": "Lato", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "400" }}>20min ago</Typography>

                </div>
                <MoreVertIcon />
              </Box>
              </>

            ))


          }
        </Box>
      </Dialog>
      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '80px', // Adjust as needed for your layout
            right: '50px', // Adjust as needed for your layout
            width: 300,
            backgroundColor: 'white',
            padding: 2,
            borderRadius: 2,
          }}
        >
          <center>
            <Typography variant="h6">{props.login.email}</Typography>
            {/* <Typography variant="h6">kumar.deepak@technoboot.co</Typography> */}
            <Avatar
              alt="User Avatar"
              src={props.login.profile_pic}
              sx={{ width: 75, height: 75, mb: 2 }}
            />
            <Typography variant="subtitle1">{props.login.user_name}</Typography>
            {/* <Typography variant="subtitle1">Deepak</Typography> */}
          </center>
          <center>
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
              }}
            >

              <LGCont />
              <Link to="/">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 1,
                    backgroundColor: '#3D3B3B',
                    width: 150,
                    height: 40,
                    color: 'white',
                  }}
                  onClick={() => {
                    props.logOutByToken(props.login.token)
                  }}
                >
                  Logout
                </Button>
              </Link>
            </Box>
          </center>
        </Box>
      </Modal>




    </Box>

  );
}
