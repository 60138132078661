import {
  VIEW_SALE_PRICE,
  VIEW_SALE_LENGTH,
  VIEW_ALL_ORDER,
  VIEW_EXECUTIVE_NEW_CUSTOMER,
  VIEW_ALL_NEW_CUSTOMER,
  VIEW_EXECUTIVE_ORDER,
  VIEW_SALE_PRICE_EXECUTIVE,
  VIEW_SALE_LENGTH_EXECUTIVE,




  VIEW_ALL_SOLD_PRODUCT,
  MANAGER_OF_THE_MONTH,
  EXECUTIVE_OF_THE_MONTH,
  VIEW_LEAD_DATA_BY_MONTH,
  VIEW_LEAD_DATA_BY_WEEK
} from "./constant";

const initial_state = {
  total_sale_price: 0,
  total_sale: [],
  all_order: 0,
  all_new_customer: 0,
  executive_new_customer: 0,
  executive_order: 0,
  executive_sale: [],
  executive_sale_price: 0,



  all_sold_product: [],
  sold_length: 0,
  new_customer_length: 0,
  manager_of_the_month: [],
  executive_of_the_month: [],
  lead_data_by_month: {},
  lead_data_by_week: {}
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case VIEW_SALE_PRICE:
      return {
        ...state,
        total_sale_price: action.payload
      };

    case VIEW_SALE_LENGTH:
      return {
        ...state,
        total_sale: action.payload
      };

    case VIEW_ALL_ORDER:

      return {
        ...state,
        all_order: action.payload
      };

    case VIEW_ALL_NEW_CUSTOMER:

      return {
        ...state,
        all_new_customer: action.payload
      };
    case VIEW_EXECUTIVE_NEW_CUSTOMER:

      return {
        ...state,
        executive_new_customer: action.payload
      };
    case VIEW_EXECUTIVE_ORDER:

      return {
        ...state,
        executive_order: action.payload
      };
    case VIEW_SALE_PRICE_EXECUTIVE:

      return {
        ...state,
        executive_sale_price: action.payload
      };
    case VIEW_SALE_LENGTH_EXECUTIVE:

      return {
        ...state,
        executive_sale: action.payload
      };















    case VIEW_ALL_SOLD_PRODUCT:

      return {
        ...state,
        all_sold_product: action.payload, sold_length: action.count
      };


    case MANAGER_OF_THE_MONTH:
      return {
        ...state,
        manager_of_the_month: action.payload
      };

    case EXECUTIVE_OF_THE_MONTH:
      return {
        ...state,
        executive_of_the_month: action.payload
      };

    case VIEW_LEAD_DATA_BY_MONTH:

      return {
        ...state,
        lead_data_by_month: action.payload
      };

    case VIEW_LEAD_DATA_BY_WEEK:

      return {
        ...state,
        lead_data_by_week: action.payload
      };




    default:
      return state;
  }
}
