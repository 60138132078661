import React, { Component } from "react";
import { connect } from "react-redux";
import Payment from "../Components/payment";
import {
  close_snack_bar
} from "../../../common/snackbar/action";
import {addBank,viewAllBank,addUPI,viewAllUpi} from "../action";


export class Controller extends Component {
    render() {
      return (
        <Payment {...this.props} />
      );
    }
  };
  export const mapStateToProps = store => {
    return {
      employee: store.employee,
      login: store.login,
      setting: store.setting,
    };
  };
  export const mapDispatchToProps = dispatch => {
    return {
      close_snack_bar: () => {
        dispatch(close_snack_bar());
      },
  
      // viewAllCategory: (org_id) => {
      //   dispatch(viewAllCategory(org_id));
      // },

      addBank: (org_id,bank_name,account_number,account_holder_name,branch,ifsc_code) => {
        dispatch(addBank(org_id,bank_name,account_number,account_holder_name,branch,ifsc_code));
      },
     
      viewAllBank: () => {
        dispatch(viewAllBank());
      },

      addUPI: (org_id,upi_id) => {
        dispatch(addUPI(org_id,upi_id));
      },
      
      viewAllUpi: (org_id) => {
        dispatch(viewAllUpi(org_id));
      },
  
    
  
     
      
     
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(Controller);