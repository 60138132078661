import React, { Component } from 'react'
import { debounce } from 'lodash';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import TablePagination from "@mui/material/TablePagination";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import icon1 from "../../../pages/image/crm.png"
import icon2 from "../../../pages/image/excel1.png"
import icon3 from "../../../pages/image/excel2.png"
// -----------------------import image start----------------------//

import LoaderCon from "../../../common/loader/containers/loader_cont";
// -----------------------mui properties start---------------------//
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TableCell, FormHelperText, Grid, TableRow, TableBody, TextField, TableContainer, Table, TableHead, Paper, Button, Modal, DialogContent, DialogTitle, DialogActions } from "@mui/material";
import { Alert } from '@mui/material';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { CloseCircle, DocumentUpload, AddCircle, ArrowCircleRight2, SearchNormal } from 'iconsax-react';
// -----------------------mui properties start---------------------//
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

export default class AdminNewLead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      inbox: false,
      document: false,
      add: false,
      deleteleadadmin: false,
      closed: true,
      age: '',
      excel_url: "",
      nextfolloup: false,
      calandericonad: false,
      phone: "",
      add_by: "",
      follow_up_date: "",
      email: "",
      product_name: "",
      product_price: "",
      deal_status: "",
      source: "",
      product_id: "",
      executive_id: "",
      category_id: '',
      category: "",
      name: "",
      description: "",
      reminder: "",
      call_purpose: "",
      status: "",
      isValidEmail: true,
      row_perpage: 10,
      pageNumber: 0,
      mailData: [],
      import: false,
      countryid: 0,
      stateid: 0,
      cityid: 0,
      pincode: "",
      address: "",
      countryname: "",
      statename: "",
      cityname: "",
      category_id: "",
      checkphmail: false,
      searchText: "",
      errors: {},
    };
    this.debouncedviewAllNewLead = debounce(this.props.viewAllNewLead, 500);
    this.debouncedviewAllFollowUpLead = debounce(this.props.viewAllFollowUpLead, 500);
    this.debouncedviewAllOldLead = debounce(this.props.viewAllOldLead, 500);


    this.debouncedviewAllNewLeadExecutive = debounce(this.props.viewAllNewLeadExecutive, 500);
  }

  validateFieldsexe = () => {
    const errors = {};

    if (!this.state.name) {
      errors.name = "Name is required";
    }
    if (!this.state.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
      errors.email = "Email is invalid";
    }
    if (!this.state.phone) {
      errors.phone = "Mobile number is required";
    } else if (!/^\d{10}$/.test(this.state.phone)) {
      errors.phone = "Mobile number is invalid";
    }
    if (!this.state.category_id) {
      errors.category_id = "Category is required";
    }
    if (!this.state.product_id) {
      errors.product_id = "Product is required";
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  validateFieldsdelstatus = () => {
    const errors = {};

    if (!this.state.deal_status) {
      errors.deal_status = "Deal Status is required";
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.setState({
      email: enteredEmail,
      isValidEmail: emailRegex.test(enteredEmail),
    });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  createData = (si, name, contact, source, status, categories, assignee) => {
    return { si, name, contact, source, status, categories, assignee };
  };
  handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      this.parseExcelData(file);
    }
  };
  downloadSampleFile = () => {
    // Define your sample data
    const data = [
      ['Name', 'Email', 'Phone', 'Source', 'Address', 'PIN', 'City', 'State', 'Country'],
      ['SWADHIN DAS', 'ABC@GMAIL.COM', '917438082965', 'META', 'Kendrapara', '754220', 'Rajkanika', 'Odisha', 'India'],
      ['SOUMYARANJAN ROUT', 'XYZ@GMAIL.COM', '917684566854', 'INDIAMART', 'Bhubaneswar', '751021', 'Aul', 'Odisha', 'India'],
      // Add more rows as needed
    ];

    // Create a new workbook and set the data
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SampleSheet');

    // Write the workbook to a file
    XLSX.writeFile(wb, 'sample_file.xlsx');
  };
  parseExcelData = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Assuming you're interested in the first sheet
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet);
      const mailArray = excelData.map((item) => ({
        name: item.Name,
        email: item.Email,
        phone: String(item.Phone),
        source: item.Source,
        add_by: this.props.login.user_id,
        executive_id: this.props.login.user_id,
        org_id: this.props.login.org_id,
        category: this.state.category_id,
        Address: item.Address,
        pin: item.PIN,
        city: item.City,
        state: item.State,
        country: item.Country,
        status: "New",
        create_date: new Date().toJSON().split('T')[0],
        create_time: new Date().toLocaleTimeString("en-GB", { timeZone: 'Asia/Kolkata' }),
      }));
      this.setState({ mailData: mailArray });

    };

    reader.readAsBinaryString(file);
  };
  componentDidMount() {

    if (this.props.login.type !== "E") {
      this.props.viewAllFollowUpLead(this.props.login.org_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      this.props.viewAllOldLead(this.props.login.org_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      this.props.viewAllNewLead(this.props.login.org_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
    }
    else if (this.props.login.type === "M") {
      this.props.viewAllFollowUpLeadManager(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      this.props.viewAllOldLeadManager(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      this.props.viewAllNewLeadManager(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
    }
    else if (this.props.login.type === "E") {
      this.props.viewAllFollowUpLeadExecutive(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      this.props.viewAllOldLeadExecutive(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      this.props.viewAllNewLeadExecutive(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
    }
    this.props.viewAllCategory(this.props.login.org_id);
    this.props.viewAllSource();
    this.props.viewAllProduct(this.props.login.org_id);
  }

  handleCountryChange = (e) => {
    this.setState({ countryid: e.id, countryname: e.name, stateid: 0, cityid: 0 });
  };

  handleStateChange = (e) => {
    this.setState({ stateid: e.id, statename: e.name, cityid: 0 });
  };
  handleCityChange = (e) => {
    this.setState({ cityid: e.id, cityname: e.name });
  };

  render() {
    const { isValidEmail } = this.state;
    const { leads } = this.props;
    const handleChangePage = (event, newPage) => {
      // this.props.viewAllNewLead(this.props.login.org_id, this.state.row_perpage, newPage, this.state.name);
      this.setState({
        pageNumber: newPage,
      });
      if (this.props.login.type !== "E") {
        this.props.viewAllNewLead(this.props.login.org_id, this.state.row_perpage, newPage, this.state.name);
      }
      else if (this.props.login.type === "M") {
        this.props.viewAllNewLeadManager(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, newPage, this.state.name);
      }
      else if (this.props.login.type === "E") {
        this.props.viewAllNewLeadExecutive(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, newPage, this.state.name);
      }

    };
    const handleChangeRow = (event) => {
      // this.props.viewAllNewLead(this.props.login.org_id, event.target.value, this.state.pageNumber, this.state.name);
      this.setState({
        row_perpage: event.target.value,
      });
      if (this.props.login.type !== "E") {
        this.props.viewAllNewLead(this.props.login.org_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      }
      else if (this.props.login.type === "M") {
        this.props.viewAllNewLeadManager(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      }
      else if (this.props.login.type === "E") {
        this.props.viewAllNewLeadExecutive(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      }
    };
    const handleChangePage1 = (event, newPage) => {
      // this.props.viewAllFollowUpLead(this.props.login.org_id, this.state.row_perpage, newPage, this.state.name);
      this.setState({
        pageNumber: newPage,
      });
      if (this.props.login.type !== "E") {
        this.props.viewAllFollowUpLead(this.props.login.org_id, this.state.row_perpage, newPage, this.state.name);
      }
      else if (this.props.login.type === "M") {
        this.props.viewAllFollowUpLeadManager(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, newPage, this.state.name);
      }
      else if (this.props.login.type === "E") {
        this.props.viewAllFollowUpLeadExecutive(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, newPage, this.state.name);
      }
    };
    const handleChangeRow1 = (event) => {
      // this.props.viewAllFollowUpLead(this.props.login.org_id, event.target.value, this.state.pageNumber, this.state.name);
      this.setState({
        row_perpage: event.target.value,
      });
      if (this.props.login.type !== "E") {
        this.props.viewAllFollowUpLead(this.props.login.org_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      }
      else if (this.props.login.type === "M") {
        this.props.viewAllFollowUpLeadManager(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      }
      else if (this.props.login.type === "E") {
        this.props.viewAllFollowUpLeadExecutive(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      }
    };
    const handleChangePage2 = (event, newPage) => {
      // this.props.viewAllOldLead(this.props.login.org_id, this.state.row_perpage, newPage, this.state.name);
      this.setState({
        pageNumber: newPage,
      });
      if (this.props.login.type !== "E") {
        this.props.viewAllOldLead(this.props.login.org_id, this.state.row_perpage, newPage, this.state.name);
      }
      else if (this.props.login.type === "M") {
        this.props.viewAllOldLeadManager(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, newPage, this.state.name);
      }
      else if (this.props.login.type === "E") {
        this.props.viewAllOldLeadExecutive(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, newPage, this.state.name);
      }
    };
    const handleChangeRow2 = (event) => {
      // this.props.viewAllOldLead(this.props.login.org_id, event.target.value, this.state.pageNumber, this.state.name);
      this.setState({
        row_perpage: event.target.value,
      });
      if (this.props.login.type !== "E") {
        this.props.viewAllOldLead(this.props.login.org_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      }
      else if (this.props.login.type === "M") {
        this.props.viewAllOldLeadManager(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      }
      else if (this.props.login.type === "E") {
        this.props.viewAllOldLeadExecutive(this.props.login.org_id, this.props.login.user_id, this.state.row_perpage, this.state.pageNumber, this.state.name);
      }
    };
    const { countryid, stateid } = this.state;
    return (
      <Grid>
        <Paper
          component="form"
          style={{
            width: '21.375rem',
            height: '2.5rem',
            gap: '0.625rem',
            borderRadius: '1.25rem',
            border: '1px solid var(--PrimaryColour-G7, #B8B8B8)'
          }}
        >
          <IconButton type="button" style={{ p: "15px" }} aria-label="search">
            <SearchNormal
              size="24"
              color="var(--PrimaryColour-G7, #B8B8B8)" />
          </IconButton>
          <InputBase
            className='search-text'
            placeholder="Search "
            inputProps={{ "aria-label": "Search " }}
            onChange={(e) => {
              // this.setState({ name: e.target.value })
              ((this.state.value === 1 && this.props.login.type !== "E") &&
                this.debouncedviewAllNewLead(this.props.login.org_id, 10, 0, e.target.value)
              );
              ((this.state.value === 1 && this.props.login.type === "E") &&
                this.debouncedviewAllNewLeadExecutive(this.props.login.org_id, this.props.login.user_id, 10, 0, e.target.value)
              );
              (this.state.value === 0 &&
                this.debouncedviewAllFollowUpLead(this.props.login.org_id, 10, 0, e.target.value)
              );
              (
                this.state.value === 2 &&
                this.debouncedviewAllOldLead(this.props.login.org_id, 10, 0, e.target.value)
              );
              this.setState({ name: e.target.value })
            }}
          />
        </Paper>

        <Box mt={1}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
            <Tabs value={this.state.value} onChange={this.handleChange} aria-label="basic tabs example">
              <Tab label="Today's" id="simple-tab-0" aria-controls="simple-tabpanel-1" style={{ fontSize: "20px", fontWeight: 500 }} />
              <Tab label="New Leads" id="simple-tab-1" aria-controls="simple-tabpanel-0" style={{ fontSize: "20px", fontWeight: 500 }} />
              <Tab label="Old Leads" id="simple-tab-2" aria-controls="simple-tabpanel-2" style={{ fontSize: "20px", fontWeight: 500 }} />
            </Tabs>
            <Grid sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>

              {this.state.value === 0 &&

                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 40, 50, 60]}
                  component="div"
                  count={leads.all_followups_lead_length}
                  rowsPerPage={this.state.row_perpage}
                  page={this.state.pageNumber}
                  onRowsPerPageChange={handleChangeRow1}
                  onPageChange={handleChangePage1}
                />
              }
              {this.state.value === 1 &&
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 40, 50, 60]}
                  component="div"
                  count={leads.all_new_lead_length}
                  rowsPerPage={this.state.row_perpage}
                  page={this.state.pageNumber}
                  onRowsPerPageChange={handleChangeRow}
                  onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                />

              }
              {this.state.value === 2 &&
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 40, 50, 60]}
                  component="div"
                  count={leads.all_old_leade_length}
                  rowsPerPage={this.state.row_perpage}
                  page={this.state.pageNumber}
                  onRowsPerPageChange={handleChangeRow2}
                  onPageChange={handleChangePage2}
                />

              }
              <DocumentUpload
                size="24"
                color="black"
                variant="Outline"
                cursor={'pointer'}
                onClick={() => this.setState({ import: true })}
              />
              <AddCircle
                size="24"
                color="black"
                variant="Outline"
                cursor={'pointer'}
                onClick={() => this.setState({ add: true })}
              />
            </Grid>
          </Box>

          <div hidden={this.state.value !== 0}>
            {this.state.value === 0 && (

              <Box sx={{ mt: 2 }}>
                <TableContainer component={Paper} style={{ borderRadius: '0px', marginBottom: '2%' }}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead style={{ background: "lightgray" }}>
                      <TableRow>
                        <TableCell> Sl No</TableCell>
                        <TableCell align="start">Name</TableCell>
                        <TableCell align="start">Contact</TableCell>
                        <TableCell align="start">Source</TableCell>
                        <TableCell align="start">Status</TableCell>
                        {/* <TableCell align="start">Category</TableCell> */}
                        {/* <TableCell align="start">Assignee</TableCell> */}
                        <TableCell align="start"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(this.props.leads.search_all_follow_up_lead) && this.props.leads.search_all_follow_up_lead?.sort((a, b) => (a._id > b._id ? -1 : 1))?.map((row, index) => {
                        return (
                          <TableRow
                            key={index + 1}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align='center' component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell align="start">{row.leadDetails?.name}</TableCell>
                            <TableCell align="start">{row.leadDetails?.phone}</TableCell>
                            <TableCell align="start" ><Typography style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>{row?.source}</Typography></TableCell>
                            <TableCell align="start">{row?.deal_status}</TableCell>
                            {/* <TableCell align="start">{row.categoryDetails?.cate_name}</TableCell> */}
                            {/* <TableCell align="start">{row.executive_id === undefined ? "Not Assigned" : "Assigned"}</TableCell> */}
                            <TableCell align="start"><Link to={`/AdminleadDetails/${row.leadDetails?._id}`}
                              onClick={() => {
                                // this.props.setLeadDetails(row.leadDetails);
                                this.props.view_lead_details(row.leadDetails?._id)
                              }}
                            >
                              <ArrowCircleRight2
                                size="32"
                                color="#292D32"
                                variant="Bulk" />
                              {/* <EastIcon style={{ color: '#292D32', borderRadius: '50%', padding: '4px 4px', fontSize: '32px', backgroundColor: 'whitesmoke' }} /> */}
                            </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </div>

          <div hidden={this.state.value !== 1}>
            {this.state.value === 1 && (
              <Box sx={{ mt: 2 }}>
                <TableContainer component={Paper} style={{ borderRadius: '0px', marginBottom: '2%' }}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead style={{ background: "lightgray" }}>
                      <TableRow>
                        <TableCell>Sl No</TableCell>
                        <TableCell align="start">Name</TableCell>
                        <TableCell align="start">Contact</TableCell>
                        <TableCell align="start">Source</TableCell>
                        <TableCell align="start">Status</TableCell>
                        {/* <TableCell align="start">Category</TableCell> */}
                        {/* <TableCell align="start">Assignee</TableCell> */}
                        <TableCell align="start"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(this.props.leads.search_new_lead) && this.props.leads.search_new_lead?.sort((a, b) => (a._id > b._id ? -1 : 1))?.map((row, index) => {

                        return (
                          <TableRow
                            key={row.si}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align='center' component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell align="start">{row.leadDetails?.name}</TableCell>
                            <TableCell align="start">{row.leadDetails?.phone}</TableCell>
                            <TableCell align="start" ><Typography style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>{row.source}</Typography></TableCell>
                            <TableCell align="start">{row?.deal_status}</TableCell>
                            {/* <TableCell align="start">{row.categoryDetails?.cate_name}</TableCell> */}
                            {/* <TableCell align="start">{row.executive_id === undefined ? "Not Assigned" : "Assigned"}</TableCell> */}
                            <TableCell align="start"><Link to={`/AdminleadDetails/${row?.leadDetails?._id}`}
                              onClick={() => {
                                // this.props.setLeadDetails(row.leadDetails);
                                this.props.view_lead_details(row.leadDetails._id)
                              }}
                            >
                              <ArrowCircleRight2
                                size="32"
                                color="#292D32"
                                variant="Bulk" />
                              {/* <EastIcon style={{ color: '#292D32', borderRadius: '50%', padding: '4px 4px', fontSize: '32px', backgroundColor: 'whitesmoke' }} /> */}
                            </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {/* <Pagination style={{ display: "flex", justifyContent: "flex-end" }}
                   count={parseInt(leads.all_new_lead_length / 10) + 1}
                   size="small"
                   className='pagination'
                   onChange={handleChangePage}
                 /> */}
                </TableContainer>
              </Box>
            )}
          </div>

          <div hidden={this.state.value !== 2}>
            {this.state.value === 2 && (
              <Box sx={{ mt: 2 }}>
                <TableContainer component={Paper} style={{ borderRadius: '0px', marginBottom: '2%' }}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead style={{ background: "lightgray" }}>
                      <TableRow>
                        <TableCell>SI No</TableCell>
                        <TableCell align="start">Name</TableCell>
                        <TableCell align="start">Contact</TableCell>
                        <TableCell align="start">Source</TableCell>
                        <TableCell align="start">Status</TableCell>
                        {/* <TableCell align="start">Category</TableCell> */}
                        {/* <TableCell align="start">Assignee</TableCell> */}
                        <TableCell align="start"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(this.props.leads.search_all_old_lead) && this.props.leads.search_all_old_lead.sort((a, b) => (a._id > b._id ? -1 : 1)).map((row, index) => {
                        return (
                          <TableRow
                            key={index + 1}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align='center' component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell align="start">{row?.leadDetails?.name}</TableCell>
                            <TableCell align="start">{row?.leadDetails?.phone}</TableCell>
                            <TableCell align="start" ><Typography style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>{row.source}</Typography></TableCell>
                            <TableCell align="start">{row?.deal_status}</TableCell>
                            {/* <TableCell align="start">{row?.categoryDetails?.cate_name}</TableCell> */}
                            {/* <TableCell align="start">{row.assignee}</TableCell> */}
                            <TableCell align="start"><Link to={`/AdminleadDetails/${row?.leadDetails?._id}`}
                              onClick={() => {
                                // this.props.setLeadDetails(row.leadDetails);
                                this.props.view_lead_details(row.leadDetails._id)
                              }}
                            >
                              <ArrowCircleRight2
                                size="32"
                                color="#292D32"
                                variant="Bulk" />
                              {/* <EastIcon style={{ color: '#292D32', borderRadius: '50%', padding: '4px 4px', fontSize: '32px', backgroundColor: 'whitesmoke' }} /> */}
                            </Link></TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </div>
        </Box>


























        {/*----------------------- add -------------------------*/}
        <Dialog
          maxWidth="sm"
          fullWidth
          open={this.state.add}
        >
          <DialogTitle display={'flex'} justifyContent={'space-between'} backgroundColor={'lightgray'}>
            <Typography variant='h6'>New Leads</Typography>
            <CloseCircle
              size="28"
              color="black"
              variant="Outline"
              cursor={'pointer'}
              onClick={() => {
                this.setState({ add: false })
              }} />
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} >
              <Grid item xs={6}>
                <TextField
                  onChange={(e) => {
                    this.setState({ name: e.target.value })
                  }}
                  fullWidth
                  error={!!this.state.errors.name}
                  helperText={this.state.errors.name}
                  placeholder='Name' id="standard-basic" label="Name *" variant="standard" />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) => {
                    this.setState({ email: e.target.value })
                    // this.handleEmailChange(e);
                  }}
                  fullWidth
                  error={!!this.state.errors.email}
                  helperText={this.state.errors.email}
                  placeholder='Email' id="standard-basic" label="Email" variant="standard" />
                {!isValidEmail && <div style={{ color: 'red', fontSize: "10px" }}>Invalid email format</div>}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='number'
                  onChange={(e) => {
                    this.setState({ phone: e.target.value })
                  }}
                  error={!!this.state.errors.phone}
                  helperText={this.state.errors.phone}
                  placeholder='Phone Number' id="standard-basic" label="Phone Number" variant="standard" />
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth >
                  <InputLabel id="demo-simple-select-standard-label">Source</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.source}
                    // onChange={this.handleChanges}
                    label="Source"
                    placeholder='Source'
                    onChange={(e) => {
                      this.setState({ source: e.target.value })
                    }}
                  >
                    {/* <MenuItem value={"N"}>{"All Source"}</MenuItem> */}
                    {Array.isArray(this.props.leads.all_source) && this.props.leads.all_source.map((item) => (
                      <MenuItem value={item.source_name}>{item.source_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth
                  error={!!this.state.errors.product_id}>
                  <InputLabel id="demo-simple-select-standard-label">Product <span style={{ color: 'red' }}>*</span> </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.product_id}
                    placeholder='Product'
                    label="Product"
                    // helperText={this.state.errors.product_id}
                    onChange={(e) => {
                      this.setState({ product_id: e.target.value })
                    }}
                  >
                    {Array.isArray(this.props.product.view_all_product) && this.props.product.view_all_product?.map((item) => (

                      <MenuItem value={item._id}
                        onClick={() => {
                          console.log(item)
                          this.setState({ product_name: item.product_name, product_price: item.product_price })
                        }}>{item.product_name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{this.state.errors.product_id}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth
                  error={!!this.state.errors.category_id}>
                  <InputLabel id="demo-simple-select-standard-label">Category <span style={{ color: 'red' }}>*</span> </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.category_id}
                    placeholder='Category'
                    label="Category"
                    // helperText={this.state.errors.category_id}
                    onChange={(e) => {
                      this.setState({ category_id: e.target.value })
                    }}
                  >
                    {/* <MenuItem value={"N"}>{"Select Category"}</MenuItem> */}
                    {Array.isArray(this.props.employee.all_category) && this.props.employee.all_category.map((item) => (
                      <MenuItem value={item._id}
                        onClick={() => {
                          this.setState({ category_id: item._id })
                        }}
                      >{item.cate_name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{this.state.errors.category_id}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                <CountrySelect fullWidth
                  onChange={this.handleCountryChange}
                  placeHolder="Select Country"
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel id="demo-simple-select-standard-label">State</InputLabel>
                <StateSelect fullWidth
                  countryid={countryid}
                  onChange={this.handleStateChange}
                  placeHolder="Select State"
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel id="demo-simple-select-standard-label">City</InputLabel>
                <CitySelect fullWidth
                  countryid={countryid}
                  stateid={stateid}
                  onChange={this.handleCityChange}
                  placeHolder="Select City"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth
                  value={this.state.address}
                  onChange={(e) => {
                    this.setState({ address: e.target.value })
                  }}
                  placeholder='Address' id="standard-basic" label="Address" variant="standard" />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth
                  value={this.state.pincode}
                  onChange={(e) => {

                    this.setState({ pincode: e.target.value })

                  }}
                  placeholder='Pin Code' id="standard-basic" type="number" label="Pin Code" variant="standard" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              // disabled={this.state.name === '' || (this.state.email === '' && this.state.phone === '') || this.state.product_id === '' || this.state.category === ''}
              onClick={() => {
                if (this.validateFieldsexe()) {
                  this.setState({
                    add: false,
                    nextfolloup: true,
                  });
                }


              }}
              variant='contained' style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white' }}>Save</Button>
          </DialogActions>
          {this.state.checkphmail &&
            <span style={{ color: 'red', fontSize: '10px', margin: '10px' }}>Please fill contact details (Phone no. / Email ID)</span>
          }
        </Dialog>

        {/*----------------------- nextfollowup -------------------------*/}
        <Dialog fullWidth maxWidth='sm' open={this.state.nextfolloup}>
          <DialogTitle style={{ display: "flex", justifyContent: "space-between", backgroundColor: 'lightgray' }}>
            <Typography variant='h6'>Next Followup</Typography>
            <CloseCircle
              size="24"
              color="black"
              variant="Outline"
              cursor={'pointer'}
              onClick={() => {
                this.setState({ nextfolloup: false });
              }}
            />
          </DialogTitle>
          <DialogContent>
            <Grid container mt={2}>
              <Grid item xs={12}>
                <Typography variant='h7'>Description <span style={{ color: 'red' }}>*</span></Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => {
                    this.setState({ description: e.target.value })
                  }}
                  fullWidth
                  multiline
                  style={{ backgroundColor: "#F2F2F2", }}
                  placeholder='Enter Description'
                />
              </Grid>

            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant='h7'>Deal Status <span style={{ color: 'red' }}>*</span></Typography>
              </Grid>
              <FormControl variant="standard" fullWidth error={!!this.state.errors.deal_status}>
                {/* <InputLabel id="demo-simple-select-standard-label">Deal Status <span style={{ color: 'red' }}>*</span> </InputLabel> */}
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.deal_status}
                  placeholder='Deal Status'
                  label="Deal Status"
                  onChange={(e) => {
                    this.setState({ deal_status: e.target.value })
                  }}
                >
                  {/* <MenuItem value={"New"}>{"New"}</MenuItem> */}
                  <MenuItem value={"In Follow Up"}>{"In Follow Up"}</MenuItem>
                  <MenuItem value={"Rejected"}>{"Rejected"}</MenuItem>
                  <MenuItem value={"Converted"}>{"Converted"}</MenuItem>

                </Select>
                <FormHelperText>{this.state.errors.deal_status}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid container mt={2} style={{ border: "0.5px solid lightgray", borderRadius: 5, padding: "20px", display: "flex" }}>
              <Grid item xs={3.5}>
                <Typography variant='h7'>Reminder</Typography>
                <TextField
                  fullWidth
                  type='time'
                  value={this.state.reminder}
                  onChange={(e) => {
                    this.setState({ reminder: e.target.value })
                  }}
                />
              </Grid>
              <hr style={{ backgroundColor: "gray", height: 30, width: 2 }} />
              <Grid item xs={3.5}>
                <Typography variant='h7'>Call Purpose <span style={{ color: 'red' }}>*</span></Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={this.state.call_purpose}
                  onChange={(e) => {
                    this.setState({ call_purpose: e.target.value })
                  }}
                  placeholder='Enter the purpose'
                />
              </Grid>
              {this.state.deal_status !== "Rejected" &&
                <>
                  <hr style={{ backgroundColor: "gray", height: 30, width: 2 }} />
                  <Grid item xs={3.5}>
                    <Typography variant='h7'>Follow Up <span style={{ color: 'red' }}>*</span></Typography>
                    <TextField
                      fullWidth
                      type='date'
                      value={this.state.follow_up_date}
                      onChange={(e) => {
                        this.setState({ follow_up_date: e.target.value })
                      }}
                    />
                  </Grid>
                </>
              }
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
            <Button
              onClick={() => {
                this.setState({ nextfolloup: false, phone: "", follow_up_date: "", email: "", source: "", name: "", description: "", reminder: "", call_purpose: "", category: "", product_id: "", deal_status: "" });
                this.props.addNewLead(this.props.login.org_id, this.state.phone, this.props.login.user_id, this.state.follow_up_date, this.state.email, this.state.source, this.state.product_id, this.props.login.user_id, this.state.name, this.state.description, this.state.reminder, this.state.call_purpose, this.state.category_id, this.state.product_name, this.state.product_price, this.state.countryname, this.state.statename, this.state.cityname, this.state.address, this.state.pincode, 'New', this.state.row_perpage, this.state.pageNumber, this.props.login.type,)
              }}
              variant='outlined' style={{ borderradius: ' 0.33675rem', border: '1px solid var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'var(--PrimaryColour-G1, #3D3B3B)' }}>
              Skip
            </Button>
            <Button
              // disabled={this.state.description === "" || this.state.reminder === "" || this.state.follow_up_date === "" || this.state.call_purpose}
              onClick={() => {
                if (this.validateFieldsdelstatus()) {
                  this.props.addNewLead(this.props.login.org_id, this.state.phone, this.props.login.user_id, this.state.follow_up_date, this.state.email, this.state.source, this.state.product_id, this.props.login.user_id, this.state.name, this.state.description, this.state.reminder, this.state.call_purpose, this.state.category_id, this.state.product_name, this.state.product_price, this.state.countryname, this.state.statename, this.state.cityname, this.state.address, this.state.pincode, this.state.deal_status, this.state.row_perpage, this.state.pageNumber, this.props.login.type,)
                  this.setState({
                    nextfolloup: false, phone: "", follow_up_date: "", email: "", source: "", name: "", description: "", reminder: "", call_purpose: "", category: "", product_id: "", countryname: "",
                    statename: "",
                    cityname: "", address: "", pincode: "", deal_status: ""
                  });
                }
              }}
              variant='contained' style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white' }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/*----------------------- import -------------------------*/}
        <Dialog
          maxWidth="xs"
          fullWidth
          open={this.state.import}
          onClose={() => {
            this.setState({ import: false });
          }} >
          <DialogTitle display={'flex'} justifyContent={'space-between'} backgroundColor={'lightgray'}>
            <Typography variant='h6'>Upload New Leads</Typography>
            <CloseCircle
              size="28"
              color="black"
              variant="Outline"
              cursor={'pointer'}
              onClick={() => {
                this.setState({ import: false })
              }} />
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} >
              <Grid item xs={12} mt={2}>
                <TextField
                  fullWidth
                  select
                  variant='standard'
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.product_id}
                  onChange={(e) => {
                    this.setState({ product_id: e.target.value })
                  }}
                >
                  <MenuItem value={"N"}>{"Select Product"}</MenuItem>
                  {Array.isArray(this.props.product.view_all_product) && this.props.product.view_all_product.map((item) => (
                    <MenuItem value={item._id}
                      onClick={() => {
                        this.setState({ product_name: item.product_name, product_price: item.product_price })
                      }}>{item.product_name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} mt={1}>
                <TextField
                  fullWidth
                  select
                  variant='standard'
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.category}
                  onChange={(e) => {
                    this.setState({ category: e.target.value })
                  }}
                >
                  <MenuItem value={"N"}>{"Select Category"}</MenuItem>
                  {Array.isArray(this.props.employee.all_category) && this.props.employee.all_category.map((item) => (
                    <MenuItem value={item.cate_name}
                      onClick={() => {
                        this.setState({ category_id: item._id })
                      }}
                    >{item.cate_name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} mt={2}>
                <center>
                  <input style={{ display: "none" }} type="file" id="file" accept=".xls, .xlsx" onChange={this.handleFileUpload} />
                  <label htmlFor="file">
                    <div style={{
                      borderRadius: "48px", height: "60x", width: "36px",
                      background: "var(--TB_BrandColor-Primary-10, #FFF4E8)"
                    }}>
                      <FileUploadIcon />
                    </div>
                  </label>
                  {this.state.mailData.length === 0 ?
                    <Typography style={{ marginTop: "5px", fontSize: "18px", color: "#161412", fontWeight: 500 }}>Click here to upload</Typography>
                    :
                    <Typography style={{ marginTop: "5px", fontSize: "18px", color: "#161412", fontWeight: 500 }}>Selected</Typography>
                  }
                  <Typography style={{ marginTop: "5px", fontSize: "14px", color: "#87817B", fontWeight: 400, }}>(File format: xsl/xlsx format)
                    <Button
                      onClick={this.downloadSampleFile}
                    >Click here to download format</Button></Typography>
                </center>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
            <Button variant="contained"
              disabled={this.state.mailData.length === 0}
              style={{ borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '6.9375rem', padding: '0.33675rem 0.6735rem', color: 'white' }}
              onClick={() => {
                this.props.import_lead(this.state.mailData, this.state.product_name, this.state.product_price, this.props.login.org_id)
                this.setState({ import: false });
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog >


        {/*----------------------- ----------------------- -------------------------*/}
        <Modal
          open={this.state.inbox}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ height: '600px', width: '1112px', position: 'relative', borderRadius: '12px', backgroundColor: '#FFF', boxShadow: '0px 8px 16px 0px #7B7B7B', margin: '60px 0px 0px 245px' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={7} style={{ backgroundColor: '#FFF' }}>
                  <Grid sx={{ marginTop: '95px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '15px' }}>
                    <img style={{ height: '48px', width: '48px', flexShrink: 0, position: 'absolute', top: '30px', left: '25px' }} src={icon2} alt="" />
                    <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                      <Grid style={{ display: 'flex', gap: '10px' }}>
                        <Typography style={{ fontSize: '20px', fontWeight: 500, lineHeight: '26px', color: '#000' }}>Import data from Excel</Typography>
                        <Typography><ChevronRightIcon style={{ color: '#000', flexShrink: 0 }} /></Typography>
                        <Typography style={{ fontSize: '20px', fontWeight: 500, lineHeight: '26px', color: '#000' }}>to CRM</Typography>
                      </Grid>
                      <Typography style={{ fontSize: '14px', fontWeight: 400, lineHeight: '21px', color: '#000' }}>Move data from an excel spreadsheet into an existing CRM Board</Typography>
                    </Grid>
                    <Grid style={{ width: '485px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '199px', border: '1px solid #A3A3A3', backgroundColor: '#FFFFFF' }}>
                      <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', flexDirection: 'column' }}>
                        <input style={{ display: "none" }} type="file" id="file" />
                        <label htmlFor="file">  <Typography style={{ color: '#000', lineHeight: '26px', fontSize: '20px', fontWeight: 500 }}>Drag file here or <span style={{ color: '#2439A9' }}>click to browse</span></Typography></label>
                        <Typography style={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px', fontFamily: 'Lato' }}>All .csv, .xlsx & .xls files are supported</Typography>
                      </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '485px', height: '199px' }}>
                      <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', flexDirection: 'column' }}>
                        <Typography style={{ color: '#000', lineHeight: '26px', fontSize: '20px', fontWeight: 500 }}>Need Help getting started?</Typography>
                        <Typography style={{ color: '#000', fontSize: '16px', lineHeight: '24px', fontWeight: 400 }}><span style={{ color: '#2439A9' }}>Read and Learn</span> about importing into CRM</Typography>
                        <Typography style={{ color: '#000', lineHeight: '26px', fontSize: '20px', fontWeight: 500 }}><span style={{ color: '#2439A9' }}>Download</span> a sample Excel file</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={5} style={{ backgroundColor: '#F2F2F2', position: 'relative', display: 'flex', width: '470px', height: '600px', alignItems: 'center', justifyContent: 'center' }}>
                  <CloseOutlinedIcon onClick={() => this.setState({ inbox: false })} style={{ height: '25px', position: 'absolute', top: '40px', right: '40px', width: '25px', flexShrink: 0 }} />
                  <img style={{ position: 'absolute', left: '76px', height: '100px', width: '100px', backgroundColor: '#FAFAFA', flexShrink: 0, boxShadow: '0px 3.36986px 3.36986px 0px #000', borderRadius: '16.849px' }} src={icon3} alt="" />
                  <img style={{ position: 'absolute', right: '60px', height: '95px', width: '95px', flexShrink: 0, borderRadius: '16.849px', backgroundColor: '#FAFAFA', boxShadow: '0px 3.36986px 3.36986px 0px #000' }} src={icon1} alt="" />
                  <Grid style={{ width: '324px', height: '324px', borderRadius: '324px', border: '1px solid #51DA57', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid style={{ height: "250px", width: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '2px solid #51DA57', strokeWidth: '2px', flexShrink: '0px', borderRadius: '250px' }}>
                      <Grid style={{ height: '170px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '170px', borderRadius: '170px', border: '3px solid #51DA57' }}>
                        <ChevronRightIcon style={{ width: '49px', height: '38', color: '#000' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={this.state.document}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ height: '600px', width: '1112px', position: 'relative', borderRadius: '12px', margin: '60px 0px 0px 245px', backgroundColor: '#FFF', boxShadow: '0px 8px 16px 0px #7B7B7B' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={7} style={{ backgroundColor: '#FFF' }}>
                  <Grid sx={{ marginTop: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '15px' }}>
                    <img style={{ height: '48px', width: '48px', flexShrink: 0, position: 'absolute', top: '30px', left: '25px' }} src={icon2} alt="" />
                    <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                      <Grid style={{ display: 'flex', gap: '10px' }}>
                        <Typography style={{ fontSize: '20px', fontWeight: 500, lineHeight: '26px', color: '#000' }}>Export data from Excel</Typography>
                        <Typography><ChevronRightIcon style={{ color: '#000', flexShrink: 0 }} /></Typography>
                        <Typography style={{ fontSize: '20px', fontWeight: 500, lineHeight: '26px', color: '#000' }}>to CRM</Typography>
                      </Grid>
                      <Typography style={{ fontSize: '14px', fontWeight: 400, lineHeight: '21px', color: '#000' }}>Move data from an excel spreadsheet into an existing CRM Board</Typography>
                    </Grid>
                    <Grid style={{ width: '485px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '199px', border: '1px solid #A3A3A3', backgroundColor: '#FFFFFF' }}>
                      <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', flexDirection: 'column' }}>
                        <input style={{ display: "none" }} type="file" id="file" accept=".xlsx" onChange={(e) => { this.setState({ excel_url: e.target.files[0] }) }} />
                        <label htmlFor="file">  <Typography style={{ color: '#000', lineHeight: '26px', fontSize: '20px', fontWeight: 500 }}>Drag file here or <span style={{ color: '#2439A9' }}
                        >click to browse</span></Typography></label>
                        <Typography style={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px', fontFamily: 'Lato' }}>All .csv, .xlsx & .xls files are supported</Typography>
                      </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '485px', height: '180px' }}>
                      <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', flexDirection: 'column' }}>
                        <Typography style={{ color: '#000', lineHeight: '26px', fontSize: '20px', fontWeight: 500 }}>Need Help getting started?</Typography>
                        <Typography sx={{ color: '#000', fontSize: '16px', lineHeight: '24px', fontWeight: 400 }}><span style={{ color: '#2439A9' }}>Read and Learn</span> about importing into CRM</Typography>
                        <Typography sx={{ color: '#000', lineHeight: '26px', fontSize: '20px', fontWeight: 500 }}><span style={{ color: '#2439A9' }}>Download</span> a sample Excel file</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={5} style={{ backgroundColor: '#F2F2F2', position: 'relative', display: 'flex', width: '470px', height: '600px', alignItems: 'center', justifyContent: 'center' }}>
                  <CloseOutlinedIcon onClick={() => this.setState({ document: false })} style={{ height: '25px', position: 'absolute', top: '40px', right: '40px', width: '25px', flexShrink: 0 }} />
                  <img style={{ position: 'absolute', left: '76px', height: '100px', width: '100px', backgroundColor: '#FAFAFA', flexShrink: 0, boxShadow: '0px 3.36986px 3.36986px 0px #000', borderRadius: '16.849px' }} src={icon3} alt="" />
                  <img style={{ position: 'absolute', right: '60px', height: '95px', width: '95px', flexShrink: 0, borderRadius: '16.849px', backgroundColor: '#FAFAFA', boxShadow: '0px 3.36986px 3.36986px 0px #000' }} src={icon1} alt="" />
                  <Grid style={{ width: '324px', height: '324px', borderRadius: '324px', border: '1px solid #51DA57', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid style={{ height: "250px", width: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '2px solid #51DA57', strokeWidth: '2px', flexShrink: '0px', borderRadius: '250px' }}>
                      <Grid style={{ height: '170px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '170px', borderRadius: '170px', border: '3px solid #51DA57' }}>
                        <ChevronRightIcon style={{ width: '49px', height: '38', color: '#000' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>

        <Dialog open={this.state.deleteleadadmin}>
          <Grid style={{ height: "210px", width: "300px" }}>
            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10, }}>
              <CloseOutlinedIcon
                onClick={() => { this.setState({ deleteleadadmin: false }); }}
                style={{ fontSize: 20, color: "#000", cursor: "pointer", marginLeft: "250px", marginTop: "3%", }} />
            </Grid>
            <Typography style={{ textAlign: "center", marginTop: "5%", color: "#000", fontWeight: 600 }}>Are you sure you want to delete ?</Typography>
            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "10%", gap: "10px" }}>
              <Button
                onClick={() => { this.setState({ deleteleadadmin: false }); }}
                variant='outlined' style={{ borderColor: "gray", color: "gray" }}>Yes</Button>
              <Button
                onClick={() => { this.setState({ deleteleadadmin: false }); }}
                variant='contained' style={{ backgroundColor: "gray", color: "#fff", marginRight: "4%" }}>No</Button>
            </Grid>
          </Grid>
        </Dialog>
        {/*----------------------- ----------------------- -------------------------*/}

        <LoaderCon />
      </Grid>
    )
  }
}
