import {
    VIEW_ALL_ALLOTMENT,
    VIEW_ALLOTMENT_BY_ID,
    VIEW_ALLOTMENT_BY_ORG_ID,
    VIEW_ALL_ALLOTMENT_WITH_DETAILS,
    VIEW_ALL_ALLOTMENT_BY_ORG_THROUGH_STATUS,
    FILTER_ALLOTMENT,
    VIEW_ALL_LEADS_WITH_SEARCH,
    CLEAR_SELECT
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import 'firebase/storage';
import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";


// -------------------ADD allortment---------------------

export function addAllortment(org_id, user_id, assign_to, leads, due_date, priority, allortment_desc, status) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_allortment", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,

            },
            body: JSON.stringify({
                assign_to: assign_to,
                assign_by: user_id,
                leads: leads,
                due_date: due_date,
                priority: priority,
                allortment_desc: allortment_desc,
                status: status
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllotmentByOrg_id(org_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// --------------------update allotment---------------------
export function updateAllortment(allortment_id, manager_id, leads, due_date, allortment, priority, allortment_desc, status) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_allortment", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                allortment_id: allortment_id
            },
            body: JSON.stringify({
                manager_id: manager_id,
                leads: leads,
                due_date: due_date,
                allortment: allortment,
                priority: priority,
                allortment_desc: allortment_desc,
                status: status
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllAllotment());
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// -------------------view all allotment-----------------
export function viewAllAllotment() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_allortment",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",

                },

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_ALLOTMENT, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_ALLOTMENT, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// -----------------view allotment by id--------------
export function viewAllotmentById(allortment_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + " view_allortment_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                allortment_id: allortment_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALLOTMENT_BY_ID, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALLOTMENT_BY_ID, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
// ------------------view allotment by org_id------------------
export function viewAllotmentByOrg_id(org_id, page_number, rowper_page) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_allortment_by_org", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                org_id: org_id,
                page_number: page_number,
                rowper_page: rowper_page
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALLOTMENT_BY_ORG_ID, payload: responseJson.result, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALLOTMENT_BY_ORG_ID, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// -----------------delete allotment----------------
export function deleteAllotment(org_id, allortment_id) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_allortment", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                allortment_id: allortment_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllotmentByOrg_id(org_id));

                    dispatch(unsetLoader());
                } else {

                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// ----------------view allotment with detailss-------------
export function viewAllAllotmentWithDetails() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_allortment_with_detais",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_ALLOTMENT_WITH_DETAILS, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_ALLOTMENT_WITH_DETAILS, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// ------------------
export function viewAllotmentByOrgThroughStatus(status, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + " view_allortment_by_org_through_status",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    status: status,
                    org_id: org_id
                }),

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_ALLOTMENT_BY_ORG_THROUGH_STATUS, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_ALLOTMENT_BY_ORG_THROUGH_STATUS, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function filterAllotmentByProgress(all_allotment_by_org_id, e, type) {
    return (dispatch) => {

        if (e === "") {
            dispatch({ type: FILTER_ALLOTMENT, payload: all_allotment_by_org_id });
        }
        else if (type === "progress") {
            const newArray = all_allotment_by_org_id.filter((el) => {
                return (el.status.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: FILTER_ALLOTMENT, payload: newArray })
        }
        else if (type === "date") {
            const newArray = all_allotment_by_org_id.filter((el) => {

                return ((el.due_date).toString().includes((e).toString()));
            })
            dispatch({ type: FILTER_ALLOTMENT, payload: newArray })
        }
    };
}


export function searchDeals(org_id, country, state, city, product, category, status, executive_id) {
    console.log(org_id, country, state, city, product, category, status, executive_id);
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "search_deals", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // org_id: org_id,
            },
            body: JSON.stringify({  
                category: category,
                status: status,
                executive_id: executive_id,
                country: country,
                state: state,
                product: product,
                city: city

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    var Arr = responseJson.result.map((item, index) => ({ _id: item._id, leadDetails: item.leadDetails, checked: false, allChecked: false }))
            
                    dispatch({ type: VIEW_ALL_LEADS_WITH_SEARCH, payload: Arr, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_LEADS_WITH_SEARCH, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function checkedLead(data, index) {
    var payload = data;
    payload[index].checked = !payload[index].checked
    return {
        type: VIEW_ALL_LEADS_WITH_SEARCH,
        payload: payload,
    };
}
export function allSelect(data) {
    var payload = data.map((item, index) => ({ _id: item._id, leadDetails: item.leadDetails, checked: !item.allChecked, allChecked: !item.allChecked }))
    return {
        type: VIEW_ALL_LEADS_WITH_SEARCH,
        payload: payload,
    };
}
export function search_country(org_id, countryname, statename, cityname) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_lead_with_search", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
            },
            body: JSON.stringify({
                countryname: countryname,
                statename: statename,
                cityname: cityname

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_LEADS_WITH_SEARCH, payload: responseJson.result, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_LEADS_WITH_SEARCH, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function clearSelect(payload) {
    return {
        type: CLEAR_SELECT,
        payload: payload,
    };
}




