export const SET_USER_DETAILS="SET_ORG_DETAILS";
export const SET_ORG_DETAILS2="SET_ORG_DETAILS2";

export const SET_NAME="SET_NAME";
export const SET_EMAIL_ID="SET_EMAIL_ID";
export const SET_PHONE="SET_PHONE";
export const SET_TYPE="SET_TYPE";

export const SET_NEW_ORG_DETAILS="SET_NEW_ORG_DETAILS";
export const SET_EDIT_ORG_NAME="SET_EDIT_ORG_NAME";
export const SET_EDIT_ORG_EMAIL="SET_EDIT_ORG_EMAIL";
export const SET_EDIT_ORG_PHONE="SET_EDIT_ORG_PHONE";
export const SET_EDIT_ORG_GSTIN_NO="SET_EDIT_ORG_GSTIN_NO";