import {
  Box, Grid, Button, Divider, Alert, IconButton, DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import React, { Component } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import '../../employee/Components/employee.css'
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import "../../employee/Components/empdet.css";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import validator from 'validator';
import InputBase from "@mui/material/InputBase";
import Pagination from "@mui/material/Pagination";
import { SearchNormal, Profile2User, People, Add, Edit2, Trash, CloseCircle } from 'iconsax-react';
import { Search as SearchIcon } from '@material-ui/icons';

export default class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.login.type === "Owner" ? '1' : '2',
      checked: false,
      addmodal: false,
      editm: false,
      delem: false,
      deltab: false,
      deltabexe: false,
      delAfter: false,
      visibilitym: false,
      transferData: false,
      category: '',
      selectedValue: 'a',
      transferExeData: false,
      delExeAfter: false,
      editexecutive: false,
      addexe: false,
      editexe: false,
      delexe: false,
      visibilityexe: false,
      employeedeatils: false,
      Adminemployeedelete: false,
      empdeletemod: false,
      visibilityexecutive: false,
      role: "N",
      admin_id: "",
      org_id: "",
      fullname: "",
      email: "",
      mobile: "",
      employeeID: "",
      employee_id: "",
      manager_id: "",
      isEmail: false,
      verificationStatus: '',
      isValidEmail: true,
      tdata: [
        {
          empname: "Kristin Watson",
          empid: "EMP123456",
          empmail: "abc@gmail.com",
          empcat: "Order",
        },
        {
          empname: "Kristin Watson",
          empid: "EMP123456",
          empmail: "abc@gmail.com",
          empcat: "Order",
        },
        {
          empname: "Kristin Watson",
          empid: "EMP123456",
          empmail: "abc@gmail.com",
          empcat: "Order",
        },
        {
          empname: "Kristin Watson",
          empid: "EMP123456",
          empmail: "abc@gmail.com",
          empcat: "Order",
        },
        {
          empname: "Kristin Watson",
          empid: "EMP123456",
          empmail: "abc@gmail.com",
          empcat: "Order",
        },
        {
          empname: "Kristin Watson",
          empid: "EMP123456",
          empmail: "abc@gmail.com",
          empcat: "Order",
        },
      ],
      tdataexe: [
        {
          exeempname: "Huisng Watson",
          exeempid: "EMP123456",
          exeempmail: "abc@gmail.com",
          exeempcat: "Order",
        },
        {
          exeempname: "Huisng Watson",
          exeempid: "EMP123456",
          exeempmail: "abc@gmail.com",
          exeempcat: "Order",
        },
        {
          exeempname: "Kristin Watson",
          exeempid: "EMP123456",
          exeempmail: "abc@gmail.com",
          exeempcat: "Order",
        },
      ],
      page: 1,
      setPage: 1,
      rowsPerPage: 10,
      category_id: "",
      type: "",
      searchText: "",
      errors: {},
    };
    this.timer = null;
  }
  handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.setState({
      email: enteredEmail,
      isValidEmail: emailRegex.test(enteredEmail),
    });
  };

  componentWillUnmount() {
    // Clear the timer if component unmounts
    clearTimeout(this.timer);
  }

  handleInputChange = (e) => {
    const searchText = e.target.value;
    this.setState({ searchText });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (searchText.trim() === '') {
        this.fetchAllData();
      } else {
        // if (this.props.employee.admin_user.some(item => item.access.type === "M")) {
        this.props.viewProductUser(
          this.props.login.org_id,
          this.props.login.product,
          this.props.login.user_id,
          this.props.login.token,
          "M",
          this.state.page,
          this.state.rowsPerPage,
          searchText
        );
        // } else {
        this.props.viewExecutiveUser(
          this.props.login.org_id,
          this.props.login.product,
          this.props.login.user_id,
          this.props.login.token,
          "E",
          this.state.page,
          this.state.rowsPerPage,
          searchText
        );
        // }
      }
    }, 500);
  };

  fetchAllData = () => {
    // if (this.props.employee.admin_user.some(item => item.access.type === "M")) {
    this.props.viewProductUser(
      this.props.login.org_id,
      this.props.login.product,
      this.props.login.user_id,
      this.props.login.token,
      "M",
      this.state.page,
      this.state.rowsPerPage,
      ""
    );
    // } else {
    this.props.viewExecutiveUser(
      this.props.login.org_id,
      this.props.login.product,
      this.props.login.user_id,
      this.props.login.token,
      "E",
      this.state.page,
      this.state.rowsPerPage,
      ""
    );
    // }
  };

  handleChangecategory = (event) => {
    this.setState({ category: event.target.value });
  };

  handleChange1 = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangecheck = (event) => {
    this.setState({ checked: event.target.checked });
  };
  handleChangeradio = (event) => {
    this.setState({
      selectedValue: event.target.value,
    });
  };

  componentDidMount() {
    this.props.viewProductUser(this.props.login.org_id, this.props.login.product, this.props.login.user_id, this.props.login.token, "M", this.state.page, this.state.rowsPerPage, "")
    this.props.viewAllCategory(this.props.login.org_id);
    console.log(this.props.employee.admin_user, "this.props.login.user_id,");
    this.props.viewExecutiveUser(this.props.login.org_id, this.props.login.product, this.props.login.user_id, this.props.login.token, "E", this.state.page,
      this.state.rowsPerPage,);

  }

  validateEmail = () => {
    const { email } = this.state;
    const isValid = validator.isEmail(email);
    this.setState({ isEmail: isValid });

  };

  validateFields = () => {
    const errors = {};
    // if (!this.state.fullname) errors.fullname = "Fullname is required";
    if (!this.state.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
      errors.email = "Email is invalid";
    }
    // if (!this.state.mobile) {
    //   errors.mobile = "Mobile number is required";
    // } else if (!/^\d{10}$/.test(this.state.mobile)) {
    //   errors.mobile = "Mobile number must be 10 digits";
    // }
    // if (!this.state.employeeID) errors.employeeID = "Employee Id is required";
    if (!this.state.role) errors.role = "Role is required";
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };
  validateFieldsexe = () => {
    const errors = {};
    if (!this.state.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
      errors.email = "Email is invalid";
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleSave = () => {
    if (this.validateFields()) {
      this.setState({
        addmodal: false,
        // fullname: "",
        email: "",
        // mobile: "",
        employeeID: "",
        role: "",
        category_id: "",
        errors: {}
      });
      this.props.addManager(
        this.props.login.user_id,
        this.props.login.org_id,
        // this.state.fullname,
        this.state.email,
        // this.state.mobile,
        // this.state.employeeID,
        this.state.category_id,
        this.props.login.product,
        this.props.login.token,
        this.state.page,
        this.state.rowsPerPage
      );
    }
  };

  handleChange = (field) => (e) => {
    this.setState({ [field]: e.target.value, errors: { ...this.state.errors, [field]: "" } });
  };

  render() {
    const { category, errors, addmodal } = this.state;
    const { isValidEmail } = this.state;
    const handleChangePage = (event, newPage) => {
      if (this.props.login.type === "Owner" || this.props.login.type === "A") {
        if (this.props.employee.admin_user?.filter(item => item?.type === "M")) {
          console.log();
          this.props.viewProductUser(this.props.login.org_id, this.props.login.product, this.props.login.user_id, this.props.login.token, "M", newPage - 1,
            this.state.rowsPerPage,);

        } else {
          this.props.viewExecutiveUser(this.props.login.org_id, this.props.login.product, this.props.login.user_id, this.props.login.token, "E", newPage - 1,
            this.state.rowsPerPage,);

        }
      } else if (this.props.login.type === "M") {
        this.props.viewExecutiveUser(this.props.login.org_id, this.props.login.product, this.props.login.user_id, this.props.login.token, "E", newPage - 1,
          this.state.rowsPerPage)

      }
      this.setState({
        page: newPage,
      });
    };
    return (
      <Grid>
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid style={{ display: "flex", gap: '5%' }}>
            {this.props.login.type === "Owner" &&
              <Card sx={{ width: "240px", borderRadius: "10px", boxShadow: "2px 2px 8px 0px #66666659" }}>
                <CardActions>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={5}>
                      <div style={{
                        backgroundColor: '#EDD0FF',
                        borderRadius: '50%',
                        width: '70px',
                        height: '70px',
                        padding: '20px',
                      }}>
                        <Profile2User
                          size="32"
                          color="#9E37DD"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Typography style={{ fontSize: "16px", color: "#000000", fontWeight: 400 }}>
                        Total Managers<br />
                        <b>{Array.isArray(this.props.employee?.admin_user) ? this.props.employee?.admin_user?.filter(item => item?.type === "M")?.length : 0}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            }
            <Card sx={{ width: "240px", borderRadius: "10px", boxShadow: "2px 2px 8px 0px #66666659" }}>
              <CardActions>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={5}>
                    <div style={{
                      backgroundColor: '#C7FFA5',
                      borderRadius: '50%',
                      width: '70px',
                      height: '70px',
                      padding: '20px',
                    }}>
                      <People
                        size="32"
                        color="#33922B"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Typography style={{ fontSize: "16px", color: "#000000", fontWeight: 400 }}>
                      Total Executives<br />
                      <b>{Array.isArray(this.props.employee.executive_user) ? this.props.employee.executive_user?.filter(item => item?.type === "E").length : 0}</b>
                    </Typography>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Paper
            component="form"
            style={{
              width: '21.375rem',
              height: '2.5rem',
              gap: '0.625rem',
              borderRadius: '1.25rem',
              border: '1px solid var(--PrimaryColour-G7, #B8B8B8)'
            }}
          >
            <IconButton type="button" style={{ padding: "15px" }} aria-label="search">
              <SearchIcon
                style={{ color: 'var(--PrimaryColour-G7, #B8B8B8)', marginTop: "-5px" }}
              />
            </IconButton>
            <InputBase
              className='search-text'
              placeholder="Search"
              inputProps={{ "aria-label": "Search" }}
              onChange={this.handleInputChange}
            />
          </Paper>
        </Grid>
        <Box sx={{ marginTop: "2%" }}>
          <TabContext value={this.state.value}>
            <Box sx={{}}>
              <TabList onChange={this.handleChange1} aria-label="lab API tabs example">
                {(this.props.login.type === "Owner" || this.props.login.type === "A") &&

                  <Tab
                    onClick={(e) => {
                      this.props.viewProductUser(this.props.login.org_id, this.props.login.product, this.props.login.user_id, this.props.login.token, "M", this.state.page, this.state.rowsPerPage, "")
                    }} style={{ fontSize: "20px", fontWeight: 500 }} label="Managers" value="1" />
                }
                <Tab
                  onClick={(e) => {
                    this.props.viewExecutiveUser(this.props.login.org_id, this.props.login.product, this.props.login.user_id, this.props.login.token, "E", this.state.page,
                      this.state.rowsPerPage,);
                  }}
                  style={{ fontSize: "20px", fontWeight: 500 }} label="Executives" value="2" />
              </TabList>
            </Box>

            {this.props.login.type === "Owner" &&
              <TabPanel value="1">
                <FormControl sx={{ marginTop: '-6%', float: 'right' }}>
                  <Add
                    size="24px"
                    color="#525050"
                    variant="Bold"
                    cursor={'pointer'}
                    onClick={() => {
                      this.setState({
                        addmodal: true,
                      });
                    }}
                  />
                </FormControl>
                <TableContainer component={Paper} style={{ borderRadius: '0px' }}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead style={{ background: "lightgray" }} >
                      <TableRow>
                        {/* <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Employee Name</TableCell> */}
                        {/* <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Mobile</TableCell> */}
                        <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Email ID</TableCell>
                        <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(this.props.employee?.search_manager) && this.props.employee.search_manager?.filter(item => item?.type === "M")?.map((item) => {
                        // console.log(this.props.employee.admin_user, "");
                        return (
                          <TableRow hover={true} style={{ cursor: "pointer" }}>
                            {/* <a href="/Employeedetail" className='table-row' style={{ display: "contents", textDecoration: "none", cursor: "pointer" }}
                              onClick={() => {
                                this.props.setManagerId(item._id);
                                this.props.setManagerName(item);
                              }}
                            > */}
                            {/* <TableCell style={{ textAlign: "center" }}>{item.name}</TableCell> */}
                            {/* <TableCell style={{ textAlign: "center" }}>{item.mobile}</TableCell> */}
                            <TableCell style={{ textAlign: "center" }}>{item.email_id}</TableCell>
                            {/* <TableCell style={{ textAlign: "center", fontSize: "18px" }}>
                                <Typography style={{ fontSize: "16px" }}>{item.role}</Typography>
                              </TableCell> */}
                            {/* </a> */}
                            <TableCell style={{ justifyContent: "center" }}>
                              <Grid style={{ display: "flex", justifyContent: 'space-evenly' }}>
                                {/* <Edit2
                                  size="24px"
                                  color="#525050"
                                  variant="Outline"
                                  cursor={'pointer'}
                                  onClick={() => {
                                    this.setState({
                                      editm: true,
                                      employee_id: item._id,
                                      name: item.name,
                                      email_id: item.email_id,
                                      mobile: item.mobile,
                                    })
                                  }}
                                /> */}
                                {/* <VisibilityOutlinedIcon styled={{ color: "blue" }}
                                  onClick={() => {
                                    this.setState({
                                      visibilitym: true,
                                      manager_id: item._id,
                                      name: item.name,
                                      email_id: item.email_id,
                                      mobile: item.mobile,
                                      employeeID: item.employeeID !== undefined ? item.employeeID : item.emp_id,
                                      // role: item.role
                                    })
                                  }}
                                /> */}
                                <Trash
                                  size="24px"
                                  color="#525050"
                                  variant="Outline"
                                  cursor={'pointer'}
                                  onClick={() => {
                                    this.setState({
                                      empdeletemod: true,
                                      _id: item._id,
                                      type: "M"
                                    })
                                  }}
                                />
                              </Grid>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>

            }

            <TabPanel value="2">
              <FormControl sx={{ marginTop: '-6%', float: 'right' }}>
                <Add
                  size="24px"
                  color="#525050"
                  variant="Bold"
                  cursor={'pointer'}
                  onClick={() => {
                    this.setState({
                      addexe: true,
                    });
                  }}
                />
              </FormControl>
              <TableContainer component={Paper} style={{ borderRadius: '0px' }}>
                <Table size="small" aria-label="a dense table">
                  <TableHead style={{ background: "lightgray" }}>
                    <TableRow>
                      {/* <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}> Employee Name</TableCell> */}
                      {/* <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Mobile</TableCell> */}
                      <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Email ID</TableCell>
                      <TableCell style={{ color: "#3D3B3B", textAlign: "center" }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {Array.isArray(this.props.employee?.search_executives) && this.props.employee.search_executives?.filter(item => item?.type === "E")?.map((item) => {
                    return (
                      <TableBody>
                        <TableRow hover={true} style={{ cursor: "pointer" }}>
                          {/* <a href="/Executivedetails" className='table-row' style={{ display: "contents", textDecoration: "none", cursor: "pointer" }}
                            onClick={() => {
                              // this.props.setManagerId(item._id);
                              this.props.setExecutiveDetails(item);
                            }}
                          > */}
                          {/* <TableCell style={{ textAlign: "center" }}>{item.name}</TableCell> */}
                          {/* <TableCell style={{ textAlign: "center" }}>{item.mobile}</TableCell> */}
                          <TableCell style={{ textAlign: "center" }}>{item.email_id}</TableCell>
                          {/* <TableCell style={{ textAlign: "center", fontSize: "18px" }}>
                              <Typography style={{ fontSize: "16px" }}>{item.role}</Typography>
                            </TableCell> */}
                          {/* </a> */}
                          <TableCell style={{ justifyContent: "center" }}>
                            <Grid style={{ display: "flex", justifyContent: 'space-evenly' }}>
                              {/* <Edit2
                                size="24px"
                                color="#525050"
                                variant="Outline"
                                cursor={'pointer'}
                                onClick={() => {
                                  this.setState({
                                    editm: true,
                                    employee_id: item._id,
                                    name: item.name,
                                    email_id: item.email_id,
                                    mobile: item.mobile,
                                  })
                                }}
                              /> */}
                              {/* <VisibilityOutlinedIcon styled={{ color: "blue", cursor: "pointer" }}
                                onClick={() => {
                                  this.setState({
                                    visibilityexecutive: true,
                                    executive_id: item._id,
                                    name: item.name,
                                    email_id: item.email_id,
                                    mobile: item.mobile,
                                    employeeID: item.employeeID !== undefined ? item.employeeID : item.emp_id,
                                  })
                                }}
                              /> */}
                              <Trash
                                size="24px"
                                color="#525050"
                                variant="Outline"
                                cursor={'pointer'}
                                onClick={() => {
                                  this.setState({
                                    empdeletemod: true,
                                    _id: item._id,
                                    type: 'E',
                                  })
                                }}
                              />
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </Table>
              </TableContainer>
            </TabPanel>
            <Pagination
              style={{ display: "flex", justifyContent: "flex-end", padding: '1%' }}
              count={
                this.props.employee.search_executives?.filter(item => item?.type === "E")?.length > 0
                  ? Math.ceil(this.props.employee.search_executives.length / 10)
                  : Math.ceil(this.props.employee.admin_user.length / 10)
              }
              size="small"
              className='pagination'
              onChange={handleChangePage}
            />

          </TabContext>
        </Box>

        {/*--------------------------------------add Manager--------------------------------*/}
        <Dialog maxWidth="xs" fullWidth open={addmodal}>
          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#D9D9D9', marginBottom: '10px' }}>
            <Typography variant="h6">Add Details of Manager</Typography>
            <CloseCircle
              size="24px"
              variant="Outline"
              cursor="pointer"
              onClick={() => {
                this.setState({ addmodal: false })
                this.setState({
                  addmodal: false,
                  // fullname: "",
                  email: "",
                  // mobile: "",
                  // employeeID: "",
                  role: "",
                  category_id: "",
                  errors: {}
                });
              }}
              className="eventcross-icon"
            />
          </DialogTitle>
          <DialogContent>
            {/* <Grid container spacing={2} marginTop="0.2%"> */}
              {/* <Grid item xs={3.5}>
                <Typography>User Name <span style={{ color: 'red' }}>*</span> - </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  type="text"
                  fullWidth
                  id="fullname"
                  variant="standard"
                  onChange={this.handleChange('fullname')}
                  error={!!errors.fullname}
                  helperText={errors.fullname}
                />
              </Grid>
            </Grid> */}
            <Grid container spacing={2} marginTop="0.2%">
              <Grid item xs={3.5}>
                <Typography>Email id <span style={{ color: 'red' }}>*</span> - </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  type="email"
                  fullWidth
                  id="email"
                  variant="standard"
                  onChange={this.handleChange('email')}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
            </Grid>
            {/* <Grid container spacing={2} marginTop="0.2%">
              <Grid item xs={3.5}>
                <Typography>Phone No <span style={{ color: 'red' }}>*</span> - </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  type="number"
                  fullWidth
                  id="mobile"
                  variant="standard"
                  onChange={this.handleChange('mobile')}
                  error={!!errors.mobile}
                  helperText={errors.mobile}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} marginTop="0.2%">
              <Grid item xs={3.5}>
                <Typography>Employee id <span style={{ color: 'red' }}>*</span>- </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  type="text"
                  fullWidth
                  id="employeeID"
                  variant="standard"
                  onChange={this.handleChange('employeeID')}
                  error={!!errors.employeeID}
                  helperText={errors.employeeID}
                />
              </Grid>
            </Grid> */}
            <Grid container spacing={2} marginTop="0.2%">
              <Grid item xs={3.5}>
                <Typography>Category <span style={{ color: 'red' }}>*</span> - </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  select
                  fullWidth
                  id="role"
                  variant="standard"
                  onChange={this.handleChange('role')}
                  value={this.state.role || 'N'}
                  error={!!errors.role}
                  helperText={errors.role}
                >
                  <MenuItem value="N">Select Category</MenuItem>
                  {Array.isArray(this.props.employee.all_category) && this.props.employee.all_category.map((item) => (
                    <MenuItem
                      key={item._id}
                      value={item.cate_name}
                      onClick={() => this.setState({ category_id: item._id })}
                    >
                      {item.cate_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              variant="contained"
              style={{ borderRadius: '0.33675rem', background: '#3D3B3B', width: '148px', padding: '0.33675rem 0.6735rem', color: 'white' }}
              onClick={this.handleSave}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/*--------------------------------------add executive--------------------------------*/}
        <Dialog
          maxWidth="xs"
          fullWidth
          open={this.state.addexe} >
          <DialogTitle display={'flex'} justifyContent={'space-between'} backgroundColor={'#D9D9D9'} marginBottom={'10px'}>
            <Typography variant="h6">Add Details of Executive</Typography>
            <CloseCircle
              size="24px"
              variant="Outline"
              cursor={'pointer'}
              onClick={() => {
                this.setState({ addexe: false });
                this.setState({
                  // fullname: "",
                  email: "",
                  // mobile: "",
                  // employeeID: "",
                  // role: ""
                })
              }}
              className="eventcross-icon"
            />
          </DialogTitle>
          <DialogContent>
            {/* <Grid container spacing={2} marginTop={'0.2%'}>
              <Grid item xs={3.5}>
                <Typography>User Name <span style={{ color: 'red' }}>*</span> - </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  type="text"
                  fullWidth
                  id="standard-basic"
                  variant="standard"
                  onChange={this.handleChange('fullname')}
                  error={!!errors.fullname}
                  helperText={errors.fullname}
                />
              </Grid>
            </Grid> */}
            <Grid container spacing={2} marginTop={'0.2%'}>
              <Grid item xs={3.5}>
                <Typography>Email id <span style={{ color: 'red' }}>*</span> - </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  type="email"
                  fullWidth
                  id="standard-basic"
                  variant="standard"
                  onChange={this.handleChange('email')}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
            </Grid>
            {/* <Grid container spacing={2} marginTop={'0.2%'}>
              <Grid item xs={3.5}>
                <Typography>Phone No <span style={{ color: 'red' }}>*</span> - </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  type="number"
                  fullWidth
                  id="standard-basic"
                  variant="standard"
                  onChange={this.handleChange('mobile')}
                  error={!!errors.mobile}
                  helperText={errors.mobile}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={'0.2%'}>
              <Grid item xs={3.5}>
                <Typography>Employee id <span style={{ color: 'red' }}>*</span>- </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  type="text"
                  fullWidth
                  id="standard-basic"
                  variant="standard"
                  onChange={this.handleChange('employeeID')}
                  error={!!errors.employeeID}
                  helperText={errors.employeeID}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={'0.2%'}>
              <Grid item xs={3.5}>
                <Typography>Category <span style={{ color: 'red' }}>*</span> - </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  select
                  fullWidth
                  id="standard-basic"
                  variant="standard"
                  onChange={this.handleChange('role')}
                  value={this.state.role || 'N'}
                  error={!!errors.role}
                  helperText={errors.role}
                >
                  <MenuItem value={"N"}>{"Select Category"}</MenuItem>
                  {Array.isArray(this.props.employee.all_category) && this.props.employee.all_category.map((item) => (
                    <MenuItem
                      key={item._id}
                      value={item.cate_name}
                      onClick={() => this.setState({ category_id: item._id })}
                    >
                      {item.cate_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid> */}
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button variant='contained'
              style={{
                borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '148px', padding: '0.33675rem 0.6735rem', color: 'white'
              }}
              // disabled={this.state.email === "" || this.state.mobile === "" || this.state.role === ""}
              onClick={() => {
                if (this.validateFieldsexe()) {
                  this.setState({
                    addexe: false,
                    // fullname: "",
                    email: "",
                    // mobile: "",
                    // employeeID: "",
                    // role: ""
                  })
                  this.props.addExecutive(this.props.login.org_id, this.props.login.user_id, this.state.email,  this.props.login.product, this.props.login.token, this.state.page, this.state.rowsPerPage)
                }
               
              }}
            >
              Save
            </Button>
          </DialogActions>



          {/* <Grid style={{ height: "370px", width: "450px" }}>
            <Box style={{ textAlign: "center" }}>
              <Typography style={{ fontSize: "20px", fontWeight: "600", marginTop: "5%" }}>Details of Manager</Typography>
              <Grid style={{ marginTop: "5%" }}>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography style={{}}>User Name -</Typography>
                  <TextField style={{ marginLeft: "2%", width: "300px" }} id="standard-basic" variant="standard"
                    onChange={(text) => this.setState({ fullname: text.target.value })}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Email id <span style={{ color: 'red' }}>*</span> -</Typography>
                  <TextField id="standard-basic" type="email" variant="standard" style={{ width: "300px", marginLeft: "25px", marginTop: "-7px" }}
                    onChange={(e) => {
                      this.setState({ email: e.target.value })
                      this.handleEmailChange(e);
                    }}
                  />
                </Grid>
                {!isValidEmail && <div style={{ color: 'red', fontSize: "10px" }}>Invalid email format</div>}
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Phone No <span style={{ color: 'red' }}>*</span> -</Typography>
                  <TextField id="standard-basic" type="number" variant="standard" style={{ marginLeft: "17px", marginTop: "-7px", width: "300px" }}
                    onChange={(text) => this.setState({ mobile: text.target.value })}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography style={{ marginLeft: "10px" }}>Employee id-</Typography>
                  <TextField id="standard-basic" variant="standard" style={{ marginLeft: "10px", marginTop: "-7px", width: "300px" }}
                    onChange={(text) => this.setState({ employeeID: text.target.value })}
                  />
                </Grid>

                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography style={{ marginLeft: "-20px" }}>Category <span style={{ color: 'red' }}>*</span> -</Typography>
                  <TextField
                    style={{ width: '256px', marginLeft: "40px" }}
                    id="outlined-select-currency-native"
                    select
                    value={this.state.role}
                    onChange={(e) => {
                      this.setState({ role: e.target.value });
                    }}
                  >
                    <MenuItem value={"N"}>{"Select Category"}</MenuItem>
                    {Array.isArray(this.props.employee.all_category) && this.props.employee.all_category.map((item) => (
                      <MenuItem value={item.cate_name}
                        onClick={() => {
                          this.setState({ category_id: item._id })
                        }}
                      >{item.cate_name}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid style={{ marginTop: "6%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Button
                    onClick={() => { this.setState({ addmodal: false }); }}
                    variant='outlined' style={{ border: "1px solid gray", color: "gray" }}>cancel</Button>
                  <Button
                    variant='contained' style={{ marginLeft: "20px", borderColor: "1px solid gray", color: "white" }} className='create-btn'
                    disabled={this.state.email === "" || this.state.mobile === "" || this.state.role === ""}
                    onClick={() => {
                      if (this.state.isValidEmail) {
                        this.setState({
                          addmodal: false,
                          fullname: "",
                          email: "",
                          mobile: "",
                          employeeID: "",
                          role: ""
                        })
                        this.props.addManager(this.props.login.user_id, this.props.login.org_id, this.state.fullname, this.state.email, this.state.mobile, this.state.employeeID, this.state.category_id, this.state.page, this.state.rowsPerPage)
                        this.handleSave();
                      }
                      else {
                        Alert('Invalid mail')
                      }
                    }}
                  >
                    save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid> */}
        </Dialog>

        {/* -------------------------------------edit------------------------------- */}
        <Dialog
          maxWidth="xs"
          fullWidth
          open={this.state.editm} >
          <DialogTitle display={'flex'} justifyContent={'space-between'} backgroundColor={'#D9D9D9'} marginBottom={'10px'}>
            <Typography variant="h6">Edit Details of Employee</Typography>
            <CloseCircle
              size="24px"
              variant="Outline"
              cursor={'pointer'}
              onClick={() => {
                this.setState({ editm: false });
              }}
              className="eventcross-icon"
            />
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} marginTop={'0.2%'}>
              <Grid item xs={3.5}>
                <Typography>User Name - </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  type="text"
                  fullWidth
                  id="standard-basic"
                  variant="standard"
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                  value={this.state.name}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={'0.2%'}>
              <Grid item xs={3.5}>
                <Typography>Email id <span style={{ color: 'red' }}>*</span> - </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  type="email"
                  fullWidth
                  id="standard-basic"
                  variant="standard"
                  onChange={(e) => {
                    this.setState({ email_id: e.target.value });
                  }}
                  value={this.state.email_id}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={'0.2%'}>
              <Grid item xs={3.5}>
                <Typography>Phone No <span style={{ color: 'red' }}>*</span> - </Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  type="number"
                  fullWidth
                  id="standard-basic"
                  variant="standard"
                  onChange={(e) => {
                    this.setState({ mobile: e.target.value });
                  }}
                  value={this.state.mobile}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button variant='contained'
              style={{
                borderradius: ' 0.33675rem', background: 'var(--PrimaryColour-G1, #3D3B3B)', width: '148px', padding: '0.33675rem 0.6735rem', color: 'white'
              }}
              onClick={() => {
                this.setState({
                  editm: false,
                  employee_id: "",
                  fullname: "",
                  email: "",
                  mobile: "",
                  employeeID: "",
                  role: ""
                });
                this.props.updatePersonalDetails(this.props.login.org_id, this.state.employee_id, this.state.name, this.state.email_id, this.state.mobile);
              }}
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>

        {/* -------------------------------------delete------------------------------- */}
        <Dialog
          maxWidth="xs"
          open={this.state.empdeletemod}>
          <DialogTitle display={'flex'} justifyContent={'space-between'} backgroundColor={'lightgray'}>
            <Typography variant='h6'>Delete</Typography>
            <CloseCircle
              size="24"
              color="black"
              variant="Outline"
              cursor={'pointer'}
              onClick={() => {
                this.setState({
                  empdeletemod: false,
                });
              }} />
          </DialogTitle>
          <DialogContent style={{ marginTop: '20px' }}>
            <DialogContentText >Are you sure, you want to delete ?</DialogContentText>
          </DialogContent>
          <DialogActions style={{ marginBottom: '10px', justifyContent: 'center' }}>
            <Button style={{ backgroundColor: "#3D3B3B", color: "#E5F6FF", borderRadius: 10, }}
              onClick={() => {
                this.props.deleteEmployee(this.state._id, this.props.login.org_id, this.props.login.product, this.props.login.user_id, this.props.login.token, this.state.type, this.state.page, this.state.rowsPerPage)
                this.setState({ empdeletemod: false });
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>






















        {/* ---------------edit executive---------------- */}
        <Dialog
          open={this.state.editexecutive} style={{}}>
          <Grid style={{ height: "400px", width: "450px" }}>
            <Box style={{ textAlign: "center" }}>
              <Typography style={{ fontSize: "18px", fontWeight: "500", marginTop: "3%" }}> Edit Details of Executive</Typography>
              <Grid style={{ marginTop: "5%" }}>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography style={{}}>User Name-</Typography>
                  <TextField style={{ marginLeft: "2%", width: "300px" }} id="standard-basic" variant="standard"
                    value={this.state.name}
                    onChange={(text) => this.setState({ name: text.target.value })}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Email id <span style={{ color: 'red' }}>*</span> -</Typography>
                  <TextField id="standard-basic" variant="standard" style={{ width: "300px", marginLeft: "25px", marginTop: "-7px" }}
                    value={this.state.email_id}
                    onChange={(text) => this.setState({ email_id: text.target.value })}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Phone No <span style={{ color: 'red' }}>*</span> -</Typography>
                  <TextField id="standard-basic" type="number" variant="standard" style={{ marginLeft: "17px", marginTop: "-7px", width: "300px" }}
                    value={this.state.mobile}
                    onChange={(text) => this.setState({ mobile: text.target.value })}
                  />
                </Grid>
                {/* <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Employee id-</Typography>
                  <TextField id="standard-basic" variant="standard" style={{ marginLeft: "10px", marginTop: "-7px", width: "300px" }}
                    value={this.state.employeeID}
                    onChange={(text) => this.setState({ employeeID: text.target.value })}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography style={{ marginLeft: "-40px" }}>Category -</Typography>
                  <TextField
                    style={{ width: '256px', marginLeft: "20px" }}
                    id="outlined-select-currency-native"
                    select
                    value={this.state.role}
                    onChange={(e) => {
                      this.setState({ role: e.target.value });
                    }}
                  >
                    <MenuItem value={"N"}>{"All Category"}</MenuItem>
                    {Array.isArray(this.props.employee.all_category) && this.props.employee.all_category.map((item) => (
                      <MenuItem value={item.cate_name}>{item.cate_name}</MenuItem>
                    ))}
                  </TextField>
                </Grid> */}
                <Grid style={{ marginTop: "3%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Button
                    onClick={() => { this.setState({ editexecutive: false }); }}
                    variant='outlined' style={{ border: "1px solid gray", color: "gray" }}>cancel</Button>
                  <Button
                    // disabled={this.state.email === "" || this.state.mobile === "" || this.state.role === ""}
                    onClick={() => {
                      this.setState({
                        editexecutive: false,
                        executive_id: '',
                        fullname: "",
                        email: "",
                        mobile: "",
                        employeeID: "",
                        role: ""
                      })
                      this.props.updatePersonalDetails(this.props.login.org_id, this.state.executive_id, this.state.name, this.state.email_id, this.state.mobile);
                      // this.props.updateExecutive(this.state._id, this.state.fullname, this.state.email, this.state.mobile, this.state.employeeID, this.state.role, this.props.login.user_id, this.props.login.org_id, this.state.page, this.state.rowsPerPage)
                    }}
                    variant='contained' style={{ marginLeft: "20px", borderColor: "1px solid gray" }} className='create-btn'>
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Dialog>
        {/* --------------------visivilityeye manager------------------ */}
        <Dialog
          open={this.state.visibilitym} style={{}}>
          <Grid style={{ height: "400px", width: "450px" }}>
            <Box style={{ textAlign: "center" }}>
              <Typography style={{ fontSize: "18px", fontWeight: "500", marginTop: "3%" }}>Details of Manager</Typography>
              <Grid style={{ marginTop: "5%" }}>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography style={{}}>User Name-</Typography>
                  <TextField disabled placeholder=" Punchual duos" style={{ marginLeft: "2%", width: "300px" }} id="standard-basic" variant="standard"
                    value={this.state.name}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Email id-</Typography>
                  <TextField disabled placeholder="s@gmail.com" id="standard-basic" variant="standard" style={{ width: "300px", marginLeft: "25px", marginTop: "-7px" }}
                    value={this.state.email_id}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Phone No-</Typography>
                  <TextField disabled placeholder="9178888606" id="standard-basic" variant="standard" style={{ marginLeft: "17px", marginTop: "-7px", width: "300px" }}
                    value={this.state.mobile}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Employee id-</Typography>
                  <TextField disabled placeholder="435gjh" id="standard-basic" variant="standard" style={{ marginLeft: "10px", marginTop: "-7px", width: "300px" }}
                    value={this.state.employeeID}
                  />
                </Grid>
                {/* <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Category -</Typography>
                  <TextField disabled placeholder="435gjh" id="standard-basic" variant="standard" style={{ marginLeft: "10px", marginTop: "-7px", width: "300px" }}
                    value={this.state.role}
                  /> */}
                {/* <TextField
                    style={{ width: '253px', }}
                    id="outlined-select-currency-native"
                    select                  
                  >
                    <MenuItem value={"N"}>{"All Category"}</MenuItem>
                    {Array.isArray(this.props.employee.all_category) && this.props.employee.all_category.map((item) => (
                      <MenuItem value={item.cate_name}>{item.cate_name}</MenuItem>
                    ))}
                  </TextField> */}
                {/* </Grid> */}
                <Grid style={{ marginTop: "3%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Button
                    onClick={() => { this.setState({ visibilitym: false }); }}
                    variant='contained' style={{ marginLeft: "20px", borderColor: "1px solid gray" }}>Okay</Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Dialog>
        {/* visibility executive */}
        <Dialog
          open={this.state.visibilityexecutive} style={{}}>
          <Grid style={{ height: "400px", width: "450px" }}>
            <Box style={{ textAlign: "center" }}>
              <Typography style={{ fontSize: "18px", fontWeight: "500", marginTop: "3%" }}>Details of Executive</Typography>
              <Grid style={{ marginTop: "5%" }}>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography style={{}}>User Name-</Typography>
                  <TextField disabled placeholder=" Punchual duos" style={{ marginLeft: "2%", width: "300px" }} id="standard-basic" variant="standard"
                    value={this.state.name}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Email id-</Typography>
                  <TextField disabled placeholder="s@gmail.com" id="standard-basic" variant="standard" style={{ width: "300px", marginLeft: "25px", marginTop: "-7px" }}
                    value={this.state.email_id}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Phone No-</Typography>
                  <TextField disabled placeholder="9178888606" id="standard-basic" variant="standard" style={{ marginLeft: "17px", marginTop: "-7px", width: "300px" }}
                    value={this.state.mobile}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Employee id-</Typography>
                  <TextField disabled placeholder="435gjh" id="standard-basic" variant="standard" style={{ marginLeft: "10px", marginTop: "-7px", width: "300px" }}
                    value={this.state.employeeID}
                  />
                </Grid>

                {/* <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Category -</Typography>
                  <TextField disabled placeholder="435gjh" id="standard-basic" variant="standard" style={{ marginLeft: "10px", marginTop: "-7px", width: "300px" }}
                    value={this.state.role}
                  /> */}
                {/* <TextField
                    style={{ width: '253px', }}
                    id="outlined-select-currency-native"
                    select
                    value={this.state.role}
                  >
                    <MenuItem value={"N"}>{"All Category"}</MenuItem>
                    {Array.isArray(this.props.employee.all_category) && this.props.employee.all_category.map((item) => (
                      <MenuItem value={item.cate_name}>{item.cate_name}</MenuItem>
                    ))}
                  </TextField> */}
                {/* </Grid> */}

                <Grid style={{ marginTop: "3%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Button
                    onClick={() => { this.setState({ visibilityexecutive: false }); }}
                    variant='contained' style={{ marginLeft: "20px", borderColor: "1px solid gray" }}>Okay</Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Dialog>

        {/* --------------------deleterowwwwww------------------ */}
        <Dialog open={this.state.Adminemployeedelete}>
          <Grid style={{ height: "210px", width: "300px" }}>
            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10, }}>
              <CloseOutlinedIcon
                onClick={() => { this.setState({ Adminemployeedelete: false }); }}
                style={{ fontSize: 20, color: "#000", cursor: "pointer", marginLeft: "250px", marginTop: "3%", }} />
            </Grid>
            <Typography style={{ textAlign: "center", marginTop: "5%", color: "#000", fontWeight: 600 }}>Are you sure you want to delete ?</Typography>
            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "10%", gap: "10px" }}>
              <Button
                onClick={() => { this.setState({ Adminemployeedelete: false }); }}
                variant='outlined' style={{ borderColor: "gray", color: "gray" }}>Yes</Button>
              <Button
                onClick={() => { this.setState({ Adminemployeedelete: false }); }}
                variant='contained' style={{ backgroundColor: "gray", color: "#fff", marginRight: "4%" }}>No</Button>
            </Grid>
          </Grid>
        </Dialog>
        {/* transfer dialog */}
        <Dialog
          open={this.state.transferData} style={{}}>
          <Grid style={{ height: "400px", width: "500px", paddingTop: "40px", paddingLeft: "40px", paddingRight: "40px" }}>
            <CancelOutlinedIcon
              onClick={() => { this.setState({ transferData: false }); }}
              style={{ marginLeft: "82%", marginTop: "1%" }} />
            <Grid style={{ gap: "88px", display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
              <Grid style={{ display: "flex", marginBottom: "3%", flexDirection: "column", justifyContent: "center", alignItems: "baseline", marginTop: "2%" }}>
                <Typography>Assign leads to</Typography>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 280 }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={category}
                    onChange={this.handleChangecategory}
                    label="Category"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Hawkings Devi</MenuItem>
                    <MenuItem value={20}>Charles babage</MenuItem>
                    <MenuItem value={30}>Johns Hum</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <button
                variant='contained'
                style={{ fontSize: "20px", cursor: "pointer", bottom: "0", border: "none", borderRadius: "4px", width: "30%", outline: "none", padding: "14px", color: "white", background: "#441000", marginTop: "9%" }}
                onClick={() => { this.setState({ delAfter: true }); }}
              >
                Assign
              </button>
            </Grid>
          </Grid>
        </Dialog>
        {/* after transfer delete dialog box */}
        <Dialog
          open={this.state.delAfter} style={{}}>
          <Grid style={{ height: "200px", width: "320px" }}>
            <Typography style={{ fontSize: "18px", fontWeight: "600", marginLeft: "5%", marginTop: "5%", marginBottom: "5%" }}>Delete</Typography>
            <Divider />
            <Box style={{ textAlign: "center", marginTop: "5%" }}>
              <Typography>Are you sure want to delete?</Typography>
            </Box>
            <Grid style={{ marginTop: "10%", display: "flex", justifyContent: "space-around" }}>
              <Button
                onClick={() => { this.setState({ delAfter: false }); }}
                variant='outlined' style={{ color: "black", borderColor: "gray" }}>cancel</Button>
              <button
                onClick={() => { this.setState({ delAfter: false }); }}
                variant='contained' style={{ cursor: "pointer", bottom: "0", border: "none", borderRadius: "4px", width: "30%", outline: "none", padding: "14px", color: "white", background: "#441000" }}>Okay</button>
            </Grid>
          </Grid>
        </Dialog>
        {/* ////This process will manager................................................................... */}
        <Dialog
          open={this.state.deletemanagertable} style={{}}>
          <Grid style={{ height: "250px", width: "520px", padding: '12px' }}>
            <CancelOutlinedIcon
              onClick={() => { this.setState({ deletemanagertable: false }); }}
              style={{ marginLeft: "82%", marginTop: "2%" }} />
            <Box style={{ textAlign: "center" }}>
              <Grid style={{ marginTop: "5%" }}>
                <Typography style={{ fontSize: "18px", fontWeight: "500" }}>This process will lead to loss in lead so </Typography>
                <Grid style={{ marginTop: "7%", display: "flex", gap: "20px", alignItems: "center", justifyContent: "center" }}>
                  {/* <Radio
                    checked={selectedValue === 'a'}
                    onChange={this.handleChangeradio}
                    value="a"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}                 
                  /> */}
                  <Box style={{ display: "flex", gap: "10px" }}>
                    <Box
                      style={{ cursor: "pointer", height: "20px", width: "20px", border: "2px solid gray", borderRadius: "100px" }}
                      onClick={() => { this.setState({ transferdatamanager: true }); }}
                    ></Box>
                    Transfer data
                  </Box>
                  <Box style={{ display: "flex", gap: "10px" }}>
                    <Box
                      style={{ cursor: "pointer", height: "20px", width: "20px", border: "2px solid gray", borderRadius: "100px" }}
                      onClick={() => { this.setState({ donttransferdelete: true }); }}
                    ></Box>
                    Don't Transfer data
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Dialog>
        {/* donttransferdeletemanager.................................................................................. */}
        <Dialog
          open={this.state.donttransferdelete} style={{}}>
          <Grid style={{ height: "210px", width: "300px" }}>
            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10, }}>
              {/* <Typography style={{ fontSize: "20px", fontWeight: "600" }}>Delete</Typography> */}
              <CloseOutlinedIcon
                onClick={() => { this.setState({ donttransferdelete: false }); }}
                style={{ fontSize: 20, color: "#000", cursor: "pointer", marginLeft: "250px", marginTop: "3%", }} />
            </Grid>
            {/* <Divider style={{ height: "1px", color: "#000" }} /> */}
            <Typography style={{ textAlign: "center", marginTop: "5%", color: "#000", fontWeight: 600 }}>Are you sure you want to delete ?</Typography>
            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "10%", gap: "10px" }}>
              <Button
                variant='outlined' style={{ borderColor: "gray", color: "gray" }}
                onClick={() => {
                  this.props.deleteManager(this.state.manager_id, this.props.login.user_id, this.props.login.org_id, this.state.page, this.state.rowsPerPage);
                  this.setState({ donttransferdelete: false, deletemanagertable: false });
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => { this.setState({ donttransferdelete: false }); }}
                variant='contained' style={{ backgroundColor: "gray", color: "#fff", marginRight: "4%" }}>No</Button>
            </Grid>
          </Grid>
        </Dialog>
        {/* //transfer all lead (manager) */}
        <Dialog
          open={this.state.transferdatamanager} style={{}}>
          <Grid style={{ height: "400px", width: "500px", paddingTop: "40px", paddingLeft: "40px", paddingRight: "40px" }}>
            <CancelOutlinedIcon
              onClick={() => { this.setState({ transferdatamanager: false }); }}
              style={{ marginLeft: "82%", marginTop: "1%" }} />
            <Grid style={{ gap: "88px", display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
              <Grid style={{ display: "flex", marginBottom: "3%", flexDirection: "column", justifyContent: "center", alignItems: "baseline", marginTop: "2%" }}>
                <Typography>Assign leads to</Typography>
                <TextField
                  style={{ width: '253px', }}
                  id="demo-simple-select-standard"
                  select
                  value={this.state._id}
                  onChange={(e) => {
                    this.setState({ _id: e.target.value })
                  }}
                >
                  <MenuItem value={"N"}>{"Select Category"}</MenuItem>
                  {Array.isArray(this.props.employee.all_manager) && this.props.employee.all_manager.map((item) => (
                    <MenuItem value={item._id}>{item.fullname}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <button
                variant='contained'
                style={{ fontSize: "20px", cursor: "pointer", bottom: "0", border: "none", borderRadius: "4px", width: "30%", outline: "none", padding: "14px", color: "white", background: "#441000", marginTop: "9%" }}
                onClick={() => {
                  this.setState({ transferdatamanager: false });
                  this.props.transferManagerLead(this.state.manager_id, this.state._id)
                }}
              >
                Assign
              </button>
            </Grid>
          </Grid>
        </Dialog>

        {/* Executive tab dialogue box */}

        {/* --------------------add------------------ */}
        <Dialog
          open={this.state.addexe22} style={{}}>
          <Grid style={{ height: "400px", width: "450px" }}>

            <Box style={{ textAlign: "center" }}>

              <Typography style={{ fontSize: "18px", fontWeight: "500", marginTop: "3%" }}>Details of Executive</Typography>


              <Grid style={{ marginTop: "5%" }}>

                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography style={{}}>User Name -</Typography>
                  <TextField style={{ marginLeft: "2%", width: "300px" }} id="standard-basic" variant="standard"
                    onChange={(text) => this.setState({ fullname: text.target.value })}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Email id <span style={{ color: 'red' }}>*</span> -</Typography>
                  <TextField id="standard-basic" variant="standard" style={{ width: "300px", marginLeft: "25px", marginTop: "-7px" }}
                    onChange={(text) => this.setState({ email: text.target.value })}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Phone No <span style={{ color: 'red' }}>*</span> -</Typography>
                  <TextField id="standard-basic" type="number" variant="standard" style={{ marginLeft: "17px", marginTop: "-7px", width: "300px" }}
                    onChange={(text) => this.setState({ mobile: text.target.value })}
                  />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography style={{ marginLeft: "10px" }}>Employee id-</Typography>
                  <TextField id="standard-basic" variant="standard" style={{ marginLeft: "10px", marginTop: "-7px", width: "300px" }}
                    onChange={(text) => this.setState({ employeeID: text.target.value })}
                  />
                </Grid>

                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography style={{ marginLeft: "-30px" }}>Category <span style={{ color: 'red' }}>*</span> -</Typography>
                  <TextField
                    style={{ width: '253px', marginLeft: "20px" }}
                    id="outlined-select-currency-native"
                    select
                    value={this.state.role}
                    onChange={(e) => {
                      this.setState({ role: e.target.value });
                    }}
                  >
                    <MenuItem value={"N"}>{"Select Category"}</MenuItem>
                    {Array.isArray(this.props.employee.all_category) && this.props.employee.all_category.map((item) => (
                      <MenuItem value={item.cate_name}>{item.cate_name}</MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid style={{ marginTop: "3%", display: "flex", flexDirection: "row", justifyContent: "center" }}>

                  <Button
                    onClick={() => { this.setState({ addexe: false }); }}
                    variant='outlined' style={{ borderColor: "1px solid gray" }}>cancel</Button>
                  <Button
                    disabled={this.state.email === "" || this.state.mobile === "" || this.state.role === ""}
                    onClick={() => {
                      this.setState({ addexe: false, fullname: "", email: "", mobile: "", employeeID: "", role: "" })
                      this.props.addExecutiveByManager(this.props.login.org_id, this.props.login.user_id, this.state.fullname, this.state.email, this.state.mobile, this.state.employeeID, this.state.role, this.state.page, this.state.rowsPerPage)

                    }}
                    variant='contained' style={{ marginLeft: "20px", borderColor: "1px solid gray", color: "white" }}>save</Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Dialog>

        {/* --------------------edit------------------ */}
        <Dialog
          open={this.state.editexe} style={{}}>
          <Grid style={{ height: "400px", width: "450px" }}>

            <Box style={{ textAlign: "center" }}>

              <Typography style={{ fontSize: "18px", fontWeight: "500", marginTop: "3%" }}> Edit Details of Executive</Typography>


              <Grid style={{ marginTop: "5%" }}>

                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography style={{}}>User Name-</Typography>
                  <TextField style={{ marginLeft: "2%", width: "300px" }} id="standard-basic" variant="standard" />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Email id <span style={{ color: 'red' }}>*</span> -</Typography>
                  <TextField id="standard-basic" variant="standard" style={{ width: "300px", marginLeft: "25px", marginTop: "-7px" }} />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Phone No <span style={{ color: 'red' }}>*</span> -</Typography>
                  <TextField id="standard-basic" variant="standard" style={{ marginLeft: "17px", marginTop: "-7px", width: "300px" }} />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Employee id-</Typography>
                  <TextField id="standard-basic" variant="standard" style={{ marginLeft: "10px", marginTop: "-7px", width: "300px" }} />
                </Grid>

                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Category <span style={{ color: 'red' }}>*</span> -</Typography>
                  <FormControl variant="standard" sx={{ m: 1, width: "310px" }}>

                    <Select>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid style={{ marginTop: "3%", display: "flex", flexDirection: "row", justifyContent: "center" }}>

                  <Button
                    onClick={() => { this.setState({ editexe: false }); }}
                    variant='outlined' style={{ borderColor: "1px solid gray" }}>cancel</Button>
                  <Button
                    onClick={() => { this.setState({ editexe: false }); }}
                    variant='contained' style={{ marginLeft: "20px", borderColor: "1px solid gray" }}>Update</Button>
                </Grid>

              </Grid>




            </Box>

          </Grid>
        </Dialog>

        {/* --------------------delete------------------ */}
        <Dialog
          open={this.state.delexe} style={{}}>
          <Grid style={{ height: "250px", width: "520px", padding: '12px' }}>

            <CancelOutlinedIcon
              onClick={() => { this.setState({ delexe: false }); }}
              style={{ marginLeft: "82%", marginTop: "2%" }} />

            <Box style={{ textAlign: "center" }}>




              <Grid style={{ marginTop: "5%" }}>

                <Typography style={{ fontSize: "18px", fontWeight: "500" }}>This process will lead to loss in lead so </Typography>

                <Grid style={{ marginTop: "7%", display: "flex", gap: "20px", alignItems: "center", justifyContent: "center" }}>

                  {/* <Radio
                    checked={selectedValue === 'a'}
                    onChange={this.handleChangeradio}
                    value="a"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}


                   
                  /> */}
                  <Box style={{ display: "flex", gap: "10px" }}>
                    <Box
                      style={{ cursor: "pointer", height: "20px", width: "20px", border: "2px solid gray", borderRadius: "100px" }}
                      onClick={() => { this.setState({ transferExeData: true }); }}
                    ></Box>
                    Transfer data
                  </Box>
                  <Box style={{ display: "flex", gap: "10px" }}>
                    <Box
                      style={{ cursor: "pointer", height: "20px", width: "20px", border: "2px solid gray", borderRadius: "100px" }}
                      onClick={() => { this.setState({ delExeAfter: true }); }}
                    ></Box>
                    Don't Transfer data
                  </Box>

                </Grid>
              </Grid>




            </Box>

          </Grid>
        </Dialog>

        {/* assign lead(executive) */}
        <Dialog
          open={this.state.transferExeData} style={{}}>
          <Grid style={{ height: "400px", width: "500px", paddingTop: "40px", paddingLeft: "40px", paddingRight: "40px" }}>


            <CancelOutlinedIcon
              onClick={() => { this.setState({ transferExeData: false }); }}
              style={{ marginLeft: "82%", marginTop: "1%" }} />

            <Grid style={{ gap: "88px", display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center", textAlign: "center" }}>


              <Grid style={{ display: "flex", marginBottom: "3%", flexDirection: "column", justifyContent: "center", alignItems: "baseline", marginTop: "2%" }}>
                <Typography>Assign leads to</Typography>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 280 }}>
                  {/* <InputLabel id="demo-simple-select-standard-label"> </InputLabel> */}
                  <TextField
                    style={{ width: '253px', }}
                    // id="outlined-select-currency-native"
                    id="demo-simple-select-standard"
                    select
                    // value={this.state.role}
                    value={this.state._id}
                    onChange={(e) => {
                      this.setState({ _id: e.target.value })
                    }}
                  >
                    <MenuItem value={"N"}>{"Select Category"}</MenuItem>

                    {Array.isArray(this.props.employee.all_executive_by_admin) && this.props.employee.all_executive_by_admin.map((item) => (
                      <MenuItem value={item._id}>{item.fullname}</MenuItem>
                    ))}

                  </TextField>
                </FormControl>
              </Grid>
              <button
                variant='contained'
                style={{ fontSize: "20px", cursor: "pointer", bottom: "0", border: "none", borderRadius: "4px", width: "30%", outline: "none", padding: "14px", color: "white", background: "#441000", marginTop: "9%" }}
                onClick={() => {
                  this.setState({ transferExeData: false });
                  this.props.transferExecutiveLead(this.state.executive_id, this.state._id, this.props.login.user_id, this.props.login.org_id)

                }}
              >
                Assign
              </button>
            </Grid>
          </Grid>
        </Dialog>

        {/* after transfer delete dialog box */}
        <Dialog
          open={this.state.delExeAfter} style={{}}>
          <Grid style={{ height: "210px", width: "300px" }}>

            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10, }}>
              {/* <Typography style={{ fontSize: "20px", fontWeight: "600" }}>Delete</Typography> */}
              <CloseOutlinedIcon
                onClick={() => { this.setState({ delExeAfter: false }); }}
                style={{ fontSize: 20, color: "#000", cursor: "pointer", marginLeft: "250px", marginTop: "3%", }} />
            </Grid>
            {/* <Divider style={{ height: "1px", color: "#000" }} /> */}

            <Typography style={{ textAlign: "center", marginTop: "5%", color: "#000", fontWeight: 600 }}>Are you sure you want to delete ?</Typography>

            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "10%", gap: "10px" }}>
              <Button

                variant='outlined' style={{ borderColor: "gray", color: "gray" }}
                onClick={() => {
                  this.setState({ delExeAfter: false });
                  this.props.deleteExecutive(this.state.executive_id, this.props.login.user_id, this.props.login.org_id, this.state.page, this.state.rowsPerPage)

                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => { this.setState({ delExeAfter: false }); }}
                variant='contained' style={{ backgroundColor: "gray", color: "#fff", marginRight: "4%" }}>No</Button>
            </Grid>


          </Grid>
        </Dialog>

        {/* --------------------visivilityeye------------------ */}
        <Dialog
          open={this.state.visibilityexe} style={{}}>
          <Grid style={{ height: "400px", width: "450px" }}>

            <Box style={{ textAlign: "center" }}>

              <Typography style={{ fontSize: "18px", fontWeight: "500", marginTop: "3%" }}>Details of Executive</Typography>


              <Grid style={{ marginTop: "5%" }}>

                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography style={{}}>User Name-</Typography>
                  <TextField disabled placeholder=" Punchual duos" style={{ marginLeft: "2%", width: "300px" }} id="standard-basic" variant="standard" />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Email id-</Typography>
                  <TextField disabled placeholder="s@gmail.com" id="standard-basic" variant="standard" style={{ width: "300px", marginLeft: "25px", marginTop: "-7px" }} />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Phone No-</Typography>
                  <TextField disabled placeholder="9178888606" id="standard-basic" variant="standard" style={{ marginLeft: "17px", marginTop: "-7px", width: "300px" }} />
                </Grid>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Employee id-</Typography>
                  <TextField disabled placeholder="435gjh" id="standard-basic" variant="standard" style={{ marginLeft: "10px", marginTop: "-7px", width: "300px" }} />
                </Grid>

                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4%" }}>
                  <Typography>Category -</Typography>
                  <FormControl disabled placeholder="jkjlk" variant="standard" sx={{ m: 1, width: "310px" }}>

                    <Select  >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid style={{ marginTop: "3%", display: "flex", flexDirection: "row", justifyContent: "center" }}>

                  {/* <Button
        onClick={() => { this.setState({ visibilitym: false }); }}
        variant='outlined' style={{ borderColor: "1px solid gray" }}>cancel</Button> */}
                  <Button
                    onClick={() => { this.setState({ visibilityexe: false }); }}
                    variant='contained' style={{ marginLeft: "20px", borderColor: "1px solid gray" }}>Okay</Button>
                </Grid>

              </Grid>




            </Box>

          </Grid>
        </Dialog>

        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        />  */}
      </Grid>

    )
  }
}
