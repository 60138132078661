import {
  VIEW_ALL_ALLOTMENT,
  VIEW_ALLOTMENT_BY_ID,
  VIEW_ALLOTMENT_BY_ORG_ID,
  VIEW_ALL_ALLOTMENT_WITH_DETAILS,
  VIEW_ALL_ALLOTMENT_BY_ORG_THROUGH_STATUS,
  FILTER_ALLOTMENT,
  VIEW_ALL_LEADS_WITH_SEARCH,
  CLEAR_SELECT
} from "./constant";
const initial_state = {
  all_allotment: [],
  all_allotment_by_id: [],
  all_allotment_by_org_id: [],
  all_allotment_with_details: [],
  all_allotment_by_org_through_status: [],
  allot_length:0,
  view_all_leads_with_search:[],
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case VIEW_ALL_ALLOTMENT:

      return {
        ...state,
        all_allotment: action.payload,
      };

    case FILTER_ALLOTMENT:
      return {
        ...state,
        filter_allot: action.payload
      };

    case VIEW_ALLOTMENT_BY_ID:
      return {
        ...state,
        all_allotment_by_id: action.payload
      };

    case VIEW_ALLOTMENT_BY_ORG_ID:
      return {
        ...state,
        all_allotment_by_org_id: action.payload, filter_allot: action.payload, allot_length: action.count
      };

    case VIEW_ALL_ALLOTMENT_WITH_DETAILS:
      return {
        ...state,
        all_allotment_with_details: action.payload
      };

    case VIEW_ALL_ALLOTMENT_BY_ORG_THROUGH_STATUS:
      return {
        ...state,
        all_allotment_by_org_through_status: action.payload
      };
      case VIEW_ALL_LEADS_WITH_SEARCH:
        return {
          ...state,
          view_all_leads_with_search: action.payload
        };
      case CLEAR_SELECT:
        return {
          ...state,
          view_all_leads_with_search: []
        };








    default:
      return state;
  }
}
