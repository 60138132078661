import React, { Component } from "react";
import { connect } from "react-redux";
import Businesspersonal from "../Components/businesspersonal";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import { viewOrgDetailsById,addPersonalDetails,viewAllPersonalDetails, updatePersonalDetails} from '../action'


export class Controller extends Component {
  render() {
    return (
      <Businesspersonal {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    employee: store.employee,
    login: store.login,
    setting:store.setting
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

    viewOrgDetailsById: (org_id) => {
      dispatch(viewOrgDetailsById(org_id));
    },

    addPersonalDetails: (org_id,name,email,phone) => {
      dispatch(addPersonalDetails(org_id,name,email,phone));
    },

    viewAllPersonalDetails: (user_id) => {
      dispatch(viewAllPersonalDetails(user_id));
    },
    updatePersonalDetails: (user_id, name,email,mobile) => {
      dispatch(updatePersonalDetails(user_id, name,email,mobile));
    },

      
  };
}; 
export default connect(mapStateToProps, mapDispatchToProps)(Controller);