import {
    VIEW_ALL_SALES_BY_ORG_ID,
    VIEW_ALL_SALES_BY_PRODUCT_AND_PAYMENT,
    SEARCH_SALE,
    SEARCH_PAYMENT
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import { set_snack_bar } from "../../common/snackbar/action";
import 'firebase/storage';





export function viewAllSalesByOrgId(org_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_converted_lead",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id,
                },
                body: JSON.stringify({
                    page_number: page_number,
                    rowper_page: rowper_page

                })

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_SALES_BY_ORG_ID, payload: responseJson.result, count: responseJson.length });
                    dispatch(set_snack_bar(responseJson.status));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_SALES_BY_ORG_ID, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewAllSalesPaymentByOrgId(org_id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_payment_by_org_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id,
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_SALES_BY_PRODUCT_AND_PAYMENT, payload: responseJson.result, count: responseJson.length });

                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_SALES_BY_PRODUCT_AND_PAYMENT, payload: [], count: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };



}

export function searchSaleByDate(view_all_sales_by_org_id, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_SALE, payload: view_all_sales_by_org_id });

        }
        else {
            const newArray = view_all_sales_by_org_id.filter((el) => {
                return ((el.create_date).toString().includes((e).toString()));
            })
            dispatch({ type: SEARCH_SALE, payload: newArray })


        }
    };
}

export function searchPaymentByDate(view_all_sales_by_product_and_payment, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_PAYMENT, payload: view_all_sales_by_product_and_payment });

        }
        else {
            const newArray = view_all_sales_by_product_and_payment.filter((el) => {
                return ((el.create_date).toString().includes((e).toString()));
            })
            dispatch({ type: SEARCH_PAYMENT, payload: newArray })


        }
    };
}