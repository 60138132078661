import React, { Component } from 'react';
import { TextField, Dialog, Box, Grid, Divider, Typography, Card, TableContainer, TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Message from "../../image/message.png";
import Whatsapp from "../../image/whatsapp.png";
import Call from "../../image/call.png";
import Mail from "../../image/email.png";
import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";

export default class Executivedetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewData: false,
            tabledata: [
            ]
        };
        this.handleSendMessage = this.handleSendMessage.bind(this)
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleCallButtonClick = this.handleCallButtonClick.bind(this)
    }
    handleEmailClick = () => {
        const emailAddress = this.props.employee.executive_details.email;
        const subject = 'Subject of the email';
        const body = 'Body of the email';
        // Construct the mailto link
        const mailtoLink = `mailto:${emailAddress}`;
        // Open the default email client
        window.location.href = mailtoLink;
    };

    componentDidMount() {
        this.props.viewLeadByExecutive(this.props.employee.executive_details._id);
        
    }
    handleButtonClick() {
        const phoneNumber = this.props.employee.executive_details.mobile;
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    }
    handleSendMessage() {
        const messageURI = `sms:${this.props.employee.executive_details.mobile}`;
        window.location.href = messageURI;
    }
    handleCallButtonClick() {
        // Replace '123456789' with the desired phone number
        // 
        // Create the tel URL with the phone number
        const telUrl = `tel:${this.props.employee.executive_details.mobile}`;
        // Initiate a phone call
        window.location.href = telUrl;
    }
    render() {
        const { snackbar, close_snack_bar } = this.props;
        const { employee } = this.props
        return (
            <Grid style={{ marginTop: "10%" }}>
                <a href='/Employee' style={{ textDecoration: 'none', color: 'black' }}>
                    <Grid style={{ display: "flex", flexDirection: "row" }}>
                        <KeyboardArrowLeftIcon />
                        Back to employee
                    </Grid>
                </a>
                <Grid style={{ display: "flex", flexDirection: "row", marginLeft: "6%", marginTop: "2%", gap: 36 }}>
                    <Grid>
                        <Card style={{ width: "300px", boxShadow: "2px 2px 8px 0px #66666633" }}>
                            <Box sx={{ textAlign: "center", marginTop: "7%", marginBottom: "7%" }}>
                                <Typography>{this.props.employee.executive_details.fullname}</Typography>
                                <Typography>Employee ID:{this.props.employee.executive_details.employeeID}</Typography>
                                <Divider style={{ marginBottom: "7%", marginTop: "7%" }} />
                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "6%", }}>
                                    <button onClick={() => this.handleButtonClick()} style={{ marginRight: "2%", borderRadius: "5px" }}> <img src={Whatsapp} alt='' style={{}} /></button>

                                    <button onClick={this.handleEmailClick} style={{ marginRight: "2%", borderRadius: "5px" }}><img src={Mail} alt='' style={{ marginLeft: "4%" }} /></button>

                                    <button onClick={() => this.handleCallButtonClick()} style={{ marginRight: "2%", borderRadius: "5px" }}><img src={Call} alt='' style={{ marginLeft: "4%" }} /></button>

                                    <button onClick={() => this.handleSendMessage()} style={{ borderRadius: "5px" }}><img src={Message} alt='' style={{ width: "24px", height: "27px", marginTop: "5px", marginLeft: "4%" }} /></button>
                                </Grid>
                            </Box>
                        </Card>
                        <Card style={{ width: "300px", marginTop: "10%", boxShadow: "2px 2px 8px 0px #66666633" }}>
                            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "7%", marginBottom: "7%" }}>
                                <Grid>
                                    <Typography style={{ fontSize: "25px", fontWeight: "600" }}>{this.props.employee.all_lead_by_executive.lead_length}</Typography>
                                    <Typography style={{ marginTop: "10%" }}>Leads</Typography>
                                </Grid>
                                <Grid style={{ marginLeft: "10%" }}>
                                    <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <ChangeHistoryTwoToneIcon style={{ color: "#20C130", fontSize: 40 }} />
                                        <Typography style={{ fontSize: "25px", fontWeight: "600" }}>450</Typography>
                                    </Grid>
                                    <Typography>Converted</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Card style={{
                        width: "700px", boxShadow: "2px 2px 8px 0px #66666633"
                    }}>
                        <Typography style={{ color: "#AA3F1D", fontSize: "20px", marginLeft: "2%", marginTop: "2%" }}>Leads</Typography>
                        <Box sx={{ height: "250px", width: "100%", marginLeft: "2%", marginRight: "2%", marginTop: "2%" }}>
                            <Grid>
                                <Divider style={{ marginBottom: "2%" }} />
                                <TableContainer style={{ width: "100%" }}  >
                                    {/* <TableHead style={{ width: "100%", backgroundColor: "#E0E0E0" }}>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead> */}
                                    {Array.isArray(this.props.employee.all_lead_by_executive) && this.props.employee.all_lead_by_executive.map((item, index) => {
                                        return (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.email}</TableCell>
                                                    <TableCell>{item.source}</TableCell>
                                                    <TableCell>{item.status}</TableCell>
                                                    {/* <TableCell style={{ width: "30%" }}><ErrorOutlinedIcon 
                                                        onClick={() => {
                                                            this.setState({
                                                                viewData: true,
                                                            })
                                                        }}
                                                    /></TableCell> */}
                                                </TableRow>
                                            </TableBody>
                                        );
                                    })}
                                </TableContainer>
                                {/* )})} */}
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
                {/* view dialog */}
                <Dialog
                    open={this.state.viewData} style={{}}>
                    <Grid style={{ width: "400px", padding: "20px", justifyContent: "center", alignItems: "center" }}>
                        <Grid style={{ display: "flex", justifyContent: "space-between", }}>
                            <Typography style={{ fontWeight: "600" }}>Add Product</Typography>
                            <CloseOutlinedIcon
                                onClick={() => {
                                    this.setState({ viewData: false });
                                }}
                                className="eventcross-icon"
                            />
                        </Grid>
                        <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <Grid style={{ marginTop: "2%" }}>
                                <Typography>User Name-</Typography>
                                <TextField id="standard-basic" placeholder='Punchul Duos' disabled variant="standard" style={{ width: "250px", marginLeft: "2%", marginTop: "-7px" }} />
                            </Grid>
                            <Grid>
                                <Typography>Email ID-</Typography>
                                <TextField id="standard-basic" placeholder='abc@gmail.com' disabled variant="standard" style={{ width: "250px", marginLeft: "2%", marginTop: "-7px" }} />
                            </Grid>
                            <Grid>
                                <Typography>Phone No-</Typography>
                                <TextField id="standard-basic" placeholder='1234567890' disabled variant="standard" style={{ width: "250px", marginLeft: "2%", marginTop: "-7px" }} />
                            </Grid>
                            <Grid>
                                <Typography>Employee ID-</Typography>
                                <TextField id="standard-basic" placeholder='EMP1234567' disabled variant="standard" style={{ width: "250px", marginLeft: "2%", marginTop: "-7px" }} />
                            </Grid>
                            <button
                                onClick={() => {
                                    this.setState({ viewData: false });
                                }}
                                style={{ border: "none", borderRadius: "2px", outline: "none", color: "white", background: "#441000", padding: "10px", width: "30%", marginTop: "2%" }}>
                                Okay
                            </button>
                        </Grid>
                    </Grid>
                </Dialog>
                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />
            </Grid>
        )
    }
}
