import React, { Component } from "react";
import { connect } from "react-redux";
import Employeedetail from "../Components/Employeedetail";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

 import {viewExecutiveByManager,assignExecutiveToManager} from '../actions'


export class Controller extends Component {
  render() {
    return (
      <Employeedetail {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    allotment: store.allotment,
    employee:store.employee,
    login:store.login,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return { 
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

    viewExecutiveByManager: (manager_id,org_id) => {
        dispatch(viewExecutiveByManager(manager_id,org_id));
      },

      assignExecutiveToManager: (executive_id,manager_id,org_id) => {
        dispatch(assignExecutiveToManager(executive_id,manager_id,org_id));
      },

     

     

    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);