import React, { Component } from 'react';
import { Grid } from "@mui/material";
import "../../auth/components/login.css";
import { useParams } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import UNIVERSAL from "../../config/config";


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Loginpage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            otp: "",
            change_num: false,
            enter_num: false,
            TermCondition: false,
            isHidden: true,
            email: false,
            isHidden2: true,
            number: false,
            time: {},
            seconds: 30,
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    toggle = () => {
        this.setState((prevState) => ({
            email: !prevState.email,
        }));
    }
    toggleElement = () => {
        this.setState((prevState) => ({
            isHidden: !prevState.isHidden,
        }));
    };

    toggle2 = () => {
        this.setState((prevState) => ({
            number: !prevState.number,
        }));
    }
    toggleElement2 = () => {
        this.setState((prevState) => ({
            isHidden2: !prevState.isHidden2,
        }));
    };

    resetTimer() {
        clearInterval(this.timer);
        this.setState({
            seconds: 30,
            time: this.secondsToTime(30),
        });
        this.timer = setInterval(this.countDown, 1000)
    }



    handleOnChange = (value, data, event, formattedValue, otp) => {
        this.setState({
            phone: value,
        });
        this.setState({ otp });
    }

    handlenumber = (value) => {
        this.setState({ phone: value });
    }

    handleChange = (otp) => {
        this.setState({ otp });
    }



    handleRestart = () => {
        // Reset the state to the initial values
        this.setState({
            phone: "",
            otp: "",
            change_num: false,
            enter_num: false,
            bt_type: true,
            time: {},
            seconds: 30,
            phoneNumberDisabled: false,
            enterOtpDisabled: true,
            error: null,
        });
    }

    handleVerifyOTP = () => {

        this.props.verify_otp(this.state.phone, this.state.otp);
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    startTimer() {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown = () => {
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        if (seconds === 0) {
            clearInterval(this.timer);
            // Enable the "Send OTP" button
            this.setState({ seconds: 30 });
        }
    }
    componentDidMount() {
        console.log(this.props.params.id)
        if (this.props.params.id === undefined) {

            const payload = {
                product: "6645bf85ce519b81ca69587b",
            };
            //sso backend
            fetch(UNIVERSAL.SSO_URL + "api/v1/loginActivity/createLogin", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.statusCode === 201) {
                        this.setState({
                            data: data,
                            isLoading: false,
                            openErrorSnackbar: true,
                            message: "Otp Verified",
                            org: true,
                            orgData: data.result,
                        });
                        window.location.href = "https://sso.technoboot.co/" + data.data._id
                        // window.location.href = "http://localhost:3001/" + data.data._id

                    }
                    else {
                        this.setState({
                            data: data,
                            isLoading: false,
                            openErrorSnackbar: true,
                            message: "Some Error Ocured"
                        });
                    }

                })
                .catch((error) => {
                    console.error(error);
                    this.setState({ openErrorSnackbar: true, message: "Soome Error Occured" })
                });
        } else {
            this.props.fetchToken(this.props.params.id);
        }
    }

    render() {
        return (
            <div style={{ background: "#FAF9F6", display: "flex", justifyContent: "center", alignItems: 'center', height: "100vh" }}>
                <Grid>
                    <Stack sx={{ width: '100%', marginTop: "20%", justifyContent: 'center', alignItems: 'center' }} spacing={2}>
                        <CircularProgress style={{ width: "100px", height: "100px" }} />
                    </Stack>
                </Grid>
            </div >
        );
    }
}

export default withParams(Loginpage);
