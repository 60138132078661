import React, { Component } from "react";
import { connect } from "react-redux";
import Businesscategory from "../Components/businesscategory";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {addCategory,updateCategory,deleteCategory,viewAllCategory} from '../action'

// import {viewAllCategory} from '../../employee/actions'


export class Controller extends Component {
  render() {
    return (
      <Businesscategory {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    employee: store.employee,
    login: store.login,
    setting:store.setting
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

    addCategory: (org_id,cate_name) => {
      dispatch(addCategory(org_id,cate_name));
    },

    viewAllCategory: (org_id) => {
        dispatch(viewAllCategory(org_id));
      },

      updateCategory: (org_id,category_id,cate_name) => {
        dispatch(updateCategory(org_id,category_id,cate_name));
      },

      deleteCategory: (org_id,category_id) => {
        dispatch(deleteCategory(org_id,category_id));
      },

  

  

   
    
   
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);