import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Controller from "../containers/controller_cont";
import Login from "../../auth/container/logincont";
import Drawer_option_con from "../containers/drawer_cont";
import Vieworg from "../../../src/auth/container/vieworgCont";
import Error from "../../404";
import Product from "../../pages/product/Container/ProductCont";
import Employee from "../../pages/employee/Container/EmployeeCont";
import Adminsales from "../../pages/sales/Container/AdminsalesCont";
import Newdashboard from "../../pages/dashboard/Container/newdashboardCont";
import Lead from "../../pages/leads/container/leadsCont";
import TodayLead from "../../pages/leads/container/TodaylclientsCont";
import Source from "../../pages/Source/Components/source";
import Employeedetail from "../../pages/employee/Container/EmployeedetailsCont";
import Setting from "../../pages/settings/Components/setting";
import SettingDrawer from "../../pages/settings/Components/SettingDrawer"
import Businesssetting from "../../pages/settings/Container/businesssettingCont";
import Integration from "../../pages/settings/Components/integration";
import Template from "../../pages/settings/Container/templateCont";
import Notification from "../../pages/settings/Components/Notification";
import Security from "../../pages/settings/Components/security";
import Payment from "../../pages/settings/Container/paymentCont";
import Businesspersonal from "../../pages/settings/Container/businesspersonalCont";
import Businesscategory from "../../pages/settings/Container/businesscategoryCont";
import Roleandpermission from "../../pages/settings/Components/roleandpermission";
import Intgdetapage from "../../pages/settings/Components/integrationdetailpage";
import Analysis from "../../pages/analysis/component/analysis";
import AdminNewLead from "../../pages/leads/container/AdminNewLeadCont";
import User from "../../pages/User/user";
import Space from "../../pages/Space/space";
import Navbar from "../../pages/navbar/navbar";
import AdminleadDetails from "../../pages/leads/container/AdminleaddetailsCont";
import Executivedetails from "../../pages/employee/Container/ExecutivedetailsCont";
import Adminallotment from "../../pages/allotment/Container/AdminallotmentCont";
import Profile from "../../pages/profile/component/Profile";

class Routers extends Component {
  render() {
    const { login, onLogout } = this.props;
    let body;
    if (login.isHome) {
      body = (
        <div>
          <main
            style={{
              flexGrow: 1,
            }}
          >
            <Controller />
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/verify/:id" element={<Login />} />
              <Route exact path="*" element={<Error />} />
            </Routes>
          </main>
        </div>
      );
    }

    else if (login.orgHome) {
      if (login.type === "A" || login.type === "Owner") {
        body = (
          <div>
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                margin: '80px 20px 0px 220px',
              }}
            >
              <Controller />
              <Routes>
                <Route exact path="/" element={<Newdashboard />} />
                <Route exact path="/verify/:id" element={<><Newdashboard /></>} />
                <Route exact path="/product" element={<Product />} />
                <Route exact path="/Employee" element={<Employee />} />
                <Route exact path="/Employeedetail" element={<Employeedetail />} />
                <Route exact path="/Executivedetails" element={<Executivedetails />} />
                <Route exact path="/Adminsales" element={<Adminsales />} />
                <Route exact path="/AdminleadDetails/:id" element={<AdminleadDetails />} />
                <Route exact path="/Adminallotment" element={<Adminallotment />} />
                <Route exact path="/AdminNewLead" element={<AdminNewLead />} />
                <Route exact path="/all_lead" element={<><Lead /></>} />
                <Route exact path="/todays_lead" element={<><TodayLead /></>} />
                <Route exact path="/source" element={<><Source /></>} />
                <Route exact path="/setting" element={<Setting />} />
                <Route exact path="/SettingDrawer" element={<SettingDrawer />} />
                <Route exact path="/businesssetting" element={<Businesssetting />} />
                <Route exact path="/businesspersonal" element={<Businesspersonal />} />
                <Route exact path="/businesscategory" element={<Businesscategory />} />
                <Route exact path="/roleandpermission" element={<Roleandpermission />} />
                <Route exact path="/integrationdetailpage" element={<Intgdetapage />} />
                <Route exact path="/analysis" element={<Analysis />} />
                <Route exact path="/Profile" element={<Profile />} />
                <Route exact path="/integration" element={<Integration />} />
                <Route exact path="/template" element={<Template />} />
                <Route exact path="/notification" element={<Notification />} />
                <Route exact path="/security" element={<Security />} />
                <Route exact path="/payment" element={<Payment />} />
                <Route exact path="/user" element={<User />} />
                <Route exact path="/space" element={<Space />} />
                <Route exact path="/navbar" element={<Navbar />} />
              </Routes>
            </main>
          </div>
        );
      }

      else if (login.type === "M") {
        body = (
          <div>
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                margin: '80px 20px 0px 220px',
              }}
            >
              <Controller />
              <Routes>
                <Route exact path="/" element={<Newdashboard />} />
                <Route exact path="/verify/:id" element={<><Newdashboard /></>} />
                <Route exact path="/product" element={<Product />} />
                <Route exact path="/Employee" element={<Employee />} />
                <Route exact path="/Employeedetail" element={<Employeedetail />} />
                <Route exact path="/Executivedetails" element={<Executivedetails />} />
                <Route exact path="/Adminsales" element={<Adminsales />} />
                <Route exact path="/AdminleadDetails/:id" element={<AdminleadDetails />} />
                <Route exact path="/Adminallotment" element={<Adminallotment />} />
                <Route exact path="/AdminNewLead" element={<AdminNewLead />} />
                <Route exact path="/all_lead" element={<><Lead /></>} />
                <Route exact path="/todays_lead" element={<><TodayLead /></>} />
                <Route exact path="/source" element={<><Source /></>} />
                <Route exact path="/setting" element={<Setting />} />
                <Route exact path="/SettingDrawer" element={<SettingDrawer />} />
                <Route exact path="/businesssetting" element={<Businesssetting />} />
                <Route exact path="/businesspersonal" element={<Businesspersonal />} />
                <Route exact path="/businesscategory" element={<Businesscategory />} />
                <Route exact path="/roleandpermission" element={<Roleandpermission />} />
                <Route exact path="/integrationdetailpage" element={<Intgdetapage />} />
                <Route exact path="/analysis" element={<Analysis />} />
                <Route exact path="/Profile" element={<Profile />} />
                <Route exact path="/integration" element={<Integration />} />
                <Route exact path="/template" element={<Template />} />
                <Route exact path="/notification" element={<Notification />} />
                <Route exact path="/security" element={<Security />} />
                <Route exact path="/payment" element={<Payment />} />
                <Route exact path="/user" element={<User />} />
                <Route exact path="/space" element={<Space />} />
                <Route exact path="/navbar" element={<Navbar />} />
              </Routes>
            </main>
          </div>
        )
      }

      else if (login.type === "E") {
        body = (
          <div>
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                margin: '80px 20px 0px 220px',
              }}
            >
              <Controller />
              <Routes>
                <Route exact path="/" element={<><Newdashboard /></>} />
                <Route exact path="/verify/:id" element={<><Newdashboard /></>} />
                <Route exact path="/product" element={<Product />} />
                <Route exact path="/Employee" element={<Employee />} />
                <Route exact path="/Employeedetail" element={<Employeedetail />} />
                <Route exact path="/Executivedetails" element={<Executivedetails />} />
                <Route exact path="/Adminsales" element={<Adminsales />} />
                <Route exact path="/AdminleadDetails/:id" element={<AdminleadDetails />} />
                <Route exact path="/Adminallotment" element={<Adminallotment />} />
                <Route exact path="/AdminNewLead" element={<AdminNewLead />} />
                <Route exact path="/all_lead" element={<><Lead /></>} />
                <Route exact path="/todays_lead" element={<><TodayLead /></>} />
                <Route exact path="/source" element={<><Source /></>} />
                <Route exact path="/setting" element={<Setting />} />
                <Route exact path="/SettingDrawer" element={<SettingDrawer />} />
                <Route exact path="/businesssetting" element={<Businesssetting />} />
                <Route exact path="/businesspersonal" element={<Businesspersonal />} />
                <Route exact path="/businesscategory" element={<Businesscategory />} />
                <Route exact path="/roleandpermission" element={<Roleandpermission />} />
                <Route exact path="/integrationdetailpage" element={<Intgdetapage />} />
                <Route exact path="/analysis" element={<Analysis />} />
                <Route exact path="/Profile" element={<Profile />} />
                <Route exact path="/integration" element={<Integration />} />
                <Route exact path="/template" element={<Template />} />
                <Route exact path="/notification" element={<Notification />} />
                <Route exact path="/security" element={<Security />} />
                <Route exact path="/payment" element={<Payment />} />
                <Route exact path="/user" element={<User />} />
                <Route exact path="/space" element={<Space />} />
                <Route exact path="/navbar" element={<Navbar />} />
              </Routes>
            </main>
          </div>
        )
      }
    }
    else {
      body = (
        <div>
          <main>
            <Controller />
            <Routes>
              <Route exact path="/" element={<><Vieworg /></>} />
            </Routes>
          </main>
        </div>
      );
    }
    return <Router>{body}</Router>;
  }
}

export default Routers;
