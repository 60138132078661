import {

    VIEW_SALE_PRICE,
    VIEW_SALE_LENGTH,
    VIEW_ALL_ORDER,
    VIEW_ALL_NEW_CUSTOMER,
    VIEW_EXECUTIVE_NEW_CUSTOMER,
    VIEW_EXECUTIVE_ORDER,
    VIEW_SALE_PRICE_EXECUTIVE,
    VIEW_SALE_LENGTH_EXECUTIVE,





    MANAGER_OF_THE_MONTH,
    EXECUTIVE_OF_THE_MONTH,
    VIEW_LEAD_DATA_BY_MONTH,
    VIEW_LEAD_DATA_BY_WEEK
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import 'firebase/storage';
import { set_snack_bar } from "../../common/snackbar/action";


// ----------------ALL------------------


// TOTAL SALE PRICE && ALL SOLD PRODUCT
export function viewSalePrice(org_id, startDate, endDate) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_converted_lead_length",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },
                body: JSON.stringify({
                    startDate: startDate,
                    endDate: endDate
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    dispatch({ type: VIEW_SALE_PRICE, payload: responseJson.price });
                    dispatch({ type: VIEW_SALE_LENGTH, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Sales Data Found"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_SALE_PRICE, payload: 0 })
                    dispatch({ type: VIEW_SALE_LENGTH, payload: [] });
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// TOTAL ORDER
export function viewAllOrder(org_id, startDate, endDate) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_follow_up_deal_length",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },
                body: JSON.stringify({
                    startDate: startDate,
                    endDate: endDate
                })

            })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_ORDER, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Order length"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_ORDER, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// ALL NEW CUSTOMER
export function viewAllNewCustomer(org_id, startDate, endDate) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_new_deal_length",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },
                body: JSON.stringify({
                    startDate: startDate,
                    endDate: endDate
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_NEW_CUSTOMER, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_NEW_CUSTOMER, payload: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// ----------------EXECUTIVE------------------

// ALL NEW CUSTOMER BY ID
export function viewExecutiveNewCustomer(executive_id, startDate, endDate) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_new_lead_executive_length",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    executive_id: executive_id,
                    start_date: startDate,
                    end_date: endDate
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_EXECUTIVE_NEW_CUSTOMER, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_EXECUTIVE_NEW_CUSTOMER, payload: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// TOTAL ORDER
export function viewExecutiveOrder(executive_id, startDate, endDate) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_follow_up_lead_executive_length",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    executive_id: executive_id,
                })

            })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_EXECUTIVE_ORDER, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Order length"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_EXECUTIVE_ORDER, payload: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// TOTAL SALE PRICE && ALL SOLD PRODUCT
export function viewSalePriceExecutive(org_id, executive_id, startDate, endDate) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_converted_lead_executive_length",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },
                body: JSON.stringify({
                    executive_id: executive_id,
                    start_date: startDate,
                    end_date: endDate
                })
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    dispatch({ type: VIEW_SALE_PRICE_EXECUTIVE, payload: responseJson.price });
                    dispatch({ type: VIEW_SALE_LENGTH_EXECUTIVE, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Sales Data Found"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_SALE_PRICE_EXECUTIVE, payload: 0 })
                    dispatch({ type: VIEW_SALE_LENGTH_EXECUTIVE, payload: [] });
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}



























// MANAGER OF THE MONTH

export function managerOfTheMonth(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "manager_of_the_month",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },
                body: JSON.stringify({

                })

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: MANAGER_OF_THE_MONTH, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: MANAGER_OF_THE_MONTH, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// EXECUTIVE OF THE MONTH

export function executiveOfTheMonth(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "executive_of_the_month",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },
                body: JSON.stringify({

                })

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: EXECUTIVE_OF_THE_MONTH, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: EXECUTIVE_OF_THE_MONTH, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


// VIEW LEAD DATA BY MONTH

export function viewLeadDataByMonth(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_lead_data_by_month",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },
                body: JSON.stringify({

                })

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_LEAD_DATA_BY_MONTH, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_LEAD_DATA_BY_MONTH, payload: {} })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


// view lead data by week

export function viewLeadDataByWeek(org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_lead_data_by_week",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    org_id: org_id
                },
                // body: JSON.stringify({

                // })

            })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_LEAD_DATA_BY_WEEK, payload: responseJson.result });
                    dispatch(set_snack_bar(responseJson.status, "Some message"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_LEAD_DATA_BY_WEEK, payload: {} })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

































