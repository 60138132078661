import React, { Component } from 'react'
import { Card } from '@mui/material';
// import Time from "../../../assets/img/clock.svg";
import Typography from '@mui/material/Typography';
// import Crmm from "../../pages/image/crmm.png"
import { Link } from 'react-router-dom';
// import Accounts from '../../pages/image/accounts.png'
// import "../../../src/router/components/drawer3.css";
import "./Settingdrawer.css";
import { NavLink } from 'react-router-dom';


export default class SettingDrawer extends Component {

    render() {


        return (
            <Card style={{ borderRadius: "0.25rem", boxShadow: "none", border: "1px solid #E0E0E0" }}>

                <Card style={{ margin: '10px', borderRadius: "0.5rem", boxShadow: "none", border: "1px solid #E0E0E0" }}>
                    <Link style={{ textDecoration: "none", }} to="/businesssetting">
                        <Typography className='text'>
                            Organizational Details
                        </Typography>
                    </Link>
                    <Link style={{ textDecoration: "none", }} to="/businesspersonal">
                        <Typography className='text'>
                            Personal Details
                        </Typography>
                    </Link>
                    <Link style={{ textDecoration: "none", }} to="/businesscategory">
                        <Typography className='text'>
                            Category Setting
                        </Typography>
                    </Link>

                    {/* <Accordion style={{ width: "180px", marginTop: "8%", boxShadow: "none", marginLeft: "30px", }} >
                        <AccordionSummary style={{ marginTop: "-30px" }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        // className='act-hover'
                        >

                            <Typography style={{ fontSize: "16px", marginLeft: "4px", fontWeight: 500, color: "#666", fontFamily: "Lato" }}>
                                Bussiness Setting
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <Accordion style={{ width: "180px", boxShadow: "none", }} >
                                <AccordionSummary style={{ marginTop: "-30px", }}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >

                                    <Typography style={{ fontSize: "16px", fontWeight: 500, color: "#666", fontFamily: "Lato" }}>
                                        Account Setting
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails>


                                    

                                </AccordionDetails>








                            </Accordion>
                           
                            {/* <Link style={{ textDecoration: "none", }} to="/roleandpermission">
                                <Typography className="da-btn" style={{ fontSize: "16px", marginLeft: "15px", fontWeight: 500, color: "#666", fontFamily: "Lato" }}>
                                    Roles & Permission
                                </Typography>
                                </Link> 
                </AccordionDetails>
            </Accordion> * /}




                    {/* <Link 
                        className='act-hover'  style={{ textDecoration: "none", }} to="/payment">
                            <Typography 
                        className='act-hover' style={{ marginLeft: "50px", marginTop: "5px", fontSize: "16px", fontWeight: 500, color: "#666", fontFamily: "Lato" }}>
                                Payment
                            </Typography>

                        </Link> */}


                    {/* <Link style={{ textDecoration: "none", }} to="/integration">
                            <Typography style={{ marginLeft: "50px", marginTop: "5px", fontSize: "16px", fontWeight: 500, color: "#666", fontFamily: "Lato" }}>

                                Integration
                            </Typography>
                        </Link> */}


                    {/* <Link style={{ textDecoration: "none" }} to="/template">
                            <Typography style={{ marginLeft: "50px", marginTop: "5px", fontSize: "16px", fontWeight: 500, color: "#666", fontFamily: "Lato" }}>

                                Template
                            </Typography>
                        </Link> */}

                    {/* <Link style={{ textDecoration: "none", }} to="/notification">
                            <Typography style={{ marginLeft: "50px", marginTop: "5px", fontSize: "16px", fontWeight: 500, color: "#666", fontFamily: "Lato" }}>
                                Notification
                            </Typography>
                        </Link> */}



                    {/* <Link style={{ textDecoration: "none", }} to="/security">
                            <Typography style={{ marginLeft: "50px", marginTop: "5px", fontSize: "16px", fontWeight: 500, color: "#666", fontFamily: "Lato" }}>
                                Security
                            </Typography>
                        </Link> */}

                </Card >



            </Card >
        )
    }
}