import React, { Component } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import "../../sales/Components/sales.css";
import { Table, Grid } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CanvasJSReact from '@canvasjs/react-charts';
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
import Pagination from "@mui/material/Pagination";
import "../../../pages/allotment/Components/allotment.css";
import { TabContent } from "react-bootstrap";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box
} from '@mui/material'


var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class Adminsales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createSpace: false,
      isHidden: true,
      showTime: false,
      copied: false,
      value: '1',
      Adminsalesdate: [
        {
          Date: "10/12/23",
          taskname: "",
          productname: "Plastic Drink Bottles ",

          Product_id: "#1224",
          Transaction_id: "#458963210257001",
          Payment_Mode: "UPI",
        },
        {
          Date: "10/12/23",
          taskname: "",
          productname: "Plastic Drink Bottles ",

          Product_id: "#1224",
          Transaction_id: "#458963210257001",
          Payment_Mode: "UPI",
        },

        {
          Date: "10/12/23",
          taskname: "",
          productname: "Plastic Drink Bottles ",

          Product_id: "#1224",
          Transaction_id: "#458963210257001",
          Payment_Mode: "UPI",
        },
      ],
      adminorgdata: [
        {
          productname: "Bottle",
          qty: "Qty - 300",
        },
        {
          productname: "Bottle",
          qty: "Qty - 300",
        },
        {
          productname: "Bottle",
          qty: "Qty - 300",
        },
        {
          productname: "Bottle",
          qty: "Qty - 300",
        },
        {
          productname: "Bottle",
          qty: "Qty - 300",
        },

      ],
      adminpaymentdata: [
        {
          Date: "10/12/23",
          taskname: "",
          productname: "Plastic Drink Bottles ",

          Product_id: "#1224",
          Transaction_id: "#458963210257001",
          Payment_Mode: "UPI",
        },
        {
          Date: "10/12/23",
          taskname: "",
          productname: "Plastic Drink Bottles ",

          Product_id: "#1224",
          Transaction_id: "#458963210257001",
          Payment_Mode: "UPI",
        },

        {
          Date: "10/12/23",
          taskname: "",
          productname: "Plastic Drink Bottles ",

          Product_id: "#1224",
          Transaction_id: "#458963210257001",
          Payment_Mode: "UPI",
        },
      ],
      page: 0,
      setPage: 0,
      rowsPerPage: 10,

    };
  }

  handleDateChange = (e) => {
    const rawDate = e.target.value; // The raw date string from the input
    const formattedDate = this.formatDate(rawDate); // Format the date
    this.props.searchSaleByDate(this.props.sales.view_all_sales_by_org_id, formattedDate)
  }

  handleDateChange2 = (e) => {
    const rawDate = e.target.value; // The raw date string from the input
    const formattedDate = this.formatDate(rawDate); // Format the date

    this.props.searchPaymentByDate(this.props.sales.view_all_sales_by_product_and_payment, formattedDate)
  }

  formatDate(rawDate) {
    // Assuming rawDate is in the format 'yyyy-MM-dd'
    const parts = rawDate.split('-');
    if (parts.length === 3) {
      const [year, month, day] = parts;
      return `${year}-${month}-${day}`;
    }
    return rawDate; // Return the original date if it's not in the expected format
  }

  componentDidMount() {
    this.props.viewAllSalesByOrgId(this.props.login.org_id, this.state.page, this.state.rowsPerPage);
    this.props.viewAllSalesPaymentByOrgId(this.props.login.org_id, this.state.page, this.state.rowsPerPage);
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    // const { snackbar, close_snack_bar } = this.props;
    const options = {
      height: 240,
      data: [
        {
          // Change type to "doughnut", "line", "splineArea", etc.
          type: "column",
          dataPoints: [
            { label: "Cheque", y: (this.props.sales.view_all_sales_by_product_and_payment.filter(item => item.paid_by === "Cheque").length), color: "#8F8F8F" },
            { label: "Card", y: (this.props.sales.view_all_sales_by_product_and_payment.filter(item => item.paid_by === "Card").length), color: "#79A495" },
            { label: "Net Banking", y: (this.props.sales.view_all_sales_by_product_and_payment.filter(item => item.paid_by === "Net Banking").length), color: "#CC542E" },
            { label: "Cash", y: (this.props.sales.view_all_sales_by_product_and_payment.filter(item => item.paid_by === "Cash").length), color: "#79A495" },
            { label: "UPI", y: (this.props.sales.view_all_sales_by_product_and_payment.filter(item => item.paid_by === "UPI").length), color: "#8F8F8F" },
          ]
        }
      ],
      axisY: {
        gridThickness: 0,
        lineThickness: 0,
        tickLength: 0,
        labelFormatter: function () {
          return "";
        }
      },
    }
    const handleChangePage = (event, newPage) => {
      this.props.viewAllSalesByOrgId(
        this.props.login.org_id,
        newPage - 1,
        this.state.rowsPerPage
      );
      this.setState({
        page: newPage,
      });
    };

    return (
      <Grid>
        <Box>
          <TabContext value={this.state.value}>
            <Box sx={{ marginBottom: "-2%" }}>
              <TabList onChange={this.handleChange} aria-label="lab API tabs example">
                <Tab style={{ fontSize: "20px", fontWeight: 500 }} label="Stock Sold" value="1" />
                <Tab style={{ fontSize: "20px", fontWeight: 500 }} label="Payments" value="2" />
              </TabList>
            </Box>

            <TabPanel value="1">
              <FormControl sx={{ m: 1, width: '25ch', marginTop: '-4%', float: 'right' }}>
                <input
                  type="date"
                  className="date-input"
                  onChange={this.handleDateChange}
                />
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <TableContainer component={Paper} style={{ borderRadius: '0px' }}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead style={{ background: "lightgray" }}>
                        <TableRow>
                          <TableCell>SI no.</TableCell>
                          <TableCell style={{ textAlign: "center" }}>Date</TableCell>
                          <TableCell style={{ textAlign: "center" }}> Product Name</TableCell>
                          <TableCell style={{ textAlign: "center" }}> Product Price </TableCell>
                          <TableCell style={{ textAlign: "center" }}>Category</TableCell>
                          <TableCell style={{ textAlign: "center" }}>Sold</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {Array.isArray(this.props.sales.search_sale) && this.props.sales.search_sale.map((item, index) => {
                          return (
                            <TableRow>
                              <TableCell> {index + 1}</TableCell>
                              <TableCell style={{ textAlign: "center" }}> {item?.create_date}</TableCell>
                              <TableCell style={{ textAlign: "center" }}> {item?.product_name}</TableCell>
                              <TableCell style={{ textAlign: "center" }}> {item?.product_price}</TableCell>
                              <TableCell style={{ textAlign: "center" }}> {item?.categoryDetails?.cate_name}</TableCell>
                              <TableCell style={{ textAlign: "center" }}> {item?.leadDetails?.name}</TableCell>
                            </TableRow>
                          );
                        })
                        }
                      </TableBody>
                    </Table>
                    <Pagination style={{ display: "flex", justifyContent: "flex-end", padding: '1%' }}
                      count={parseInt(this.props.sales.sale_length / 10) + 1}
                      size="small"
                      className='pagination'
                      onChange={handleChangePage}
                    />
                  </TableContainer>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Grid style={{ backgroundColor: "#E3E3E3" }}>
                    <center>
                      <Typography
                        className="Top"
                      >
                        Top 5 Sold Product
                      </Typography>
                    </center>
                  </Grid>
                  <Card style={{
                    boxShadow: "2px 2px 15px rgba(0, 0, 0, 0.2)", marginTop: '5%'
                  }}>



                    <Grid style={{ boxShadow: "none" }}>
                      {/* maxHeight: "300", overflowY: "scroll" */}

                      {Array.isArray(this.props.sales.view_all_sales_by_org_id) &&
                        this.props.sales.view_all_sales_by_org_id
                          .sort((a, b) => b?.productDetails?.product_stock - a?.productDetails?.product_stock)
                          .slice(0, 5).map((item) => {

                            return (
                              <Box
                                style={{
                                  display: "flex",
                                  margin: 20
                                }}
                              >
                                <img alt="" style={{ height: 50, width: 50 }} src={item?.productDetails?.product_img} />

                                <Grid>

                                  <Typography
                                    style={{
                                      color: "black",
                                      display: "flex",
                                      fontSize: "15px",
                                      marginTop: "3px",
                                      marginLeft: "20px",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {item.product_name}
                                  </Typography>

                                  <Typography
                                    style={{
                                      color: "gray",
                                      display: "flex",
                                      fontSize: "15px",
                                      marginLeft: "20px",
                                      fontWeight: 400,
                                      marginTop: "4x%"
                                    }}
                                  >
                                    {item?.productDetails?.product_stock}
                                  </Typography>
                                </Grid>

                              </Box>
                            )
                          })}
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value="2">
              <FormControl sx={{ m: 1, width: '25ch', marginTop: '-4%', float: 'right' }}>
                <input
                  type="date"
                  className="date-input"
                  onChange={this.handleDateChange2}
                />
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <TableContainer component={Paper} style={{ borderRadius: '0px' }}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead style={{ background: "lightgray" }}>
                        <TableRow>
                          <TableCell>SI no.</TableCell>
                          <TableCell style={{ textAlign: "center" }}>Date</TableCell>
                          <TableCell style={{ textAlign: "center" }}> Product Name</TableCell>
                          <TableCell style={{ textAlign: "center" }}>Product Price</TableCell>
                          <TableCell style={{ textAlign: "center" }}>Amount Paid</TableCell>
                          <TableCell style={{ textAlign: "center" }}>Payment Mode</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(this.props.sales.search_payment) && this.props.sales.search_payment.map((item, index) => {
                          return (
                            <TableRow>
                              <TableCell> {index + 1}</TableCell>
                              <TableCell style={{ textAlign: "center" }}> {item.create_date}</TableCell>
                              <TableCell style={{ textAlign: "center" }}> {item?.dealDetails?.product_name}</TableCell>
                              <TableCell style={{ textAlign: "center" }}>  {item?.dealDetails?.product_price}</TableCell>
                              <TableCell style={{ textAlign: "center" }}> {item.amount}</TableCell>
                              <TableCell style={{ textAlign: "center" }}> {item.paid_by}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <Pagination style={{ display: "flex", justifyContent: "flex-end", padding: '1%' }}
                      count={parseInt(this.props.sales.payment_length / 10) + 1}
                      size="small"
                      className='pagination'
                      onChange={handleChangePage}
                    />
                  </TableContainer>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Grid style={{ backgroundColor: "#E3E3E3" }}>
                    <center>
                      <Typography
                        className="Top"
                      >
                        Mode of Payment
                      </Typography>
                    </center>
                  </Grid>
                  <Card style={{
                    boxShadow: "2px 2px 15px rgba(0, 0, 0, 0.2)", marginTop: '5%'
                  }}>
                    <Card style={{ boxShadow: "none", padding: 10 }}>
                      <CanvasJSChart options={options} />
                    </Card>
                  </Card>
                </Grid>

              </Grid>
            </TabPanel>

          </TabContext>
        </Box>
        <LoaderCon />
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
      </Grid>
    );
  }
}
