import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../Components/newdashboard";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import { viewAllManager, viewAllExecutiveByAdmin } from "../../employee/actions"
import { viewAllSalesByOrgId } from "../../sales/action"
import {
  viewSalePrice,
  viewAllOrder,
  viewAllNewCustomer,
  viewExecutiveNewCustomer,
  viewExecutiveOrder,
  viewSalePriceExecutive,


  managerOfTheMonth,
  executiveOfTheMonth,
  viewLeadDataByWeek
} from "../action"




export class Controller extends Component {
  render() {
    return (
      <Dashboard {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    employee: store.employee,
    login: store.login,
    snackbar: store.snackbar,
    sales: store.sales,
    dashboard: store.dashboard
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewSalePrice: (org_id, startDate, endDate) => {
      dispatch(viewSalePrice(org_id, startDate, endDate));
    },
    viewAllOrder: (org_id, startDate, endDate) => {
      dispatch(viewAllOrder(org_id, startDate, endDate));
    },
    viewAllNewCustomer: (org_id, startDate, endDate) => {
      dispatch(viewAllNewCustomer(org_id, startDate, endDate));
    },
    viewExecutiveNewCustomer: (executive_id, startDate, endDate) => {
      dispatch(viewExecutiveNewCustomer(executive_id, startDate, endDate));
    },
    viewExecutiveOrder: (executive_id, startDate, endDate) => {
      dispatch(viewExecutiveOrder(executive_id, startDate, endDate));
    },
    viewSalePriceExecutive: (org_id, executive_id, startDate, endDate) => {
      dispatch(viewSalePriceExecutive(org_id, executive_id, startDate, endDate));
    },









    viewAllManager: (org_id) => {
      dispatch(viewAllManager(org_id));
    },

    viewAllExecutiveByAdmin: (admin_id, org_id) => {
      dispatch(viewAllExecutiveByAdmin(admin_id, org_id));
    },

    viewAllSalesByOrgId: (org_id) => {
      dispatch(viewAllSalesByOrgId(org_id));
    },







    managerOfTheMonth: (org_id) => {
      dispatch(managerOfTheMonth(org_id));
    },

    executiveOfTheMonth: (org_id) => {
      dispatch(executiveOfTheMonth(org_id));
    },

    viewLeadDataByWeek: (org_id) => {
      dispatch(viewLeadDataByWeek(org_id));
    },


  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);