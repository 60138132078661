import React, { Component } from "react";
import { connect } from "react-redux";
import Adminsales from "../Components/Adminsales";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  viewAllSalesByOrgId,
  searchSaleByDate,
  viewAllSalesPaymentByOrgId,
  searchPaymentByDate
} from "../action"


export class Controller extends Component {
  render() {
    return (
      <Adminsales {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    sales: store.sales,
    login: store.login,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return { 
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewAllSalesByOrgId: (org_id,page_number,rowper_page) => {
      dispatch(viewAllSalesByOrgId(org_id,page_number,rowper_page));
    },
    viewAllSalesPaymentByOrgId: (org_id,page_number,rowper_page) => {
      dispatch(viewAllSalesPaymentByOrgId(org_id, page_number, rowper_page));
    },
    searchSaleByDate: (view_all_sales_by_org_id, e) => {
      dispatch(searchSaleByDate(view_all_sales_by_org_id, e));
    },
    searchPaymentByDate: (view_all_sales_by_product_and_payment, e) => {
      dispatch(searchPaymentByDate(view_all_sales_by_product_and_payment, e));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);