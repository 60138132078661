import React, { Component } from "react";
import { connect } from "react-redux";
import Employee from "../Components/Employee";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  viewAllCategory,
  addManager,
  viewAllManager,
  updateManager,
  addExecutiveByAdmin,
  viewAllExecutive, viewProductUser,
  updateExecutive, deleteManager,
  viewAllExecutiveByAdmin, SearchManager,
  searchExecutiveByAdmin, setManagerId,
  setManagerName, deleteExecutive,
  transferExecutiveLead, transferManagerLead,
  addExecutiveByManager, view_executive_by_manager,
  setExecutiveDetails, viewAllManagerByAdmin,
  search_executive_by_manager,
  searchMANAGER, searchEXECUTIVE,
  addExecutive,
  viewExecutiveUser
} from '../actions'

import { updatePersonalDetails, deleteEmployee } from "../actions"
export class Controller extends Component {
  render() {
    return (
      <Employee {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    employee: store.employee,
    login: store.login,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

    viewAllCategory: (org_id) => {
      dispatch(viewAllCategory(org_id));
    },

    addManager: (admin_id, org_id,  email, role, product, token, page_number, rowper_page) => {
      dispatch(addManager(admin_id, org_id,  email,  role, product, token, page_number, rowper_page));
    },

    viewAllManager: (org_id) => {
      dispatch(viewAllManager(org_id));
    },

    updateManager: (manager_id, fullname, email, mobile, employeeID, role, admin_id, org_id, page_number, rowper_page) => {
      dispatch(updateManager(manager_id, fullname, email, mobile, employeeID, role, admin_id, org_id, page_number, rowper_page));
    },

    addExecutiveByAdmin: (org_id, admin_id, fullname, email, mobile, employeeID, role, page_number, rowper_page) => {
      dispatch(addExecutiveByAdmin(org_id, admin_id, fullname, email, mobile, employeeID, role, page_number, rowper_page));
    },

    viewAllExecutive: () => {
      dispatch(viewAllExecutive());
    },

    updateExecutive: (_id, fullname, email, mobile, employeeID, role, admin_id, org_id, page_number, rowper_page) => {
      dispatch(updateExecutive(_id, fullname, email, mobile, employeeID, role, admin_id, org_id, page_number, rowper_page));
    },
    deleteManager: (manager_id, admin_id, org_id, page_number, rowper_page) => {
      dispatch(deleteManager(manager_id, admin_id, org_id, page_number, rowper_page));
    },

    viewAllExecutiveByAdmin: (admin_id, org_id, page_number, rowper_page) => {
      dispatch(viewAllExecutiveByAdmin(admin_id, org_id, page_number, rowper_page));
    },
    viewAllManagerByAdmin: (admin_id, org_id, page_number, rowper_page) => {
      dispatch(viewAllManagerByAdmin(admin_id, org_id, page_number, rowper_page));
    },

    SearchManager: (admin_id, org_id, page_number, rowper_page, name) => {
      dispatch(SearchManager(admin_id, org_id, page_number, rowper_page, name));
    },

    searchExecutiveByAdmin: (admin_id, org_id, page_number, rowper_page, name) => {
      dispatch(searchExecutiveByAdmin(admin_id, org_id, page_number, rowper_page, name));
    },
    search_executive_by_manager: (manager_id, org_id, page_number, rowper_page, name) => {
      dispatch(search_executive_by_manager(manager_id, org_id, page_number, rowper_page, name));
    },

    setManagerId: (payload) => {
      dispatch(setManagerId(payload))
    },
    setManagerName: (payload) => {
      dispatch(setManagerName(payload))
    },

    deleteExecutive: (executive_id, admin_id, org_id, page_number, rowper_page) => {
      dispatch(deleteExecutive(executive_id, admin_id, org_id, page_number, rowper_page))
    },

    transferExecutiveLead: (executive_id, _id, admin_id, org_id) => {
      dispatch(transferExecutiveLead(executive_id, _id, admin_id, org_id))
    },

    transferManagerLead: (manager_id, _id) => {
      dispatch(transferManagerLead(manager_id, _id))
    },

    addExecutive: (org_id, user_id,  email,  product, token, page_number, rowper_page) => {
      dispatch(addExecutive(org_id, user_id,  email,  product, token, page_number, rowper_page))
    },

    view_executive_by_manager: (manager_id, org_id, page_number, rowper_page) => {
      dispatch(view_executive_by_manager(manager_id, org_id, page_number, rowper_page))
    },

    setExecutiveDetails: (payload) => {
      dispatch(setExecutiveDetails(payload))
    },
    viewProductUser: (org_id, product, admin_id, token, type, page_number, rowper_page, name) => {
      dispatch(viewProductUser(org_id, product, admin_id, token, type, page_number, rowper_page, name))
    },
    viewExecutiveUser: (org_id, product, admin_id, token, type, page_number, rowper_page, name) => {
      dispatch(viewExecutiveUser(org_id, product, admin_id, token, type, page_number, rowper_page, name))
    },
    updatePersonalDetails: (org_id, user_id, name, email, mobile) => {
      dispatch(updatePersonalDetails(org_id, user_id, name, email, mobile));
    },
    deleteEmployee: (_id, org_id, product, admin_id, token, type, page_number, rowper_page) => {
      dispatch(deleteEmployee(_id, org_id, product, admin_id, token, type, page_number, rowper_page));
    },
    searchMANAGER: (all_manager, e) => {
      dispatch(searchMANAGER(all_manager, e));
    },
    searchEXECUTIVE: (all_executive, e) => {
      dispatch(searchEXECUTIVE(all_executive, e));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);