import React, { Component } from 'react';
import {
  Grid, Typography, Box, Button, TextField, Tooltip, DialogTitle,
  Dialog,
} from '@mui/material';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Style from "../../settings/Components/payment.css";
import SettingDrawer from "../../settings/Components/SettingDrawer";
import AddIcon from '@mui/icons-material/Add';
import QRcode from "../../image/qr-code 1.png";
import { MoreVertOutlined } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import CameraAltIcon from '@mui/icons-material/CameraAlt';


export default class payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: false,
      // image: "",
      image: null,
      isHidden3: true,
      isHidden2: true,
      addDetail: false,
      addUPIDetail: false,
      org_id: " ",
      bank_name: " ",
      account_number: " ",
      account_holder_name: " ",
      branch: " ",
      ifsc_code: " ",
      upi_id: " ",
      Qr_id: " ",
    }
  }
  toggleElement = () => {
    this.setState((prevState) => ({
      isHidden: !prevState.isHidden,
    }));
  };


  toggleElement2 = () => {
    this.setState((prevState) => ({
      isHidden2: !prevState.isHidden2,
    }));
    this.setState({ addUPIDetail: false })
  }

  toggleElement3 = () => {
    this.setState((prevState) => ({
      isHidden3: !prevState.isHidden3,
    }));
    this.setState({ addDetail: false })
  }


  componentDidMount() {
    this.props.viewAllBank();
    this.props.viewAllUpi(this.props.login.org_id);

  }
  // handleFileUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     this.parseExcelData(file);
  //   }
  // };

  // handleImageChange = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       this.setState({ image: reader.result });
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  render() {
    const { image } = this.state;
    return (
      <>
        <Grid style={{ marginTop: "10%", display: "flex", gap: 30 }}>


          <Grid>
            <SettingDrawer />
          </Grid>

          <Grid style={{ marginTop: "2%", marginLeft: "2%" }}>
            <Typography style={{ fontSize: "28px", fontWeight: "500" }}>Payment</Typography>
            <Grid className='tab-plus'>

              <Grid style={{ marginTop: "5%" }}>
                <Tabs>
                  <TabList style={{ borderBottom: "none", display: "flex", gap: "28px",justifyContent:"space-between",flexDirection:"row" }}>
                    <Grid style={{display:"flex",flexDirection:"row",gap:"20px"}}>
                    <Tab className="pay-tab" onClick={this.toggleElement}>Bank Details</Tab>
                    <Tab className="pay-tab" onClick={this.toggleElement}>UPI Details</Tab>
                    </Grid>

                    <Grid style={{ }}>
                      {this.state.isHidden ?
                        <Tooltip title="Add">
                          <AddIcon

                            style={{ background: 'gray', color: "white", borderRadius: "4px" }}

                            onClick={() => {
                              this.setState({ addDetail: true });
                            }}
                          />
                        </Tooltip>
                        :
                        <Tooltip title="Add">
                          <AddIcon

                            style={{ background: 'gray', color: "white", borderRadius: "4px" }}

                            onClick={() => {
                              this.setState({ addUPIDetail: true });
                            }}
                          />
                        </Tooltip>
                      }

                    </Grid>

                  </TabList>

                  <TabPanel style={{ marginTop: "5%" }}>
                    <Typography style={{ fontSize: "13px" }}>Manage your bank accounts for your business</Typography>
                    {/* <Grid style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:"10px"}}> */}
                    {Array.isArray(this.props.setting.viewAllBank) && this.props.setting.viewAllBank.map((item) => {
                      return (
                        <Grid container style={{width:"100%"}}>
                          <Grid item style={{ border: "2px dotted gray", textAlign: "left", marginTop: "14%", padding: "12px",width:"200px" }}>
                            <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                              <Typography>Primary </Typography>
                              <MoreVertOutlined style={{ color: "blue" }} />
                            </Box>
                            <Box>
                              <Typography>{item.bank_name !== (undefined || null) ? item.bank_name : "---"}</Typography>
                              <Typography>{item.account_holder_name !== (undefined || null) ? item.account_holder_name : "---"}</Typography>
                              <Typography>{item.account_number !== (undefined || null) ? item.account_number : "---"}</Typography>
                              <Typography>{item.branch !== (undefined || null) ? item.branch : "---"}</Typography>
                              <Typography>{item.ifsc_code !== (undefined || null) ? item.ifsc_code : "---"}</Typography>
                            </Box>
                          </Grid>
                         </Grid>
                      );
                    })}

{/* </Grid> */}
                  </TabPanel>

                  <TabPanel style={{ marginTop: "5%" }}>
                    <Typography style={{ fontSize: "13px" }}>
                      Manage your UPI IDs for your business
                    </Typography>
                    {Array.isArray(this.props.setting.viewAllUpi) && this.props.setting.viewAllUpi.map((item) => {
                      return (
                        <Grid style={{ marginTop: "14%", display: "flex", justifyContent: "space-around", gap: "17px" }}>
                          <Box >
                            <Typography>UPI ID -</Typography>
                            <TextField id="standard-basic" variant="standard" value={item.upi_id !== (undefined || null) ? item.upi_id : "---"} />
                          </Box>
                          {/* <Box style={{ border: "1px solid gray" }}>
                          <img src={this.state.Qr_id !== (undefined || null) ? this.state.Qr_id : "---"} style={{ margin: "7px" }} />
                        </Box> */}
                        </Grid>
                      );
                    })}
                  </TabPanel>

                </Tabs>
              </Grid>

              {/* <Grid>
                <Tooltip title="Add">
                  <AddIcon

                    style={{ background: 'gray', color: "white", borderRadius: "4px" }}

                    onClick={() => {
                      this.setState({ addDetail: true });
                    }}
                  />
                </Tooltip>

              </Grid> */}

            </Grid>
          </Grid>




        </Grid>


        {/* bank details  */}
        <Dialog
          open={this.state.addDetail} style={{}}>
          <Grid style={{ height: "370px", width: "300px" }}>

            <Box style={{ textAlign: "center", marginLeft: "15%", paddingTop: "3%" }}>
              <Grid style={{ marginTop: "5%" }}>

                <Grid style={{ textAlign: "left", marginLeft: "4%", marginTop: "1%" }} >
                  <Typography >Bank Name-</Typography>
                  <TextField id="standard-basic" variant="standard"
                    onChange={(e) => {
                      this.setState({ bank_name: e.target.value })
                    }}
                  />
                </Grid>
                <Grid style={{ textAlign: "left", marginLeft: "4%", marginTop: "1%" }} >
                  <Typography >Account Holder's Name-</Typography>
                  <TextField id="standard-basic" variant="standard"
                    onChange={(e) => {
                      this.setState({ account_holder_name: e.target.value })
                    }}
                  />
                </Grid>
                <Grid style={{ textAlign: "left", marginLeft: "4%", marginTop: "1%" }} >
                  <Typography >Account Number-</Typography>
                  <TextField id="standard-basic" variant="standard"
                    onChange={(e) => {
                      this.setState({ account_number: e.target.value })
                    }}
                  />
                </Grid>
                <Grid style={{ textAlign: "left", marginLeft: "4%", marginTop: "1%" }} >
                  <Typography >Branch-</Typography>
                  <TextField id="standard-basic" variant="standard"
                    onChange={(e) => {
                      this.setState({ branch: e.target.value })
                    }}
                  />
                </Grid>
                <Grid style={{ textAlign: "left", marginLeft: "4%", marginTop: "1%" }} >
                  <Typography >IFSC Code-</Typography>
                  <TextField id="standard-basic" variant="standard"
                    onChange={(e) => {
                      this.setState({ ifsc_code: e.target.value })
                    }}
                  />
                </Grid>


                <Grid style={{ marginTop: "3%", textAlign: "center", marginRight: "50px" }}>

                  <button
                    onClick={() => {

                      this.props.addBank(this.props.login.org_id, this.state.bank_name, this.state.account_number, this.state.account_holder_name, this.state.branch, this.state.ifsc_code)
                      
                      this.toggleElement3();
                    }}
                    // onClick={this.toggleElement3}
                    variant='contained' style={{ background: "gray", color: "white", padding: "7px 15px", cursor: "pointer", border: "none", outline: "none" }}>Add</button>
                </Grid>
              </Grid>




            </Box>

          </Grid>
        </Dialog>

        {/* upi details */}

        <Dialog
          open={this.state.addUPIDetail} style={{}}>
          <Grid style={{ height: "200px", width: "300px" }}>

            <Box style={{ textAlign: "center", marginLeft: "15%", paddingTop: "3%" }}>
              <Grid style={{ marginTop: "5%" }}>

                <Grid style={{ textAlign: "left", marginTop: "1%" }} >
                  <Typography >UPI ID-</Typography>
                  <TextField id="standard-basic" variant="standard"
                    onChange={(e) => {
                      this.setState({ upi_id: e.target.value })
                    }}
                  />
                </Grid>


                {/* <Grid style={{ display: "flex", gap: "30px", marginTop: "5%" }}>
                  <Box style={{ textAlign: "left" }}>
                    <Typography style={{ fontSize: "14px" }}>Upload your QR code :</Typography>

                    <label htmlFor="file" className='label-mentor' style={{ color: "red", fontSize: "9px", cursor: "pointer" }}>
                      Click here to upload
                    </label>
                  </Box>

                  <Box
                    style={{
                      cursor: "pointer",
                      border: "1px solid lightgray",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center"
                    }}


                  >

                    <Avatar
                      variant="square"
                      src={this.state.Qr_id ? URL.createObjectURL(this.state.Qr_id) : ""}
                      style={{
                        marginLeft: "auto", marginRight: "auto", width: "48px",
                        height: "48px", backgroundColor: "#fff"
                      }}
                    >
                      <input
                        style={{ display: "none", cursor: "pointer" }}
                        type="file"
                        id="file"
                        onChange={(e) => {
                          
                          if (e.target.files && e.target.files.length > 0) {
                            this.setState({ Qr_id: e.target.files[0] });
                          }
                        }}
                      />
                     
                    </Avatar>
                  </Box>

                 


                </Grid> */}



                <Grid style={{ marginTop: "10%", textAlign: "center", marginRight: "50px" }}>

                  <button
                    onClick={() => {

                      this.props.addUPI(this.props.login.org_id, this.state.upi_id,)
                      
                      this.toggleElement2();
                    }}
                    // onClick={this.toggleElement2}
                    variant='contained' style={{ background: "gray", color: "white", padding: "7px 15px", cursor: "pointer", border: "none", outline: "none", borderRadius: 5 }}>Add</button>
                </Grid>
              </Grid>




            </Box>

          </Grid>
        </Dialog>


      </>
    )
  }
}
