import React, { Component } from "react";
import { connect } from "react-redux";
import Adminallotment from "../Components/Adminallotment";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
  viewAllotmentByOrg_id,
  addAllortment,
  deleteAllotment,
  filterAllotmentByProgress,
  updateAllortment,
  search_country,
  checkedLead,
  allSelect, clearSelect,
  searchDeals
} from "../actions"

import{
  viewAllExecutive,
  viewAllManager,
  viewProductUser,
  viewAllExecutiveByAdminNoPage
} from "../../employee/actions"

import{
  viewAllLead
} from "../../leads/action"

import{
  viewProductByOrgId
} from "../../product/Action"

import { view_lead_details, setLeadDetails } from '../../leads/action'
import { viewAllCategory } from '../../settings/action'

export class Controller extends Component {
  render() {
    return (
      <Adminallotment {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    allotment: store.allotment,
    employee:store.employee,
    login: store.login,
    snackbar: store.snackbar,
    lead: store.lead,
    product: store.product,
    setting: store.setting

  };
};
export const mapDispatchToProps = dispatch => {
  return { 
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewAllotmentByOrg_id: (org_id) => {
      dispatch(viewAllotmentByOrg_id(org_id));
    },
    checkedLead: (data, index) => {
      dispatch(checkedLead(data, index));
    },
    allSelect: (data) => {
      dispatch(allSelect(data));
    },
    viewAllExecutive: (token,org_id,type) => {
      dispatch(viewAllExecutive(token,org_id,type));
    },
    viewAllLead: (org_id) => {
      dispatch(viewAllLead(org_id));
    },
    deleteAllotment: (org_id, allortment_id) => {
      dispatch(deleteAllotment(org_id, allortment_id));
    },
    filterAllotmentByProgress: (all_allotment_by_org_id, e, type) => {
      dispatch(filterAllotmentByProgress(all_allotment_by_org_id, e, type));
    },
    addAllortment: ( org_id, user_id, assign_to, leads, due_date, priority, allortment_desc, status) => {
      dispatch(addAllortment(org_id, user_id, assign_to, leads, due_date, priority, allortment_desc, status));
    },
    updateAllortment: (allortment_id, manager_id, leads, due_date, allortment, priority, allortment_desc, status) => {
      dispatch(updateAllortment(allortment_id, manager_id, leads, due_date, allortment, priority, allortment_desc, status));
    },
    viewProductByOrgId: (org_id) => {
      dispatch(viewProductByOrgId(org_id));
    },

    viewAllManager: (org_id) => {
      dispatch(viewAllManager(org_id));
    },
    view_lead_details: (lead_id) => {
      dispatch(view_lead_details(lead_id));
    },
    setLeadDetails: (payload) => {
      dispatch(setLeadDetails(payload));
  },

    viewProductUser: (org_id, product) => {
      dispatch(viewProductUser(org_id, product))
    },
    search_country: (org_id,countryname, statename,cityname) => {
      dispatch(search_country(org_id, countryname, statename, cityname));
    },
    clearSelect: () => {
      dispatch(clearSelect());
    },
    viewAllCategory: (org_id) => {
      dispatch(viewAllCategory(org_id));
    },
    viewAllExecutiveByAdminNoPage: (admin_id, org_id) => {
      dispatch(viewAllExecutiveByAdminNoPage(admin_id, org_id));
    },
    searchDeals: (org_id, country, state, city, product, category, status, executive_id) => {
      dispatch(searchDeals(org_id, country, state, city, product, category, status, executive_id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);