export const VIEW_ALL_PRODUCT = "VIEW_ALL_PRODUCT";
export const VIEW_ALL_PRODUCT_WITH_PGN = "VIEW_ALL_PRODUCT_WITH_PGN";
export const VIEW_PRODUCT_BY_ID = "VIEW_PRODUCT_BY_ID";
export const VIEW_PRODUCT_BY_ORG_ID = "VIEW_PRODUCT_BY_ORG_ID";
export const VIEW_All_SOLD_PRODUCT = "VIEW_All_SOLD_PRODUCT";
export const SET_PRODUCT_ID = "SET_PRODUCT_ID";
export const SEARCH_PRODUCT = "SEARCH_PRODUCT";
export const PRODUCT = "PRODUCT";

export const SEARCH_PRODUCT_BY_ADMIN = "SEARCH_PRODUCT_BY_ADMIN";


