import {
    PRODUCT,
    VIEW_ALL_PRODUCT,
    VIEW_ALL_PRODUCT_WITH_PGN,
    VIEW_PRODUCT_BY_ID,
    VIEW_PRODUCT_BY_ORG_ID,
    VIEW_All_SOLD_PRODUCT,
    SEARCH_PRODUCT_BY_ADMIN,

} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import { set_snack_bar } from "../../common/snackbar/action";
import 'firebase/storage';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

////////////////firebase......................................

export function uploadImageProduct(org_id, product_img, product_shordId, product_name, product_price, product_stock, product_desc, product_type) {
    return dispatch => {

        dispatch(setLoader(true));
        if (product_img !== "") {
            const storageRef = ref(getStorage(), "/Product/" + product_img + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, product_img);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {

                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },

                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (product_img) {
                        // 
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            // 
                            dispatch(addProduct(org_id, product_img, product_shordId, product_name, product_price, product_stock, product_desc, product_type));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(addProduct(org_id, product_img, product_shordId, product_name, product_price, product_stock, product_desc, product_type));
        }
    }
}

export function updateimageproduct(org_id, product_id, old_product_img, product_img, product_shortId, product_name, product_price, product_stock, product_desc, product_type) {
    return dispatch => {
        dispatch(setLoader(true));
        if (product_img !== "") {
            const storageRef = ref(getStorage(), "/Product/" + product_img + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, product_img);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {

                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },

                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (product_img) {
                        // 
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            // 
                            dispatch(updateproduct(org_id, product_id, product_img, product_shortId, product_name, product_price, product_stock, product_desc, product_type));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(updateproduct(org_id, product_id, old_product_img, product_shortId, product_name, product_price, product_stock, product_desc, product_type));
        }
    }
}


///////////////////...........................add product.......................................///

export function addProduct(org_id, product_img, product_shortId, product_name, product_price, product_stock, product_desc, product_type) {

    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_product", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({


                product_img: product_img,
                product_shortId: product_shortId,
                product_name: product_name,
                product_price: product_price,
                product_stock: product_stock,
                product_desc: product_desc,
                product_type: product_type,

            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllProductWithPgn(org_id, 0, 10));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, "Product Added Successfully"));

                } else {
                    dispatch(viewAllProductWithPgn(org_id, 0, 10));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, "Product Not Added"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


// ....................UPDATE PRODUCT................................................////
export function updateproduct(org_id, product_id, product_img, product_shortId, product_name, product_price, product_stock, product_desc, product_type) {

    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_product", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                _id: product_id,
                product_img: product_img,
                product_shortId: product_shortId,
                product_name: product_name,
                product_price: product_price,
                product_stock: product_stock,
                product_desc: product_desc,
                product_type: product_type,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllProductWithPgn(org_id, 0, 10));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, 'Product Updated Successfully'));

                } else {
                    dispatch(viewAllProductWithPgn(org_id, 0, 10));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, 'Product Not Updated'));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


// export function editUploadImageProduct(org_id, product_id, product_img, old_product_img, product_shortId, product_name, product_price, product_stock, product_desc, product_type) {
//     return dispatch => {

//         // 
//         dispatch(setLoader(true));
//         if (product_img !== "") {
//             const storageRef = ref(getStorage(), "/Tags/" + product_id + Math.random() + ".png")
//             const uploadimage = uploadBytesResumable(storageRef, product_img);
//             uploadimage.on(
//                 "state_changed",
//                 function (snapshot) {
//                 },
//                 function (error) {
//                     // 
//                     dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
//                     // dispatch(setLoader(false));
//                 },
//                 function () {
//                     getDownloadURL(uploadimage.snapshot.ref).then(function (product_img) {
//                         // 
//                         if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
//                             // 
//                             dispatch(updateproduct(org_id, product_id, product_img, product_shortId, product_name, product_price, product_stock, product_desc, product_type));
//                             dispatch(set_snack_bar(true, "Image Uploaded"))
//                         }
//                         else {
//                             dispatch(set_snack_bar(true, "Image not Uploaded"));
//                         }
//                     });
//                 }
//             );
//         }
//         else {
//             dispatch(updateproduct(org_id, product_id, old_product_img, product_shortId, product_name, product_price, product_stock, product_desc, product_type));
//         }
//     }
// }



// ..................VIEW ALL PRODUCT .............................................

export function viewAllProduct(org_id) {
    console.log(org_id,"hj");
    return (dispatch) => {
        dispatch(unsetLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_product", {

            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "Application/json",
                org_id: org_id
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_PRODUCT, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_PRODUCT, payload: responseJson.result });
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// ................VIEWALL PRODUCT WITH PGN...................................

export function viewAllProductWithPgn(org_id, page_number, rowper_page) {
    return (dispatch) => {

        dispatch(unsetLoader());

        return fetch(UNIVERSAL.BASEURL + "view_all_product_with_pgn", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "Application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_PRODUCT_WITH_PGN, payload: responseJson.result, count: responseJson.total_result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_PRODUCT_WITH_PGN, payload: [], count: 0 });
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



// .........................delete product..........................................

export function deleteProduct(org_id, _id) {

    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_product", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                org_id: org_id
            },
            body: JSON.stringify({
                _id: _id,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllProductWithPgn(org_id, 0, 10));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, "Product Deleted Successfully"));

                } else {
                    dispatch(viewAllProductWithPgn(org_id, 0, 10));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, "Product Not Deleted"));
                }
                dispatch(unsetLoader());

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

/////////////bulk delete product...............................................////////

export function bulkDeleteProduct(product_id, isChecked) {

    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "bulk_delete_product", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                product_id: isChecked
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(viewAllProduct(product_id));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                } else {
                    dispatch(viewAllProduct(product_id));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function CheckedService(index, checked, quantity, view_product_by_id) {
    var payload = view_product_by_id;
    payload[index].checked = checked;
    payload[index].quantity = quantity;
    payload.allCheck = false
    return {
        type: PRODUCT,
        payload: payload
    }
};

export function getallchecked(view_product_by_id) {
    for (var i = 0; i <= view_product_by_id.length - 1; i++) {
        view_product_by_id[i].checked = true;
    }
    return {
        type: PRODUCT,

    }
};

export function allCheck(checked, quantity, view_product_by_id) {
    var payload = view_product_by_id;
    for (var i = 0; i <= payload.length - 1; i++) {
        payload[i].checked = checked;
        payload[i].quantity = quantity;
        payload.allCheck = checked;
        if (i === payload.length - 1)
            return {
                type: PRODUCT,
                payload: payload
            }
    }
};

export function isCheckedId(org_id, view_product_by_id) {
    var count = 0;
    var isChecked = [];
    return (dispatch) => {
        for (var i = 0; i <= view_product_by_id.length - 1; i++) {
            if (view_product_by_id[i].checked === true) {
                isChecked.push(view_product_by_id[i]._id)
            }
            count = i;
        }
        if (count === view_product_by_id.length - 1) {
            dispatch(bulkDeleteProduct(org_id, isChecked))
        }
    }

};

// ..............................VIEW PRODUCT BY ID ...................................

export function viewProductById(product_id) {

    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_product_by_id", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                product_id: product_id
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_PRODUCT_BY_ID, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_PRODUCT_BY_ID, payload: responseJson.result })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// ..................VIEW PRODUCT BY ORG ID ...................................

export function viewProductByOrgId(org_id) {
    return (dispatch) => {
        dispatch(unsetLoader());

        return fetch(UNIVERSAL.BASEURL + "view_product_by_org_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "Application/json",
                org_id: org_id
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_PRODUCT_BY_ORG_ID, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_PRODUCT_BY_ORG_ID, payload: responseJson.result });
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// ............................VIEW ALL SOLD PRODUCT.............................

export function viewAllSoldProduct(org_id) {
    return (dispatch) => {
        dispatch(unsetLoader());

        return fetch(UNIVERSAL.BASEURL + "view_all_sold_product", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-type": "Application/json",
                org_id: org_id
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: VIEW_All_SOLD_PRODUCT, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_All_SOLD_PRODUCT, payload: responseJson.result });
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// searchbarrrrrrrrr......................................................................

export function SearchProduct(org_id,page_number,rowper_page,name) {
    return (dispatch) => {

        dispatch(unsetLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_product_with_pgn", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "Application/json",
                org_id:org_id
            },
            body: JSON.stringify({
                name: name,
                page_number:page_number,
                rowper_page:rowper_page
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: VIEW_ALL_PRODUCT_WITH_PGN, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: VIEW_ALL_PRODUCT_WITH_PGN, payload: responseJson.result });
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function searchProductByAdmin(view_all_product_with_pagination, e) {
    return (dispatch) => {
        
        if (e === "") {
            dispatch({ type: SEARCH_PRODUCT_BY_ADMIN, payload: view_all_product_with_pagination });
        }
        else {
            const newArray = view_all_product_with_pagination.filter((el) => {
                return (el.product_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_PRODUCT_BY_ADMIN, payload: newArray })

        }
    };
}



// export function deleteProduct(org_id, product_id) {

//     return (dispatch) => {
//         dispatch(setLoader());
//         return fetch(UNIVERSAL.BASEURL + "delete_product", {
//             method: "DELETE",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//                 org_id: org_id
//             },
//             body: JSON.stringify({
//                 _id: product_id
//             }),
//         })
//             .then((response) => response.json())
//             .then((responseJson) => {
//                 if (responseJson.status) {
//                     dispatch(viewAllProduct(org_id));
//                     dispatch(unsetLoader());
//                     dispatch(set_snack_bar(true, responseJson.message));

//                 } else {
//                     dispatch(viewAllProduct(org_id));
//                     dispatch(unsetLoader());
//                 }
//                 dispatch(unsetLoader());
//                 dispatch(set_snack_bar(true, responseJson.message));

//             })
//             .catch((error) => {
//                 dispatch(unsetLoader());
//                 dispatch(set_snack_bar(true, "Check Your Internet Connection"));
//                 console.error(error);
//             });
//     };
// }

// export function search_product(prroduct, e) {
//     return (dispatch) => {
//         if (e === "") {
//             dispatch({ type: SEARCH_PRODUCT, payload: prroduct });
//         }
//         else {
//             const newArray = e.filter((el) => {
//                 return (el.product_name.toLowerCase().includes(e.toLowerCase()));
//             })
//             dispatch({ type: SEARCH_PRODUCT, payload: newArray })
//         }
//     };
// }

// export function SetProductName(payload) {
//     return {
//         type: SET_PRODUCT_NAME,
//         payload: payload,
//     };
// }
// export function SetShortId(payload) {
//     return {
//         type: PRODUCT_SHORT_ID,
//         payload: payload,
//     };
// }
// export function SetProductPrice(payload) {
//     return {
//         type: PRODUCT_PRICE,
//         payload: payload,
//     };
// }
// export function SetProductId(payload) {
//     return {
//         type: SET_PRODUCT_ID,
//         payload: payload,
//     };
// }

// export function setProductStock(payload) {
//     return {
//         type: SET_PRODUCT_STOCK,
//         payload: payload,
//     };
// }


// export function setProductDesc(payload) {
//     return {
//         type: SET_PRODUCT_DESC,
//         payload: payload,
//     };
// }


// export function (payload) {
//     return {
//         type: SET_PRODUCT_ID,
//         payload: payload,
//     };
// }