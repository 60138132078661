import React, { Component } from 'react';
import { TextField, Dialog, Box, Grid, Divider, Typography, Card, TableContainer, TableHead, TableCell, TableRow, TableBody, Button } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Message from "../../image/message.png";
import Whatsapp from "../../image/whatsapp.png";
import Call from "../../image/call.png";
import Mail from "../../image/email.png";
import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
export default class Employeedetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewData: false,
      assign: false,
      executive_id: "",
      tabledata: [
      ]
    };
    this.handleSendMessage = this.handleSendMessage.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleCallButtonClick = this.handleCallButtonClick.bind(this)
  }
  handleEmailClick = () => {
    const emailAddress = this.props.employee.fullname.email;
    const subject = 'Subject of the email';
    const body = 'Body of the email';
    // Construct the mailto link
    const mailtoLink = `mailto:${emailAddress}`;
    // Open the default email client
    window.location.href = mailtoLink;
  };

  componentDidMount() {
    this.props.viewExecutiveByManager(this.props.employee.manager_id, this.props.login.org_id);
    
  }

  handleButtonClick() {
    // Replace '123456789' with the desired WhatsApp number
    const phoneNumber = this.props.employee.fullname.mobile;
    // Create the WhatsApp API URL with the phone number
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    // Open the WhatsApp chat window in a new tab
    window.open(whatsappUrl, '_blank');
  }

  handleSendMessage() {
    // Construct the message URI
    const messageURI = `sms:${this.props.employee.fullname.mobile}`;

    // Open the default messaging app with the pre-filled message
    window.location.href = messageURI;
  }
  handleCallButtonClick() {
    // Replace '123456789' with the desired phone number
    
    // Create the tel URL with the phone number
    const telUrl = `tel:${this.props.employee.fullname.mobile}`;
    // Initiate a phone call
    window.location.href = telUrl;
  }

  render() {
    const { snackbar, close_snack_bar } = this.props;
    const { employee } = this.props
    return (
      <Grid style={{ marginTop: "10%" }}>
        <a href='/Employee' style={{ textDecoration: 'none', color: 'black' }}>
          <Grid style={{ display: "flex", flexDirection: "row" }}>
            <KeyboardArrowLeftIcon />
            Back to employee
          </Grid>
        </a>
        <Grid style={{ display: "flex", marginLeft: "6%", marginTop: "2%", gap: 36 }}>
          <Grid>
              <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "6%", }}>
                <button onClick={() => this.handleButtonClick()} style={{ marginRight: "2%", borderRadius: "5px" }}> <img src={Whatsapp} alt='' style={{}} /></button>

                <button onClick={this.handleEmailClick} style={{ marginRight: "2%", borderRadius: "5px" }}><img src={Mail} alt='' style={{ marginLeft: "4%" }} /></button>

                <button onClick={() => this.handleCallButtonClick()} style={{ marginRight: "2%", borderRadius: "5px" }}><img src={Call} alt='' style={{ marginLeft: "4%" }} /></button>

                <button onClick={() => this.handleSendMessage()} style={{ borderRadius: "5px" }}><img src={Message} alt='' style={{ width: "24px", height: "27px", marginTop: "5px", marginLeft: "4%" }} /></button>
              </Grid>
            <Card style={{ width: "300px", marginTop: "10%", boxShadow: "2px 2px 8px 0px #66666633" }}>
              <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "7%", marginBottom: "7%" }}>
                <Grid>
                  <Typography style={{ fontSize: "25px", fontWeight: "600" }}>500</Typography>
                  <Typography style={{ marginTop: "10%" }}>Leads</Typography>
                </Grid>
                <Grid style={{ marginLeft: "10%" }}>
                  <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <ChangeHistoryTwoToneIcon style={{ color: "#20C130", fontSize: 40 }} />
                    <Typography style={{ fontSize: "25px", fontWeight: "600" }}>450</Typography>
                  </Grid>
                  <Typography>Converted</Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Card style={{
            width: "700px", boxShadow: "2px 2px 8px 0px #66666633"
          }}>

            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography style={{ color: "#AA3F1D", fontSize: "20px", marginLeft: "2%", marginTop: "2%" }}>Executive</Typography>

              <button
                onClick={() => {
                  this.setState({ assign: true });
                }}
                style={{ border: "none", borderRadius: "2px", outline: "none", color: "white", background: "#441000", width: "18%", marginTop: "2%", marginRight: "15%" }}>
                Assign Executive
              </button>


              {/* <FormControl variant="standard" sx={{ minWidth: '17vw', maxWidth: '18vw' ,marginRight:"10%"}}>
                  <InputLabel id="demo-simple-select-standard-label">Assign Executive</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.source}
                    // onChange={this.handleChanges}
                    label="executive"
                    placeholder='executive'
                   
                  >
                    <MenuItem value={"N"}>{"All Executive"}</MenuItem>
                    {Array.isArray(this.props.employee.all_executive_by_admin) && this.props.employee.all_executive_by_admin.map((item) => (
                      <MenuItem value={item._id}
                      onClick={() => {
                        this.props.assignExecutiveToManager(this.props.employee.manager_id,item._id);
                      }}
                      >
                        {item.fullname}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
            </Grid>




            <Box sx={{ height: "250px", width: "100%", marginLeft: "2%", marginRight: "2%", marginTop: "2%" }}>  
              <Grid>
                {/* <Typography>Date: 29/06/2023</Typography> */}
                <Divider style={{ marginBottom: "2%" }} />
                <TableContainer style={{ width: "100%" }}  >
                  <TableHead style={{ width: "100%", backgroundColor: "#E0E0E0" }}>
                    <TableRow>
                      <TableCell style={{ width: "30%" }}>Executive Name</TableCell>
                      <TableCell style={{ width: "30%" }}>Executive ID</TableCell>
                      <TableCell style={{ width: "30%" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  {Array.isArray(this.props.employee.all_executive_by_manager) && this.props.employee.all_executive_by_manager.map((item, index) => {
                    return (
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ width: "30%" }}>{item.fullname}</TableCell>
                          <TableCell style={{ width: "30%" }}>{item.employeeID}</TableCell>
                          <TableCell style={{ width: "30%" }}><ErrorOutlinedIcon
                            onClick={() => {
                              this.setState({
                                viewData: true,
                                fullname: item.fullname,
                                email: item.email,
                                mobile: item.mobile,
                                employeeID: item.employeeID,
                              })
                            }}
                          />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </TableContainer>
              </Grid>
            </Box>
          </Card>
        </Grid>
        {/* view dialog */}
        <Dialog
          open={this.state.viewData} style={{}}>
          <Grid style={{ width: "400px", padding: "20px", justifyContent: "center", alignItems: "center" }}>
            <Grid style={{ display: "flex", justifyContent: "space-between", }}>
              <Typography style={{ fontWeight: "600" }}>Details Of Employee</Typography>
              <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ viewData: false });
                }}
                className="eventcross-icon"
              />
            </Grid>
            <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Grid style={{ marginTop: "2%" }}>
                <Typography>User Name-</Typography>
                <TextField id="standard-basic" placeholder='Punchul Duos' disabled variant="standard" style={{ width: "250px", marginLeft: "2%", marginTop: "-7px" }}
                  value={this.state.fullname}
                />
              </Grid>
              <Grid>
                <Typography>Email ID-</Typography>
                <TextField id="standard-basic" placeholder='abc@gmail.com' disabled variant="standard" style={{ width: "250px", marginLeft: "2%", marginTop: "-7px" }}
                  value={this.state.email}
                />
              </Grid>
              <Grid>
                <Typography>Phone No-</Typography>
                <TextField id="standard-basic" placeholder='1234567890' disabled variant="standard" style={{ width: "250px", marginLeft: "2%", marginTop: "-7px" }}
                  value={this.state.mobile}
                />
              </Grid>
              <Grid>
                <Typography>Employee ID-</Typography>
                <TextField id="standard-basic" placeholder='EMP1234567' disabled variant="standard" style={{ width: "250px", marginLeft: "2%", marginTop: "-7px" }}
                  value={this.state.employeeID}
                />
              </Grid>
              <button
                onClick={() => {
                  this.setState({ viewData: false });
                }}
                style={{ border: "none", borderRadius: "2px", outline: "none", color: "white", background: "#441000", padding: "10px", width: "30%", marginTop: "2%" }}>
                Okay
              </button>
            </Grid>
          </Grid>
        </Dialog>

        {/* assign modal */}
        <Dialog
          open={this.state.assign} style={{}}>
          <Grid style={{ width: "400px", padding: "20px", justifyContent: "center", alignItems: "center" }}>
            <Grid style={{ float: "right" }}>

              <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ assign: false });
                }}

              // className="eventcross-icon"
              />
            </Grid>
            <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              {/* <Grid style={{ marginTop: "2%" }}>
                <Typography>User Name-</Typography>
                <TextField id="standard-basic" placeholder='Punchul Duos' disabled variant="standard" style={{ width: "250px", marginLeft: "2%", marginTop: "-7px" }}
                  value={this.state.fullname}
                />
              </Grid> */}
              <FormControl variant="standard" sx={{ minWidth: '17vw', maxWidth: '18vw', marginRight: "10%" }}>
                {/* <InputLabel id="demo-simple-select-standard-label">Assign Executive</InputLabel> */}
                {/* <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={this.state.executive_id}
                    label="executive"
                    placeholder='executive'                  
                  >
                    <MenuItem value={"N"}>{"All Executive"}</MenuItem>
                    {Array.isArray(this.props.employee.all_executive_by_admin) && this.props.employee.all_executive_by_admin.map((item) => (
                      <MenuItem value={item._id}                    
                      >
                        {item.fullname}
                        </MenuItem>
                    ))}
                  </Select> */}

                <Typography style={{ fontWeight: "600" }}>Assign Executive</Typography>
                <TextField
                  style={{ width: '258px', marginLeft: "10px", marginTop: "3%" }}
                  id="outlined-select-currency-native"
                  select
                  value={this.state.executive_id}
                  onChange={(e) => {
                    this.setState({ executive_id: e.target.value });
                  }}
                >
                  <MenuItem value={"N"}>{"All Executive"}</MenuItem>
                  {Array.isArray(this.props.employee.all_executive_by_admin) && this.props.employee.all_executive_by_admin.map((item) => (
                    <MenuItem value={item._id}>{item.fullname}</MenuItem>
                  ))}
                </TextField>
              </FormControl>

              <button
                onClick={() => {
                  this.setState({ assign: false });
                  this.props.assignExecutiveToManager(this.state.executive_id, this.props.employee.manager_id, this.props.login.org_id);
                }}
                style={{ border: "none", borderRadius: "2px", outline: "none", color: "white", background: "#441000", padding: "10px", width: "30%", marginTop: "2%" }}>
                Assign
              </button>

            </Grid>
          </Grid>
        </Dialog>
        <LoaderCon />
        <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        />
      </Grid>
    )
  }
}
