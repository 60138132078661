export const VIEW_ALL_LEAD = "VIEW_ALL_LEAD";
export const VIEW_ALL_LEAD_WITH_PGN = "VIEW_ALL_LEAD_WITH_PGN";

export const VIEW_ALL_TODAY_LEAD = "VIEW_ALL_TODAY_LEAD";
export const VIEW_ALL_LEAD_BY_ID = "VIEW_ALL_LEAD_BY_ID";
export const VIEW_ASSIGNED_BY_LEAD = "VIEW_ASSIGNED_BY_LEAD";
export const VIEW_LEAD_BY_MANAGER_ID = "VIEW_LEAD_BY_MANAGER_ID";
export const VIEW_ALL_SOURCE = "VIEW_ALL_SOURCE";
export const VIEW_ALL_NEW_LEAD = "VIEW_ALL_NEW_LEAD";
export const SEARCH_NEW_LEAD = "SEARCH_NEW_LEAD";
export const VIEW_ALL_FOLLOW_UPS_LEAD = "VIEW_ALL_FOLLOW_UPS_LEAD";
export const VIEW_ALL_OLD_LEAD = "VIEW_ALL_OLD_LEAD";
export const SEARCH_FOLLOW_UPS_LEAD = "SEARCH_FOLLOW_UPS_LEAD";
export const SEARCH_OLD_LEAD = "SEARCH_OLD_LEAD";
export const SET_LEAD_DETAILS = "SET_LEAD_DETAILS";
export const VIEW_ALL_DEAL_BY_LEAD_ID = "VIEW_ALL_DEAL_BY_LEAD_ID";
export const SET_ALL_NOTES_BY_LEAD_ID = "SET_ALL_NOTES_BY_LEAD_ID";
export const SEARCH_NOTES = "SEARCH_NOTES";
export const SET_ALL_PAYMENT_BY_LEAD_ID = "SET_ALL_PAYMENT_BY_LEAD_ID";
export const SET_ALL_QUOTES_BY_LEAD_ID = "SET_ALL_QUOTES_BY_LEAD_ID";




 