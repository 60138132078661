import React, { Component } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";

export default class Customize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
    };
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState((prevState) => ({
        progress: prevState.progress >= 100 ? 0 : prevState.progress + 10,
      }));
    }, 800);

    
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { loader } = this.props;

    return (
      <Grid>
      <CircularProgress
      style={{padding:"30%"}}
        variant="determinate"
        value={loader !== undefined ? loader : this.state.progress}
      />
       </Grid>
    );
  }
}
